import React, { useEffect,useRef, useState  } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader } from './../components/card/card.jsx';
import { workerApi, workerPaidAmountApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { VscEdit } from "react-icons/vsc";
import { FaListAlt,FaMoneyBillWave  } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import validator from 'validator';
import { boldTableStyle, countriesList, formatNumber, getCurrentDate, notify, ScreenLoading, workerTypeList } from '../utils.js';
import Select from 'react-select';

const $ = require('jquery');

function Workers({permissions}) {
    const [allWorkers,setAllWorkers] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allWorkers);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);

	//new states
	const [name,setName] = useState("")
    const [email,setEmail] = useState("")
	const [phone,setPhone] = useState("")
	const [phone2,setPhone2] = useState("")
	const [type,setType] = useState({value:"",label:"Select..."})
	const [typeF,setTypeF] = useState({value:"",label:"Select..."})
	const [salary,setSalary] = useState(0)
	const [country,setCountry] = useState({value:"",label:"Select..."})
	const [countryF,setCountryF] = useState({value:"",label:"Select..."})
    const [password,setPassword] = useState("")
    const [confirmPass,setConfirmPass] = useState("")

	//loan 
	const [amount,setAmount] = useState(0)
	const [note,setNote] = useState("")


	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		workerApi.getAllWorkers(
			pageNumber,
			filterText,
			paginationPerPage,
			countryF?.value  !== '' && countryF?.value !== undefined  ? `&country=${countryF?.value}` : '',
            typeF?.value  !== '' && typeF?.value !== undefined  ? `&type=${typeF?.value}` : '',
		)
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllWorkers(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable,paginationPerPage,
		countryF?.value,typeF?.value
	])

	const columns = [
		{ name: "ID", maxWidth: '50px',selector: row=>row.id,
			cell: (row) => row.id.toString(),sortable: true },
		{ name: "Name", selector: row=>row.name,
			cell: (row) => row.name,sortable: true },
		{ name: "Phone", selector: row=>row.phone, width:'150px',
			cell: (row) => row.phone,sortable: true },
		{ name: "Phone 2", selector: row=>row.phone2, width:'150px',
			cell: (row) => row.phone2,sortable: true },
		{ name: "Type", selector: row=>row.type,
			cell: (row) => row.type,sortable: true },
		{ name: "Salary", selector: row=>row.salary, width:'150px',
			cell: (row) => formatNumber(row?.salary)?.toString(),sortable: true },
		{ name: "Email", selector: row=>row.email, width:'200px',
			cell: (row) => row.email,sortable: true },
		{ name: "Contry", selector: row=>row.country, width:'150px',
			cell: (row) => row.country,sortable: true },
		{ name: "Updated At" , selector: row=>row.updated_at, width:'200px',sortable: true,
			cell: (row) => new Date(`${row.updated_at}`).toLocaleDateString(
			'en-gb',
			{
			  year: 'numeric',
			  month: 'long',
			  day: 'numeric',
			  timeZone: 'utc',
			  hour: 'numeric',
			  minute: 'numeric',
			  second:'2-digit'
			}) 
		},
		{
			name: '',
			button: true,
			width:'60px',
			cell: row => 
				{return (permissions?.edit) ?
					(
				<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)
						setType({label:row?.type,value:row?.type})
						setCountry({label:row?.country,value:row?.country})
					}
					}
					href="#modalUpdate" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<VscEdit />
				</a>
				)
				:
				""
			},
		},
		{
			name: '',
			button: true,
			width:'60px',
			cell: row => 
			{return (permissions?.advancePayment) ?
				(
				<a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)}
					}
					href="#modalLoan" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<FaMoneyBillWave color='olive' />
				</a>
				)
				:
				""
			},
		},
		{
			name: '',
			button: true,
			width:'60px',
			cell: row => 
				{return (permissions?.delete) ?
					(
				<a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)}
					}
					href="#modalDelete" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<RiDeleteBin6Line />
				</a>
				)
				:
				""
			}
		},
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		if (selectedItems.length > 0){
			let promises = selectedItems.map(async (item,index)=>{
				return new Promise((resolve,reject)=>{
				setTimeout(() => 
					workerApi.deleteWorker(item.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			// Await all promises to settle
			const results = await Promise.allSettled(promises);
			const hasErrors = results.some(result => result.status === 'rejected');
			if (hasErrors) {
				results.forEach(result => {
				if (result?.status === 'rejected') {
					if (result.reason?.response && result.reason.response?.status === 404) {
						notify("Item not found, please refresh the page and retry");
					  }
					else if (result?.reason?.response && result?.reason?.response?.status === 405) {
					notify("You don't have permission to perform this action.");
					} else {
					notify('An issue occurred during deleting the worker');
					}
				}
				});
			}
			else{
				setSelectedItems([])
				handleClearRows()
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			}
		}
	} 

	const deleteSingle = async () => {
		workerApi.deleteWorker(onUpdateItem.id)
		.then(res=>{
			setSelectedItems([])
			handleClearRows()
			setFilterText('')
			setPageNumber(1)
			setRefreshTable(!refreshTable)
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				notify("You don't have permission to perform this action.")
			}
			else{
				notify('An issue occured while deleting the worker')
			}
		})
	} 

	const updateItem = () => {
		if(onUpdateItem.name !== ""){
			if(onUpdateItem?.salary >= 0){
				let data = {
					name:onUpdateItem?.name,
					email:onUpdateItem?.email,
					phone:onUpdateItem?.phone,
					phone2:onUpdateItem?.phone2,
					country:country?.value,
					type:type?.value,
					salary:onUpdateItem?.salary
				}
				workerApi.updateWorker(onUpdateItem.id,data)
				.then(resUser=>{
					setFilterText('')
					setPageNumber(1)
					setRefreshTable(!refreshTable)
					setCountry({value:"",label:"Select..."})
					setType({value:"",label:"Select..."})
					$("[data-bs-dismiss='modal']").trigger({ type: "click" });
					})
				.catch(err=>{
					console.log(err)
					if(err.response.status === 405){
						notify("You don't have permission to perform this action.")
					}
					else{
						notify("Failed to create the worker please refresh the page and retry.")
					}
				})
			}
			else{
				notify("Salary cannot be negative!")
			}
		}
		else{
			notify("Please fill all required information to update the worker")
		}
	}

	const createItem = () => {
		if(name !== ""){
			if(salary >= 0) {
				const data = {
					"name":name,
					"email":email,
					"phone":phone,
					"phone2":phone2,
					"type": type?.value,
					"salary": salary,
					"country": country?.value,
				}
				workerApi.createWorker(data)
				.then(resUser=>{
					setFilterText('')
					setPageNumber(1)
					setRefreshTable(!refreshTable)
					// setFilteredItems(res.data)
					setName("")
					setPhone("")
					setPhone2("")
					setEmail("")
					setSalary(0)
					setCountry({value:"",label:"Select..."})
					setType({value:"",label:"Select..."})
					$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
				.catch(err=>{
					if(err?.response?.data?.message){
						notify(err?.response?.data?.message)
					}
					else if(err.response.status === 405){
						notify("You don't have permission to perform this action.")
					}
					else{
						notify("Failed to create the worker please refresh the page and retry.")
					}
				})
			}
			else {
				notify("salary cannot be negative")
			}

		}
		else{
			notify("Please fill all required information to create a worker.")
		}
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
		if(paginationPerPage !== newPerPage){
			setPaginationPerPage(newPerPage)
		}
	};

	const onAdvancePayment = () => {
		if(amount > 0){
			let data = {
				paid_date:getCurrentDate(),
				amount:amount,
				note:note,
				worker_id:onUpdateItem?.id
			}
			workerPaidAmountApi.createPaidAmount(data)
			.then(res=>{
				notify("advance payment submitted!")
				setAmount(0)
				setNote("")
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			})
			.catch(err=>{
				if(err?.response?.data?.message){
					notify(err?.response?.data?.message)
				}
				else{
					notify("An issue occurred with your request")
				}
			})
		}
		else{
			notify("Please add amount.")
		}

	}

	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
			<ul className="breadcrumb">
				<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
				<li className="breadcrumb-item active">WORKERS</li>
			</ul>

			<div className="row d-flex align-items-center mb-3">
				<div className='col-12 col-lg-3 col-md-3 col-sm-12 mb-3'>
					<h1 className="page-header mb-0">WORKERS</h1>
				</div>
				
				<div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12 d-flex ms-auto"
					style={{gap:10,justifyContent: "flex-end",flexWrap:'wrap'}}>
									
					{(permissions?.advancePayment) && (
						<Link style={{justifyContent: "center",alignItems:'center'}} to={'/workers/advance-payment'} 
						className="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-12 d-flex btn btn-theme text_icon_center">
							<FaListAlt /> Advance Payment
						</Link>
					)}
				
					{(permissions?.create) && (
						<a href="#modalAdd" data-bs-toggle="modal"
						className="btn btn-theme col-12 col-xl-3 col-lg-4 col-md-4 col-sm-12 ">
							<i className="fa fa-plus-circle fa-fw me-1"></i> Add Worker
						</a>
					)}
				
				</div>
			</div>
			
			{/* <div className="d-flex align-items-center mb-3">
				<div>
					<ul className="breadcrumb">
						<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
						<li className="breadcrumb-item active">WORKERS</li>
					</ul>
					<h1 className="page-header mb-0">WORKERS</h1>
				</div>
				
				<div className="ms-auto">
					<a href="#modalAdd" data-bs-toggle="modal" className="btn btn-theme"><i className="fa fa-plus-circle fa-fw me-1"></i> Add Worker</a>
				</div>
			</div> */}
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							{/* <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button>
							<div className="dropdown-menu">
								<button className="dropdown-item" href="#modalDelete" data-bs-toggle="modal" target="_blank"
									rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Delete selected</button>
							</div> */}
							<div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search Workers"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div>
						</div>

						 {/* filters */}
						 <div className='row mb-4'>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Country</label>
								<Select options={countriesList} classNamePrefix="react-select" 
									value={countryF} onChange={(e)=>setCountryF(e)} 
									isClearable={true} />
							</div>
                            <div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Type</label>
								<Select options={workerTypeList} classNamePrefix="react-select" 
									value={typeF} onChange={(e)=>setTypeF(e)} 
									isClearable={true}/>
							</div>
						</div>
						
						<div>
							<DataTable
								columns={columns}
								data={allWorkers}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								// selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								// onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								// clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

            {/* add modal */}
            <div className="modal fade" id="modalAdd">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Add New Worker</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Name</label>
								<input type="text" className="form-control" placeholder="name" 
									onChange={(e)=>setName(e.target.value)} value={name}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Email</label>
								<input type="email" className="form-control" placeholder="email"
									onChange={(e)=>setEmail(e.target.value)} value={email}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Phone</label>
								<input type="number" className="form-control" placeholder="phone" 
									onChange={(e)=>setPhone(e.target.value)} value={phone}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Phone 2</label>
								<input type="number" className="form-control" placeholder="phone 2" 
									onChange={(e)=>setPhone2(e.target.value)} value={phone2}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Salary</label>
								<input type="number" className="form-control" placeholder="salary"
									onChange={(e)=>setSalary(e.target.value)} value={salary}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Type</label>
								<Select options={workerTypeList} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Country</label>
								<Select options={countriesList} classNamePrefix="react-select" 
									value={country} onChange={(e)=>setCountry(e)}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>createItem()}>Create</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of add modal */}

			{/* update modal */}
			<div className="modal fade" id="modalUpdate">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update Worker</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">name</label>
								<input type="text" className="form-control" placeholder="name" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.name = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem?.name}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Email</label>
								<input type="email" className="form-control" placeholder="Email"
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.email = e.target.value
										setOnUpdateItem(data)
										}}
									value={(onUpdateItem?.email) ? onUpdateItem?.email : ""}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Phone</label>
								<input type="text" className="form-control" placeholder="Phone"
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.phone = e.target.value
										setOnUpdateItem(data)
										}}
									value={(onUpdateItem?.phone) ? onUpdateItem?.phone : ""}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Phone 2</label>
								<input type="text" className="form-control" placeholder="phone 2" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.phone2 = e.target.value
										setOnUpdateItem(data)
										}}
									value={(onUpdateItem?.phone2) ? onUpdateItem?.phone2 : ""}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Salary</label>
								<input type="number" className="form-control" placeholder="salary"
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.salary = e.target.value
										setOnUpdateItem(data)
										}}
									value={(onUpdateItem?.salary) ? onUpdateItem?.salary : ""}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Type</label>
								<Select options={workerTypeList} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Country</label>
								<Select options={countriesList} classNamePrefix="react-select" 
									value={country} onChange={(e)=>setCountry(e)}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>updateItem()}>Update</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}

			{/* delete modal */}
			<div className="modal fade" id="modalDelete">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Delete Worker</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							{(onUpdateItem?.name) ?
								(<p>Are you sure you want to delete {onUpdateItem?.name} worker?</p>)
								:
								(selectedItems?.length) ?
									(<p>Are you sure you want to delete {selectedItems?.length} workers?</p>)
									:
									(<p>No worker was selected for deleting</p>)
							}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							{(onUpdateItem?.name) ?
								(							
								<button type="button" className="btn btn-theme" onClick={()=>deleteSingle()}>Delete</button>
								)
								:
								(selectedItems?.length) ?
									(
										<button type="button" className="btn btn-theme" onClick={()=>batchDelete()}>Batch Delete</button>
									)
									:
									("")
							}
						</div>
					</div>
				</div>
			</div>
            {/* end of delete modal */}

			{/* loan modal */}
			<div className="modal fade" id="modalLoan">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Advance Payment</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Amount*</label>
								<input type="text" className="form-control" placeholder="amount" 
									onChange={(e)=>setAmount(e.target.value)} value={amount}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Note</label>
								<textarea className="form-control" rows="2" maxLength={200}
									onChange={(e)=>{setNote(e.target.value)}} value={note}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>onAdvancePayment()}>Submit</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of loan modal */}

		</div>
	)
}

export default Workers;