import http from "../http-common";

export const getAllProject = (page,name,per_page,engineer)=> {
    return http.get(
        `/project?sort=-id&pagination=true&perPage=${per_page}&page=${page}&name=*${name}*&include=createdBy,engineer${engineer}`
    );
}

export const getUnfinishProjects = (page,name,per_page,engineer)=> {
    return http.get(
        `/project?sort=-id&pagination=true&is_finished=0&perPage=${per_page}&page=${page}&name=*${name}*&include=createdBy,engineer${engineer}`
    );
}

export const createProject = (data)=> {
    return http.post(`/project`,data);
}

export const deleteProject = (data)=> {
    return http.delete(`/project/${data}`);
}

export const updateProject = (id,name,address,description,grp_price_meter,grc_price_meter,status)=> {
    return http.put(
        `/project/${id}?name=${name}&address=${address}&description=${description}&grp_price_meter=${grp_price_meter}&grc_price_meter=${grc_price_meter}&is_finished=${status}`
    );
}

export const updateEngineer = (id,engineer)=> {
    return http.put(
        `/project/${id}?engineer_id=${engineer}`
    );
}
