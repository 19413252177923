import React, { useEffect, useState  } from 'react';
import { Card } from './../components/card/card.jsx';
import { expenseApi, transactionApi,projectApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { VscEdit } from "react-icons/vsc";
import { FaListAlt,FaMoneyBillAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import Select from 'react-select';
import { boldTableStyle, formatDate, formatNumber, ModalLoading, notify, ScreenLoading, transactionType } from '../utils.js';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';

const $ = require('jquery');

function Transaction({permissions}) {
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allItems);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);

	//new states
    const [amount,setAmount] = useState(0)
    const [expenses_type,setExpenses_type] = useState({value:"",label:"Select..."})
    const [description,setDescription] = useState("")
	const [cashboxLoading,setCashboxLoading] = useState(true)

	const [projectFilterText, setProjectFilterText] = useState('');
	const [projectList,setProjectList] = useState([])
	const [project,setProject] = useState({label:"Select...",value:""})
	const [fProject,setFProject] = useState({label:"Select...",value:""})//for filter
    const [type,setType] = useState({value:"",label:"Select..."})
    const [typeF,setTypeF] = useState({value:"",label:"Select..."})
    const [invoice_1,setInvoice_1] = useState("")
    const [invoice_2,setInvoice_2] = useState("")

    const [transactionDate, setTransactionDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

	const [totalIncoming,setTotalIncoming] = useState("")
	const [totalOutgoing,setTotalOutgoing] = useState("")
	const [currentAmount,setCurrentAmount] = useState("")

    const handleTDateChange = (date) => {
        if (date) {
          const formattedDate = format(date, 'yyyy-MM-dd'); // Format date to 'yyyy-MM-dd'
          setTransactionDate(date); // Save the date object
        } else {
            setTransactionDate(null);
        }
      };

    const handleToDateChange = (date) => {
    if (date) {
        const formattedDate = format(date, 'yyyy-MM-dd'); // Format date to 'yyyy-MM-dd'
        setToDate(date); // Save the date object
    } else {
        setToDate(null);
    }
    };

    const handleFromDateChange = (date) => {
    if (date) {
        const formattedDate = format(date, 'yyyy-MM-dd'); // Format date to 'yyyy-MM-dd'
        setFromDate(date); // Save the date object
    } else {
        setFromDate(null);
    }
    };

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		transactionApi.getAllTransactions(
            pageNumber,
            paginationPerPage,
            fProject?.value  !== '' && fProject?.value !== undefined  ? `&project=${fProject?.value}` : '',
            typeF?.value  !== '' && typeF?.value !== undefined  ? `&type=${typeF?.value}` : '',
            (fromDate !==null && toDate !==null && fromDate !== undefined && toDate !== undefined) ? 
            `&date=${formatDate(fromDate)},${formatDate(toDate)}` : '',
        )
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllItems(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable,paginationPerPage,
        fProject?.value,typeF?.value,toDate,fromDate
    ])

	const getAmounts=()=>{
		setCashboxLoading(true)
		transactionApi.getAmounts()
		.then(res=>{
			setTotalIncoming(res?.data?.data?.total_incoming)
			setTotalOutgoing(res?.data?.data?.total_outgoing)
			setCurrentAmount(res?.data?.data?.current)
			setCashboxLoading(false)
		})
		.catch(err=>{
			notify("An issue occured while getting current transaction amounts.")
			setCashboxLoading(false)
		})
	}

	//project filter
	useEffect(()=>{
		let projectsList = []
		if(projectFilterText === undefined){
			setProjectFilterText('')
		}
		projectApi.getAllProject(1,projectFilterText,10,'')
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				projectsList = [...projectsList, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setProjectList(projectsList)
			})
		})
		.catch(err=>{
			notify("failed to fetch projects")
		})
	},[projectFilterText])

	const columns = [
		{ name: "ID", width: '80px',selector: row=>row.id,
			cell: (row) => row.id.toString(),sortable: true },
		{ name: "Purpose", width: '150px',selector: row=>row?.purpose,
			cell: (row) => row?.purpose,sortable: true },
        { name: "Type", selector: row=>row.type, cell: (row) => row.type, sortable: true },
		{ name: "Amount", selector: row=>row.amount,
            cell: (row) => formatNumber(row?.amount)?.toString(), sortable: true },
		{ name: "Date", width: '150px',selector: row=>row?.date,
			cell: (row) => row?.date,sortable: true },
		{ name: "Worker", width: '150px',selector: row=>row?.worker?.name,
			cell: (row) => row?.worker?.name,sortable: true },
        { name: "Project", width: '150px',selector: row=>row?.project?.name,
            cell: (row) => row?.project?.name,sortable: true },
		{ name: "Order ID", width: '150px',selector: row=>row?.order_id,
			cell: (row) => row?.order_id,sortable: true },
		{ name: "Shipping ID", width: '150px',selector: row=>row?.shipping_id,
			cell: (row) => row?.shipping_id,sortable: true },
		{ name: "Expense ID", width: '150px',selector: row=>row?.expense_id,
			cell: (row) => row?.expense_id,sortable: true },
        { name: "Description",  width: '250px',selector: row=>row.description,
            cell: (row) => row.description,sortable: true },
		{
			name: 'Actions',
			button: true,
            width:'60px',
			cell: row => {
                return (row?.type ==="incoming" && permissions?.edit) &&
                (
                    <a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)
                        setType({label:row?.type,value:row?.type})
                        setProject({label:row?.project?.id,value:row?.project?.name})
                    }
					}
					href="#modalUpdate" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<VscEdit />
				</a>
                )
            },
		},
		{
			name: '',
			button: true,
            width:'60px',
			cell: row => {
				return (row?.type ==="incoming"  && permissions?.delete) &&
                (
				<a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)}
					}
					href="#modalDelete" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<RiDeleteBin6Line />
				</a>
				)
			},
		},
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		if (selectedItems.length > 0){
			let promises = selectedItems.map(async (item,index)=>{
				return new Promise((resolve,reject)=>{
				setTimeout(() => 
					expenseApi.deleteExpense(item.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			// Await all promises to settle
			const results = await Promise.allSettled(promises);
			const hasErrors = results.some(result => result.status === 'rejected');
			if (hasErrors) {
				results.forEach(result => {
				if (result?.status === 'rejected') {
					if (result.reason?.response && result.reason.response?.status === 404) {
						notify("Item not found, please refresh the page and retry");
						}
					else if (result?.reason?.response && result?.reason?.response?.status === 405) {
					notify("You don't have permission to perform this action.");
					} else {
					notify('An issue occurred during deleting the item');
					}
				}
				});
			}
			else{
				setSelectedItems([])
				handleClearRows()
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			}
		}
	} 

	const deleteSingle = async () => {
		transactionApi.deleteTransaction(onUpdateItem.id)
		.then(res=>{
			setFilterText('')
			setSelectedItems([])
			handleClearRows()
			setPageNumber(1)
			notify("deleted!")
			setRefreshTable(!refreshTable)
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				notify("You don't have permission to perform this action.")
			}
			else{
				notify('An issue occured while deleting the item')
			}
		})
	} 

	const updateItem = () => {
		if(onUpdateItem.amount !== "" && onUpdateItem?.date !==""
            && project?.value !=="" && type?.value !==""
        ){
            let data = {
                amount:onUpdateItem?.amount,
                description:onUpdateItem?.description,
                transaction_date:onUpdateItem?.date,
                type:type.value,
                project_id:project?.value
            }
			transactionApi.updateTransaction(onUpdateItem?.id,data)
			.then(resUser=>{
				console.log(resUser)
				setFilterText('')
				setPageNumber(1)
                setType({value:"",label:"Select..."})
                setProject({value:"",label:"Select..."})
                setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
			.catch(err=>{
				console.log(err)
				if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the item please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to update the item")
		}
	}

	const createItem = () => {
		if(amount !== "" && ((type?.value ==="outgoing") ||
            (type?.value ==="incoming" && project?.value !=="") && transactionDate !=="")
        ){
			let data = {
				amount:amount,
				transaction_date:formatDate(transactionDate),
				description:description,
                type:type?.value,
                project_id:project?.value
			}
			transactionApi.createTransaction(data)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				setDescription("")
                setType({value:"",label:"Select..."})
                setProject({value:"",label:"Select..."})
				setAmount("")
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			})
			.catch(err=>{
				console.log(err)
				if(err?.response?.data?.message){
					notify(err?.response?.data?.message)
				}
				else if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the transaction please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to create a transaction.")
		}
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
	if(paginationPerPage !== newPerPage){
		setPaginationPerPage(newPerPage)
	}
	};

	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
			<ul className="breadcrumb">
				<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
				<li className="breadcrumb-item active">TRANSACTIONS</li>
			</ul>
			<div className="row d-flex align-items-center mb-3">
				<div className='col-12 col-lg-3 col-md-3 col-sm-5 mb-3'>
					<h1 className="page-header mb-0">TRANSACTIONS</h1>
				</div>
				<div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12 d-flex ms-auto"
					style={{gap:10,justifyContent: "flex-end",flexWrap:'wrap'}}>
					{(permissions?.checkStore) && (
						<a href="#cashboxModal" data-bs-toggle="modal" style={{justifyContent: 'center'}}
						onClick={()=>getAmounts()}
						className="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-12 d-flex btn btn-theme text_icon_center">
							<FaMoneyBillAlt />Check Cash Box
						</a>
					)}

					{(permissions?.create) && (
						<a href="#modalAdd" data-bs-toggle="modal"
						className="btn btn-theme col-12 col-xl-3 col-lg-4 col-md-4 col-sm-12 ">
							<i className="fa fa-plus-circle fa-fw me-1"></i> New
						</a>
					)}
				
				</div>

			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						{/* <div className="input-group mb-4">
							<button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button>
							<div className="dropdown-menu">
								<button className="dropdown-item" href="#modalDelete" data-bs-toggle="modal" target="_blank"
									rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Delete selected</button>
							</div>
							<div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search Exepnses"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div>
						</div> */}

                        {/* filters */}
						<div className='row mb-4'>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Project</label>
								<Select options={projectList} classNamePrefix="react-select" 
									value={fProject} onChange={(e)=>setFProject(e)} 
									isClearable={true} onInputChange={(e)=>setProjectFilterText(e)}/>
							</div>
                            <div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Type</label>
								<Select options={transactionType} classNamePrefix="react-select" 
									value={typeF} onChange={(e)=>setTypeF(e)} 
									isClearable={true}/>
							</div>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">From</label>
                                <DatePicker 
                                    showIcon 
                                    dateFormat="yyyy-MM-dd" // Change to desired format
                                    selected={fromDate} 
                                    onChange={handleFromDateChange} 
                                    className="form-control"
                                    isClearable
                                />
							</div>
                            <div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">To</label>
                                <DatePicker 
                                    showIcon 
                                    dateFormat="yyyy-MM-dd" // Change to desired format
                                    selected={toDate} 
                                    onChange={handleToDateChange} 
                                    className="form-control"
                                    isClearable
                                />
							</div>
						</div>

						<div>
							<DataTable
								columns={columns}
								data={allItems}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								// selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								// onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								// clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

            {/* add modal */}
            <div className="modal fade" id="modalAdd">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Add New Transaction</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
                            <div className="mb-3">
								<label className="form-label">Amount</label>
								<input type="number" className="form-control" placeholder="Amount"
									onChange={(e)=>setAmount(e.target.value)} value={amount}/>
							</div>
                            <div className="mb-3">
                                <label className="form-label">Date</label>
                                <DatePicker 
                                    showIcon 
                                    dateFormat="yyyy-MM-dd" // Change to desired format
                                    selected={transactionDate} 
                                    onChange={handleTDateChange} 
                                    className="form-control"
                                    isClearable
                                />
    						</div>
                            <div className="mb-3">
								<label className="form-label">Type</label>
								<Select options={transactionType} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)}/>
							</div>
                            {(type?.value === "incoming")
                                && (
                                    <div className="mb-3">
                                        <label className="form-label">Project</label>
                                        <Select options={projectList} classNamePrefix="react-select" 
                                            value={project} onChange={(e)=>setProject(e)}/>
                                    </div>
                                )
                            }
                            {/* <div className="mb-3">
								<label className="form-label">Invoice 1</label>
								<input type="number" className="form-control" placeholder="Amount"
									onChange={(e)=>setInvoice_1(e.target.value)} value={invoice_1}/>
							</div>
                            <div className="mb-3">
								<label className="form-label">Invoice 2</label>
								<input type="number" className="form-control" placeholder="Amount"
									onChange={(e)=>setInvoice_2(e.target.value)} value={invoice_2}/>
							</div> */}
							<div className="mb-3">
								<label className="form-label">Description</label>
								<textarea className="form-control" rows="2" maxLength={200}
									onChange={(e)=>setDescription(e.target.value)} value={description}></textarea>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>createItem()}>Create</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of modal */}

			{/* cashbox modal */}
			<div className="modal fade" id="cashboxModal">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Cash Box</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						{(cashboxLoading) ?
							<ModalLoading loading={cashboxLoading}/>
						: (
							<div className="modal-body">
								<div className="mb-3">
									<div className='d-flex' style={{alignItems:'center',gap:10}}>
											<h5 className="form-label costText">Current Amount: </h5>
											<h6>{formatNumber(currentAmount)}</h6>
									</div>
								</div>
								<div className="mb-3">
								<div className='d-flex' style={{alignItems:'center',gap:10}}>
										<h5 className="form-label costText">Total Incoming: </h5>
										<h6>{formatNumber(totalIncoming)}</h6>
								</div>
								</div>
								<div className="mb-3">
									<div className='d-flex' style={{alignItems:'center',gap:10}}>
											<h5 className="form-label costText">Total Outgoing: </h5>
											<h6>{formatNumber(totalOutgoing)}</h6>
									</div>
								</div>
							</div>
							)
						}
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Close</a>
						</div>
					</div>
				</div>
			</div>
            {/* end of modal */}

			{/* update modal */}
			<div className="modal fade" id="modalUpdate">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update Transaction</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Amount</label>
								<input type="number" className="form-control" placeholder="Amount" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.amount = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem.amount}/>
							</div>
                            <div className="mb-3">
								<label className="form-label">Type</label>
								<Select options={transactionType} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)}/>
							</div>
                            <div className="mb-3">
                                <label className="form-label">Project</label>
                                <Select options={projectList} classNamePrefix="react-select" 
                                    value={project} onChange={(e)=>setProject(e)}/>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Date</label>
                                <DatePicker 
                                    showIcon 
                                    dateFormat="yyyy-MM-dd" // Change to desired format
                                    selected={onUpdateItem?.date} 
                                    onChange={(e)=>{
										let data = {...onUpdateItem}
										data.date = formatDate(e)
										setOnUpdateItem(data)
										}} 
                                    className="form-control"
                                    isClearable
                                />
    						</div>
							<div className="mb-3">
								<label className="form-label">Description</label>
								<textarea className="form-control" rows="2" maxLength={200}
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.description = e.target.value
										setOnUpdateItem(data)
									}} value={onUpdateItem?.description}></textarea>
							</div>
							{/* <div className="mb-3">
								<label className="form-label">Password*</label>
								<input type="text" className="form-control" placeholder="Password" 
									onChange={(e)=>setPassword(e.target.value)} value={onUpdateItem.password}/>
							</div> */}
							{/* <div className="mb-3">
								<label className="form-label">Status*</label>
								<select className="form-select" onChange={(e)=>{
										let data = {...onUpdateItem}
										data.status = e.target.value
										setOnUpdateItem(data)
										}}
										value={onUpdateItem.status}>
									<option value="">Select One</option>
									<option value="active">Active</option>
									<option value="suspended">Suspended</option>
								</select>
							</div> */}
							{/* <div className="mb-3">
								<label className="form-label">Tags</label>
								<TagsInput value={tags} name="tags" placeHolder="enter tags" />
							</div> */}
							{/* <div className="form-group">
								<label>Description</label>
								<textarea className="form-control" rows="10"></textarea>
							</div> */}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>updateItem()}>Update</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}

			{/* delete modal */}
			<div className="modal fade" id="modalDelete">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Delete Item</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							{(onUpdateItem?.id) ?
								(<p>Are you sure you want to delete this item?</p>)
								:
								(selectedItems?.length) ?
									(<p>Are you sure you want to delete {selectedItems?.length} items?</p>)
									:
									(<p>No item was selected for deleting</p>)
							}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							{(onUpdateItem?.id) ?
								(							
								<button type="button" className="btn btn-theme" onClick={()=>deleteSingle()}>Delete</button>
								)
								:
								(selectedItems?.length) ?
									(
										<button type="button" className="btn btn-theme" onClick={()=>batchDelete()}>Batch Delete</button>
									)
									:
									("")
							}
						</div>
					</div>
				</div>
			</div>
            {/* end of delete modal */}

		</div>
	)
}

export default Transaction;