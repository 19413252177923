import http from "../http-common";

export const getAllMold = (page, per_page, code, project_id, type, material_type) => {
    const params = {
        sort: '-id',
        pagination: true,
        perPage: per_page,
        page,
        include: 'project',
        ...(code && { code }),
        ...(project_id && { project_id }),
        ...(type && { type }),
        ...(material_type && { material_type })
    };

    const queryString = new URLSearchParams(params).toString();
    return http.get(`/mold?${queryString}`);
};

export const createMold = (data)=> {
    return http.post(`/mold`,data);
}

export const deleteMold = (data)=> {
    return http.delete(`/mold/${data}`);
}

export const updateMold = (id,code,project_id,meter,type,materialType)=> {
    return http.put(`/mold/${id}?code=${code}&project_id=${project_id}&meter=${meter}&type=${type}&material_type=${materialType}`);
}
