import * as actionTypes from './actionTypes';

export const setUserInfo = (userData) => (dispatch) => {
  // let data = {
  //   success: userData,
  // };
  dispatch(
    {
      type: actionTypes.SET_USER_INFO,
      userData,
    });
};

export const setUserToken = (token) => (dispatch) => {
  dispatch(
    {
      type: actionTypes.SET_USER_TOKEN,
      token,
    });
};

