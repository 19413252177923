import React, { useEffect,useRef, useState  } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader } from './../components/card/card.jsx';
import { expenseApi, expenseTypeApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { VscEdit } from "react-icons/vsc";
import { FaListAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import validator from 'validator';
import Select from 'react-select';
import { boldTableStyle, formatNumber, getCurrentDate, notify, ScreenLoading } from '../utils.js';
import { Toggle } from 'rsuite';

const $ = require('jquery');

function Expense({permissions}) {
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allItems);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);

	//new states
    const [amount,setAmount] = useState(0)
    const [expenses_type,setExpenses_type] = useState({value:"",label:"Select..."})
    const [description,setDescription] = useState("")
    const [isDept,setIsDept] = useState(false)

	//expense type dropdown filter
	const [expenseTypeFilterText, setExpenseTypeFilterText] = useState('');
    const [expensesTypeList,setExpensesTypeList] = useState([])

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		expenseApi.getAllExpense(pageNumber,paginationPerPage)
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllItems(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable,paginationPerPage])

	//expense type filter
	useEffect(()=>{
		// setLoading(true)
		let expenseTypeList = []
		if(expenseTypeFilterText === undefined){
			setExpenseTypeFilterText('')
		}
		expenseTypeApi.getAllExpenseType(1,expenseTypeFilterText,10)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				expenseTypeList = [...expenseTypeList, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setExpensesTypeList(expenseTypeList)
				// setExpenses_type({value:"",label:"Select..."})
			})
			// setLoading(false)
		})
		.catch(err=>{
			// setLoading(false)
		})
    },[expenseTypeFilterText])

	const columns = [
		{ name: "ID", width: '80px',selector: row=>row.id,
			cell: (row) => row.id.toString(),sortable: true },
        { name: "Expense Date", selector: row=>row.expense_date,
			cell: (row) => row.expense_date,sortable: true },
		{ name: "Amount", width: '100px', selector: row=>row.amount,
			cell: (row) => formatNumber(row?.amount)?.toString(),sortable: true },
		{ name: "Description",  width: '250px',selector: row=>row.description,
			cell: (row) => row.description,sortable: true },
		{ name: "Dept",  width: '150px', selector: row=>row.is_dept, sortable: true,
			cell: (row) => (row.is_dept) ? "Dept" : "Cash"
		},
		{ name: "Created By", width: '150px',selector: row=>row?.createdBy?.name,
			cell: (row) => row?.createdBy?.name,sortable: true },
        { name: "Updated At" ,width: '150px', selector: row=>row.updated_at,sortable: true,
        cell: (row) => new Date(`${row.updated_at}`).toLocaleDateString(
        'en-gb',
        {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          timeZone: 'utc',
          hour: 'numeric',
          minute: 'numeric',
          second:'2-digit'
        }) 
        },
		{
			name: '',
			button: true,
			cell: row => 
				{return (permissions?.edit) ?
					(
				<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)}
					}
					href="#modalUpdate" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<VscEdit />
				</a>
				)
				:
				""
			},
		},
		{
			name: '',
			button: true,
			cell: row => 
				{return (permissions?.delete) ?
					(
				<a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)}
					}
					href="#modalDelete" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<RiDeleteBin6Line />
				</a>
				)
				:
				""
			},
		},
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		if (selectedItems.length > 0){
			let promises = selectedItems.map(async (item,index)=>{
				return new Promise((resolve,reject)=>{
				setTimeout(() => 
					expenseApi.deleteExpense(item.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			// Await all promises to settle
			const results = await Promise.allSettled(promises);
			const hasErrors = results.some(result => result.status === 'rejected');
			if (hasErrors) {
				results.forEach(result => {
				if (result?.status === 'rejected') {
					if (result.reason?.response && result.reason.response?.status === 404) {
						notify("Item not found, please refresh the page and retry");
						}
					else if (result?.reason?.response && result?.reason?.response?.status === 405) {
					notify("You don't have permission to perform this action.");
					} else {
					notify('An issue occurred during deleting the item');
					}
				}
				});
			}
			else{
				setSelectedItems([])
				handleClearRows()
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			}
		}
	} 

	const deleteSingle = async () => {
		expenseApi.deleteExpense(onUpdateItem.id)
		.then(res=>{
			setFilterText('')
			setSelectedItems([])
			handleClearRows()
			setPageNumber(1)
			notify("Deleted !")
			setRefreshTable(!refreshTable)
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				notify("You don't have permission to perform this action.")
			}
			else{
				notify('An issue occured while deleting the item')
			}
		})
	} 

	const updateItem = () => {
		if(onUpdateItem.amount !== "" &&  onUpdateItem.description !== "" ){
			expenseApi.updateExpense(onUpdateItem?.id,onUpdateItem?.amount,onUpdateItem?.description)
			.then(resUser=>{
				console.log(resUser)
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
			.catch(err=>{
				console.log(err)
				if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the item please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to update the item")
		}
	}

	const createItem = () => {
		if(amount !== "" && description !==""){
			let data = {
				amount:amount,
				expense_date:getCurrentDate(),
				description:description,
				is_dept:isDept
			}
			expenseApi.createExpense(data)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				// setFilteredItems(res.data)
				setExpenseTypeFilterText("")
				setDescription("")
				setAmount("")
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			})
			.catch(err=>{
				console.log(err)
				if(err?.response?.data?.message){
					notify(err?.response?.data?.message)
				}
				else if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the expense please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to create a expense.")
		}
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
	if(paginationPerPage !== newPerPage){
		setPaginationPerPage(newPerPage)
	}
	};

	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
			<div className="d-flex align-items-center mb-3">
				<div>
					<ul className="breadcrumb">
						<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
						<li className="breadcrumb-item active">EXPENSES</li>
					</ul>
					<h1 className="page-header mb-0">EXPENSES</h1>
				</div>
				
				{(permissions?.create) && (
					<div className="ms-auto" style={{display:'flex',gap:10}}>
					<a href="#modalAdd" data-bs-toggle="modal" className="btn btn-theme"
						onClick={()=>setExpenses_type({label:"Select...",value:""})}>
						<i className="fa fa-plus-circle fa-fw me-1"></i> New Expense</a>
					</div>
				)}
			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							{/* <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button>
							<div className="dropdown-menu">
								<button className="dropdown-item" href="#modalDelete" data-bs-toggle="modal" target="_blank"
									rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Delete selected</button>
							</div> */}
							<div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search Exepnses"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div>
						</div>
						
						<div>
							<DataTable
								columns={columns}
								data={allItems}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

            {/* add modal */}
            <div className="modal fade" id="modalAdd">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Add New Expense</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
                            <div className="mb-3">
								<label className="form-label">Amount</label>
								<input type="number" className="form-control" placeholder="Amount"
									onChange={(e)=>setAmount(e.target.value)} value={amount}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Description</label>
								<textarea className="form-control" rows="2" maxLength={200}
									onChange={(e)=>setDescription(e.target.value)} value={description}></textarea>
							</div>
							<div className="mb-3">
								<Toggle onChange={(e)=>setIsDept(e)}>Dept</Toggle>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>createItem()}>Create</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of modal */}

			{/* update modal */}
			<div className="modal fade" id="modalUpdate">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update Expense</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Amount</label>
								<input type="number" className="form-control" placeholder="Amount" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.amount = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem.amount}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Description</label>
								<textarea className="form-control" rows="2" maxLength={200}
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.description = e.target.value
										setOnUpdateItem(data)
									}} value={onUpdateItem?.description}></textarea>
							</div>
							<div className="mb-3">
								<Toggle checked={onUpdateItem?.is_dept}>Dept</Toggle>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>updateItem()}>Update</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}

			{/* delete modal */}
			<div className="modal fade" id="modalDelete">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Delete Expense</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							{(onUpdateItem?.id) ?
								(<p>Are you sure you want to delete this item?</p>)
								:
								(selectedItems?.length) ?
									(<p>Are you sure you want to delete {selectedItems?.length} items?</p>)
									:
									(<p>No item was selected for deleting</p>)
							}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							{(onUpdateItem?.id) ?
								(							
								<button type="button" className="btn btn-theme" onClick={()=>deleteSingle()}>Delete</button>
								)
								:
								(selectedItems?.length) ?
									(
										<button type="button" className="btn btn-theme" onClick={()=>batchDelete()}>Batch Delete</button>
									)
									:
									("")
							}
						</div>
					</div>
				</div>
			</div>
            {/* end of delete modal */}

		</div>
	)
}

export default Expense;