import React from 'react';
import { useResolvedPath, useMatch, NavLink, useLocation, matchPath } from 'react-router-dom';
import menus from './../../config/app-menu.jsx';

function NavItem({ menu, ...props }: LinkProps) {
	let path = (menu.path) ? menu.path : '';
	let resolved = useResolvedPath(path);
  	let match = useMatch({ path: resolved.pathname });
	let location = useLocation();
	
	if (menu.is_header) {
		return (
			<div className="menu-header">{ menu.title }</div>
		);
	}
	
	if (menu.is_divider) {
		return (
			<div className="menu-divider"></div>
		);
	}
  
	let match2 = matchPath({path: path, end: false, },location.pathname);

	let badge = menu.badge && <span className="menu-icon-label">{menu.badge}</span>;
	let icon = menu.icon && <div className="menu-icon"><i className={menu.icon}></i> { badge }</div>;
	let img = menu.img && <div className="menu-icon-img"><img src={menu.img} alt="" /></div>;
	let caret = (menu.children) && <div className="menu-caret"><b className="caret"></b></div>;
	let title = menu.title && <div className="menu-text">{menu.title}</div>;
	
	return (
		<div className={'menu-item' + ((match || match2) ? ' active' : '') + (menu.children ? ' has-sub' : '')}>
			<NavLink className="menu-link" to={menu.path} {...props}>
				{ img } { icon } { title } { caret }
			</NavLink>
		
			{menu.children && (
				<div className="menu-submenu">
					{menu.children.map((submenu, i) => (
						<NavItem key={i} menu={submenu} />
					))}
				</div>
			)}
		</div>
	);
}

function SidebarNav() {
	return (
		<div className="menu">
			{menus().map((menu, i) => (
				<NavItem key={i} menu={menu} />
			))}
			{/* <div className="p-3 px-4 mt-auto hide-on-minified">
				<a href="https://seantheme.com/react-studio/documentation/index.html" rel="noreferrer" target="_blank" className="btn btn-secondary d-block w-100 fw-600 rounded-pill">
					<i className="fa fa-code-branch me-2 ms-n2 opacity-5"></i> Documentation
				</a>
			</div> */}
		</div>
	);
}

export default SidebarNav;