import http from "../http-common";

export const getAllCasting = (page,per_page,code,project,projectType,status,steel,mold,createdBy)=> {
    return http.get(`/casting?sort=-id&pagination=true&perPage=${per_page}&page=${page}&include=project,steel,mold,shipping,createdBy&${code}${project}${projectType}${status}${steel}${mold}${createdBy}`);
}

export const getAllDailyCasting = (page,per_page,code,project,projectType,status,steel,mold,createdBy)=> {
    return http.get(`/casting/search/daily-casting?sort=-id&pagination=true&perPage=${per_page}&page=${page}&include=project,steel,mold,shipping,createdBy&${code}${project}${projectType}${status}${steel}${mold}${createdBy}`);
}

export const getAllFixingSteel = (page,per_page,code,project,projectType)=> {
    return http.get(`/casting?sort=-id&pagination=true&perPage=${per_page}&page=${page}&include=project,steel,mold,shipping,createdBy&status=broken${code}${project}${projectType}`);
}

export const getAllFinishing = (page,per_page,code,project,projectType,status)=> {
    return http.get(`/casting/search/finishing?sort=-id&pagination=true&perPage=${per_page}&page=${page}&include=project${status}${code}${project}${projectType}`);
}

export const getAllFixingHistory = (page,per_page,code,project,projectType,status)=> {
    return http.get(`/casting/search/fixing?sort=-id&pagination=true&perPage=${per_page}&page=${page}&include=project${status}${code}${project}${projectType}`);
}

export const getAllAssembly = (page,per_page,code,project,projectType,status)=> {
    return http.get(`/casting/search/tarkebed?sort=-id&pagination=true&perPage=${per_page}&page=${page}&include=project${status}${code}${project}${projectType}`);
}

export const createDailyWorking = (data)=> {
    return http.post(`/casting`,data);
}

export const deleteDailyWorking = (data)=> {
    return http.delete(`/casting/${data}`);
}

export const updateDailyWorking = (id,meter,project_id,steel_id,code)=> {
    return http.put(`/casting/${id}?meter=${meter}&project_id=${project_id}&steel_id=${steel_id}&code=${code}`);
}

export const updateStatus = (id,status)=> {
    return http.put(`/casting/${id}?status=${status}`);
}


export const updateCasting = (id,data)=> {
    return http.put(`/casting/${id}`,data);
}