import http from "../http-common";

export const getAllDept = (page,per_page,purpose,deptType,date,createdBy)=> {
    return http.get(
        `/dept?sort=-id&pagination=true&perPage=${per_page}&page=${page}&include=createdBy${purpose}${deptType}${date}${createdBy}`
    );
}

export const getDeptAmounts = (deptType)=> {
    return http.get(
        `/check-dept?deptType=${deptType}`
    );
}

export const updateDept = (id,data)=> {
    return http.put(`/dept/${id}`,data);
}
