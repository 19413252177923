import http from "../http-common";

export const getAllSteel = (page,code,per_page,type)=> {
    return http.get(`/steel?sort=-id&pagination=true&perPage=${per_page}&page=${page}&include=logs,usages,materials,costs&code=*${code}*${type}`);
}

export const getUnusedSteel = (page,code,per_page)=> {
    return http.get(`/steel?sort=-id&pagination=true&perPage=${per_page}&page=${page}&include=logs,usages,materials,costs&code=*${code}*&notused=true`);
}

export const createSteel = (data)=> {
    return http.post(`/steel`,data);
}

export const deleteSteel = (data)=> {
    return http.delete(`/steel/${data}`);
}

export const updateSteel = (id,code,type)=> {
    return http.put(`/steel/${id}?code=${code}&type=${type}`);
}
