import http from "../http-common";

export const getAllWorkers = (page,name,per_page,country,type)=> {
    return http.get(`/worker?sort=-updated_at&pagination=true&perPage=${per_page}&page=${page}&name=*${name}*${country}${type}`);
}

export const getWorkers = (page,name,per_page,type)=> {
    return http.get(`/worker?sort=-updated_at&pagination=true&perPage=${per_page}&page=${page}&name=*${name}*&type=${type}`);
}

export const createWorker = (data)=> {
    return http.post(`/worker`,data);
}

export const deleteWorker = (data)=> {
    return http.delete(`/worker/${data}`);
}

export const updateWorker = (id,data)=> {
    return http.put(`/worker/${id}`,data);
}
