import http from "../http-common";

export const getAllMoldCosts = (page,per_page)=> {
    return http.get(`/moldCost?sort=-updated_at&pagination=true&perPage=${per_page}&page=${page}&include=worker,mold`);
}

export const createMoldCosts = (data)=> {
    return http.post(`/moldCost`,data);
}

export const deleteMoldCosts = (data)=> {
    return http.delete(`/moldCost/${data}`);
}

export const updateMoldCosts = (id,mold_id,worker_id,cost,note)=> {
    return http.put(`/moldCost/${id}?mold_id=${mold_id}&worker_id=${worker_id}&cost=${cost}&note=${note}`);
}

export const getSingleMoldCosts = (page,per_page,mold_id)=> {
    return http.get(`/moldCost?sort=-updated_at&pagination=true&perPage=${per_page}&page=${page}&include=worker,mold&mold=${mold_id}`);
}

//mold records

export const getMoldReports = (project_id)=> {
    return http.get(`/mold-cost?${project_id}`);
}
