import http from "../http-common";

export const getAllSteelMaterial = (page,per_page)=> {
    return http.get(`/steelMaterial?sort=-updated_at&pagination=true&perPage=${per_page}&page=${page}&include=material`);
}

export const createSteelMaterial = (data)=> {
    return http.post(`/steelMaterial`,data);
}

export const deleteSteelMaterial = (data)=> {
    return http.delete(`/steelMaterial/${data}`);
}

export const updateSteelMaterial = (id,steel_id,material_id,quantity)=> {
    return http.put(`/steelMaterial/${id}?steel_id=${steel_id}&material_id=${material_id}&quantity=${quantity}`);
}

export const getSingleSteelMaterial = (page,per_page,steel_id)=> {
    return http.get(`/steelMaterial?sort=-updated_at&pagination=true&perPage=${per_page}&page=${page}&include=steel,material&steel=${steel_id}`);
}
