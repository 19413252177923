import http from "../http-common";

export const getPaidAmount = (page,per_page,worker)=> {
    return http.get(`/paidAmount?sort=-id&pagination=true&include=worker&perPage=${per_page}&page=${page}${worker}`);
}

export const createPaidAmount = (data)=> {
    return http.post(`/paidAmount`,data);
}

export const deletePaidAmount = (id)=> {
    return http.delete(`/paidAmount/${id}`);
}

export const updatePaidAmount = (id,data)=> {
    return http.put(`/paidAmount/${id}`,data);
}
