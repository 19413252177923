import React, { useEffect, useState  } from 'react';
import { Link } from 'react-router-dom';
import { Card } from './../components/card/card.jsx';
import { materialApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { VscEdit } from "react-icons/vsc";
import { FaListAlt,FaMoneyBillAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import Select from 'react-select';
import { notify, ScreenLoading, unitList,typeList, boldTableStyle, formatNumber, ModalLoading } from '../utils.js';

const $ = require('jquery');

function Inventory({permissions,roles}) {
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);
	const [modalLoading,setModalLoading] = useState(true)

	//new states
	const [name,setName] = useState("")
	const [stockTaking,setStockTaking] = useState("")
    const [type,setType] = useState({label:"Select...",value:""})
	const [description,setDescription] = useState("")
	const [perUnit,setPerUnit] = useState(0)
    const [unit,setUnit] = useState({label:"Select...",value:""})

	const [selectedType, setSelectedType] = useState({label:"Select...",value:""});

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		materialApi.getAllInventory(
			pageNumber,
			filterText,
			paginationPerPage,
			selectedType?.value  !== '' && selectedType?.value !== undefined  ? `&type=${selectedType?.value}` : '',
		)
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllItems(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable,paginationPerPage,selectedType?.value])

	const getStock= () =>{
		setModalLoading(true)
		materialApi.getStocktaking()
		.then(res=>{
			console.log(res?.data?.data?.total_price)
			setStockTaking(formatNumber(res?.data?.data?.total_price))
			setModalLoading(false)
		})
		.catch(err=>{
			notify("error getting inventory stocktaking!")
			setModalLoading(false)
		})
	}

	const columns = [
		{ name: "ID",selector: row=>row.id,
			cell: (row) => row.id.toString(),sortable: true },
		{ name: "Name", selector: row=>row.name,
			cell: (row) => row?.name,sortable: true },
		{ name: "Description",  width: '250px',selector: row=>row.description,
			cell: (row) => row.description,sortable: true },
		{ name: "Type", selector: row=>row.type,
			cell: (row) => row.type, sortable: true },
		{ name: "Unit", selector: row=>row.unit,
			cell: (row) => row.unit,sortable: true },
		{ name: "Per Package", width: '150px',selector: row=>row.per_unit,
			cell: (row) => row.per_unit.toString(),sortable: true },
		{ name: "Quantity", selector: row=>row.quantity,
			cell: (row) => row.quantity.toString(),sortable: true },
		...((roles[0] !== "casting-engineer" && roles[0] !== "fixing-engineer" && roles[0] !== "engineer")? [
			{ name: "Remained Cost", width: '150px',selector: row=>row.remained_cost,
				cell: (row) => (
					row?.remained_cost === null ? 0 :
					formatNumber(row?.remained_cost)?.toString()
				) ,
				sortable: true
			},
		]: []),
		{
			name: 'Actions',
			button: true,
			width: '80px',
			cell: row => {return (permissions?.edit) ?
				(
				<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setType({label:row.type,value:row.type})
						setUnit({label:row.unit,value:row.unit})
						setOnUpdateItem(row)}
					}
					href="#modalUpdate" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<VscEdit />
				</a>
				)
				:
				""
			},
		},
		{
			name: '',
			button: true,
			width: '40px',
			cell: row => {return (permissions?.delete) ?
				(
				<a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)}
					}
					href="#modalDelete" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<RiDeleteBin6Line />
				</a>
			)
			:
			""
		},
		},
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		if (selectedItems.length > 0){
			let promises = selectedItems.map(async (item,index)=>{
				return new Promise((resolve,reject)=>{
				setTimeout(() => 
					materialApi.deleteMaterial(item.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			// Await all promises to settle
			const results = await Promise.allSettled(promises);
			const hasErrors = results.some(result => result.status === 'rejected');
			if (hasErrors) {
				results.forEach(result => {
				if (result?.status === 'rejected') {
					if (result.reason?.response && result.reason.response?.status === 404) {
						notify("Item not found, please refresh the page and retry");
						}
					else if (result?.reason?.response && result?.reason?.response?.status === 405) {
					notify("You don't have permission to perform this action.");
					} else {
					notify('An issue occurred during deleting the material');
					}
				}
				});
			}
			else{
				setSelectedItems([])
				handleClearRows()
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			}
		}
	} 

	const deleteSingle = async () => {
		materialApi.deleteMaterial(onUpdateItem.id)
		.then(res=>{
			setFilterText('')
			setSelectedItems([])
			handleClearRows()
			setPageNumber(1)
			setRefreshTable(!refreshTable)
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				notify("You don't have permission to perform this action.")
			}
			else{
				notify('An issue occured while deleting the material')
			}
		})
	} 

	const updateItem = () => {
		if(onUpdateItem.name !== "" &&  type?.value !== ""
				&&  onUpdateItem.per_unit > 0 && unit?.value !==""
		){
			materialApi.updateMaterial(onUpdateItem.id,onUpdateItem.name,type?.value,
				onUpdateItem.description,unit?.value,onUpdateItem.per_unit)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				setName("")
				setDescription("")
				setType({label:"Select...",value:""})
				setUnit({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
			.catch(err=>{
				console.log(err)
				if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the material please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to update the material")
		}
	}

	const createItem = () => {
		if(name !== "" && type?.value !== "" && perUnit > 0
			&& unit?.value !=="" ){
			const data = {
				"name":name,
				"type":type?.value,
				"description":description,
				"quantity": 0,
				"per_unit":perUnit,
				"unit":unit?.value
			}
			materialApi.createMaterial(data)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				setName("")
				setDescription("")
				setPerUnit(0)
				setType({label:"Select...",value:""})
				setUnit({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			})
			.catch(err=>{
				console.log(err)
				if(err?.response?.data?.message){
					notify(err?.response?.data?.message)
				}
				else if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the material please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to create a material.")
		}
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
		if(paginationPerPage !== newPerPage){
			setPaginationPerPage(newPerPage)
		}
	};

	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
			<ul className="breadcrumb">
				<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
				<li className="breadcrumb-item active">INVENTORY</li>
			</ul>
			<div className="row d-flex align-items-center mb-3">
				<div className='col-12 col-lg-3 col-md-3 col-sm-5 mb-3'>
					<h1 className="page-header mb-0">INVENTORY</h1>
				</div>
				{/* <div className="ms-auto col-12 col-xl-4 col-lg-6 col-md-6 col-sm-7 d-flex  mb-3" style={{gap:10}}>
					<a href="#cashboxModal" data-bs-toggle="modal" style={{justifyContent: 'center'}}
						onClick={()=>getStock()}
						className="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-12 d-flex btn btn-theme text_icon_center">
						<FaMoneyBillAlt />Check Stocktaking
					</a>
					<Link to={'/order'} style={{justifyContent: "center"}} className="btn btn-theme text_icon_center ms-auto w-100">
						<FaListAlt />Order History
					</Link>
					<a href="#modalAddMaterial" style={{justifyContent: "center"}} data-bs-toggle="modal" className="btn btn-theme w-100"><i className="fa fa-plus-circle fa-fw me-1"></i> New Material</a>
				</div> */}

				<div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12 d-flex ms-auto"
					style={{gap:10,justifyContent: "flex-end",flexWrap:'wrap'}}>
					{(permissions?.stocktaking) && (
						<a href="#stockModal" data-bs-toggle="modal" style={{justifyContent: 'center'}}
						onClick={()=>getStock()}
						className="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-12 d-flex btn btn-theme text_icon_center">
							<FaMoneyBillAlt />Check Stocktaking
						</a>
					)}

					{(permissions?.history) && (
						<Link to={'/order'} style={{justifyContent: "center"}} className="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-12 d-flex
						btn btn-theme text_icon_center">
							<FaListAlt />Order History
						</Link>
					)}

					{(permissions?.create) && (
						<a href="#modalAddMaterial" data-bs-toggle="modal"
						className="btn btn-theme col-12 col-xl-3 col-lg-4 col-md-4 col-sm-12 ">
							<i className="fa fa-plus-circle fa-fw me-1"></i> New Material
						</a>
					)}
			
				</div>

			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							{/* <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button>
							<div className="dropdown-menu">
								<button className="dropdown-item" href="#modalDelete" data-bs-toggle="modal" target="_blank"
									rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Delete selected</button>
							</div> */}
							<div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search By Material Name"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div>
						</div>

						{/* filters */}
						<div className='row mb-4' style={{ justifyContent: 'space-between' }}>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4'>
								<label className="form-label">Type</label>
								<Select
								options={typeList}
								classNamePrefix="react-select"
								value={selectedType}
								onChange={(e) => setSelectedType(e)}
								isClearable={true}
								/>
							</div>
						</div>
						
						<div>
							<DataTable
								columns={columns}
								data={allItems}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

            {/* add material modal */}
            <div className="modal fade" id="modalAddMaterial">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Add New Material</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Name</label>
								<input type="text" className="form-control" placeholder="name" 
									onChange={(e)=>setName(e.target.value)} value={name}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Type</label>
								<Select options={typeList} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Unit</label>
								<Select options={unitList} classNamePrefix="react-select" 
									value={unit} onChange={(e)=>setUnit(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Per Package</label>
								<input type="number" className="form-control" placeholder="per package" 
									onChange={(e)=>setPerUnit(e.target.value)} value={perUnit}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Description</label>
								<textarea className="form-control" rows="2" maxLength={200}
									onChange={(e)=>setDescription(e.target.value)} value={description}></textarea>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>createItem()}>Create</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of add modal */}

			{/* stocktaking modal */}
			<div className="modal fade" id="stockModal">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Stocktaking</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						{(modalLoading) ?
							<ModalLoading loading={modalLoading}/>
						: (
							<div className="modal-body">
								<div className="mb-3">
									<div className='d-flex' style={{alignItems:'center',gap:10}}>
											<h5 className="form-label costText">Inventory Stock Taking: </h5>
											<h6>{stockTaking}</h6>
									</div>
								</div>
							</div>
							)
						}
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Close</a>
						</div>
					</div>
				</div>
			</div>
            {/* end of modal */}

			{/* update modal */}
			<div className="modal fade" id="modalUpdate">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update Material</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Name</label>
								<input type="text" className="form-control" placeholder="name" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.name = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem?.name}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Type</label>
								<Select options={typeList} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Unit</label>
								<Select options={unitList} classNamePrefix="react-select" 
									value={unit} onChange={(e)=>setUnit(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Per Package</label>
								<input type="number" className="form-control" placeholder="per package" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.per_unit = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem?.per_unit}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Description</label>
								<textarea className="form-control" rows="3" maxLength={200}
									onChange={(e)=>{
									let data = {...onUpdateItem}
									data.description = e.target.value
									setOnUpdateItem(data)
									}}
									value={(onUpdateItem?.description) ? onUpdateItem?.description : ""}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>updateItem()}>Update</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}

			{/* delete modal */}
			<div className="modal fade" id="modalDelete">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Delete Material</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							{(onUpdateItem?.name) ?
								(<p>Are you sure you want to delete {onUpdateItem?.name} material?</p>)
								:
								(selectedItems?.length) ?
									(<p>Are you sure you want to delete {selectedItems?.length} materials?</p>)
									:
									(<p>No material was selected for deleting</p>)
							}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							{(onUpdateItem?.name) ?
								(							
								<button type="button" className="btn btn-theme" onClick={()=>deleteSingle()}>Delete</button>
								)
								:
								(selectedItems?.length) ?
									(
										<button type="button" className="btn btn-theme" onClick={()=>batchDelete()}>Batch Delete</button>
									)
									:
									("")
							}
						</div>
					</div>
				</div>
			</div>
            {/* end of delete modal */}

		</div>
	)
}

export default Inventory;