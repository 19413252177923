import React from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function Content(props) {

	return (
		<div id="content" className={'app-content '+ (props.className ? props.className : '')}>
			<Outlet />
			<ToastContainer />
		</div>
	)
}

export default Content;
