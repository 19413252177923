import React, { useEffect, useContext, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../config/app-settings.js';
import { authApi, userApi } from './../api';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from '../actions';
import Cookies from 'universal-cookie';
import validator from 'validator';

function PagesLogin() {
	const context = useContext(AppSettings);
	// const cookies = new Cookies();
	const [redirect, setRedirect] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const dispatch = useDispatch();
    const cookies = new Cookies();

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppSidebarNone(true);
		context.setAppContentClass('p-0');
		context.setAppContentFullHeight(true);
		
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppSidebarNone(false);
			context.setAppContentClass('');
			context.setAppContentFullHeight(false);
		};
		
		// eslint-disable-next-line
	}, []);
	
	async function handleSubmit(event) {
		event.preventDefault();
		setErrorMessage("")
		if(email !== "" && password !== ""){
			if(validator.isEmail(email)){
				authApi.login({email:email,password:password})
				.then(res=>{
					let userData= {
						id:res?.data?.data?.user?.id,
						name:res?.data?.data?.user?.name,
						// email:res?.data?.data?.user?.email
					}
					// get current cookies
					// var tomorrow = new Date();
					// tomorrow.setDate(tomorrow.getDate()+1);
					// console.log(tomorrow,"tomorrow")
					// cookies.set("token", "token-value", {
					// expires: tomorrow, // Will expire after 24hr from setting (value is in Date object)
					// path: '/'
					// });
					// save non critical info in redux persist
					dispatch(AuthActions.setUserInfo(userData))
					dispatch(AuthActions.setUserToken({token:res?.data?.data?.token}))
	
					setRedirect(true);
				})
				.catch(err=>{
					if(err?.response?.data?.message){
						setErrorMessage(err?.response?.data?.message)
					}
				})
			}
			else{
				setErrorMessage("Email address is not valid")
			}

		}
		else{
			setErrorMessage("email or password cannot be empty")
		}

  }
  
	if (redirect) {
		return <Navigate to='/projects' />;
	}
	return (
		<div className="login">
			<div className="login-content">
				<form onSubmit={handleSubmit}>
					<h1 className="text-center mb-5">Sign In</h1>
					{/* <div className="text-muted text-center mb-4">
						For your protection, please verify your identity.
					</div> */}
					<div className="mb-3">
						<label className="form-label">Email<span className="text-danger">*</span></label>
						<input type="email" className="form-control form-control-lg fs-15px" placeholder="mike"
							onChange={(e)=>setEmail(e.target.value)} value={email}/>
					</div>
					<div className="mb-3">
						<div className="d-flex">
							<label className="form-label">Password <span className="text-danger">*</span></label>
							{/* <a href="#/" className="ms-auto text-muted">Forgot password?</a> */}
						</div>
						<input type="password" className="form-control form-control-lg fs-15px" placeholder="Enter your password"
							value={password} onChange={(e)=>setPassword(e.target.value)}/>
					</div>
					{/* <div className="mb-3">
						<div className="form-check">
							<input className="form-check-input" type="checkbox" id="customCheck1" />
							<label className="form-check-label fw-500" htmlFor="customCheck1">Remember me</label>
						</div>
					</div> */}
					<button type="submit" className="btn btn-theme btn-lg d-block w-100 fw-500 mb-3">Sign In</button>
					{/* <div className="text-center text-muted">
						Don't have an account yet? <Link to="/pages/register">Sign up</Link>.
					</div> */}
					{(errorMessage) && (
						<div className='text-center'>
							<p style={{color:'red'}} >{errorMessage}</p>
						</div>
					)}
				</form>
			</div>
		</div>
	)
}

export default PagesLogin;