import React, { useEffect, useState  } from 'react';
import { useNavigate} from 'react-router-dom';
import { Card } from './../components/card/card.jsx';
import { materialApi, MoldApi, moldCostApi, projectApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { VscEdit } from "react-icons/vsc";
import { RiDeleteBin6Line } from "react-icons/ri";
import Select from 'react-select';
import { boldTableStyle, formatNumber, getCurrentDate, moldMaterialTypeList, notify, ScreenLoading } from '../utils.js';
import { FaMoneyBill } from "react-icons/fa";
import { CiViewList } from "react-icons/ci";

const $ = require('jquery');

let typeList = [
	{label:"GRC",value:"grc"},
	{label:"GRP",value:"grp"}
]

function Mold({permissions,roles}) {
	const navigate = useNavigate();
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});
	const [moldReport,setMoldReport] = useState({})

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);

	//new states
	const [code,setCode] = useState("")
    const [type,setType] = useState({label:"Select...",value:""})
	const [fType,setFType] = useState({label:"Select...",value:""})
	const [meter,setMeter] = useState(0)

    const [projectFilterText, setProjectFilterText] = useState('');
	const [projectList,setProjectList] = useState([])
	const [project,setProject] = useState({label:"Select...",value:""})
	const [selectedProject, setSelectedProject] = useState({label:"Select...",value:""});

	//expense type dropdown filter
	const [materialType,setMaterialType] = useState([])
	const [materialTypeF,setMaterialTypeF] = useState({label:"Select...",value:""})

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		MoldApi.getAllMold(
			pageNumber,
			paginationPerPage,
			filterText ? `&code=*${filterText}*` : '',
			selectedProject?.value  !== '' && selectedProject?.value !== undefined  ? `&project=${selectedProject?.value}` : '',
			fType?.value  !== '' && fType?.value !== undefined  ? `&type=${fType?.value}` : '',
			materialTypeF?.value  !== '' && materialTypeF?.value !== undefined  ? `&material_type=${materialTypeF?.value}` : '',
        )
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllItems(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable,paginationPerPage,
		selectedProject?.value,fType?.value,materialTypeF?.value])

	useEffect(()=>{
		moldCostApi.getMoldReports(
			selectedProject?.value  !== '' && selectedProject?.value !== undefined  ? `project=${selectedProject?.value}` : '',
		)
		.then(res=>{
			setMoldReport(res?.data)
		})
		.catch(err=>{
			notify("failed to fetch mold report")
		})
	},[selectedProject?.value])

	//project filter
	useEffect(()=>{
		let projectsList = []
		if(projectFilterText === undefined){
			setProjectFilterText('')
		}
		projectApi.getUnfinishProjects(1,projectFilterText,10,'')
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				projectsList = [...projectsList, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setProjectList(projectsList)
			})
		})
		.catch(err=>{
			notify("failed to fetch projects")
		})
	},[projectFilterText])

	const columns = [
		{ name: "ID", maxWidth: '50px',selector: row=>row.id,
			cell: (row) => row.id.toString(),sortable: true },
		{ name: "Code", selector: row=>row?.code,
			cell: (row) => row?.code,sortable: true },
		{ name: "Project", width: '150px',selector: row=>row?.project?.name,
			cell: (row) => row?.project?.name,sortable: true },
		{ name: "Meter", width: '150px',selector: row=>row.meter,
			cell: (row) =>formatNumber(row?.meter).toString(),sortable: true },
		{ name: "Type", width: '100px',selector: row=>row.type,
			cell: (row) => row.type,sortable: true },
		{ name: "Material Type", width: '150px',selector: row=>row.material_type,
			cell: (row) => row.material_type,sortable: true },
		...(roles[0] !== "casting-engineer" ? [
			{ name: "Cost", width: '100px',selector: row=>row.cost,
				cell: (row) => formatNumber(row?.cost).toString(),sortable: true },
			{ name: "Material Cost", width: '150px',selector: row=>row.material_cost,
				cell: (row) => formatNumber(row?.material_cost)?.toString(),sortable: true },
		]: []),
		{ name: "Production Date", width: '150px',selector: row=>row?.production_date,
			cell: (row) => row?.production_date,sortable: true },
        { name: "Created At" , width: '250px', selector: row=>row.created_at,sortable: true,
            cell: (row) => new Date(`${row.created_at}`).toLocaleDateString(
            'en-gb',
            {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                timeZone: 'utc',
                hour: 'numeric',
                minute: 'numeric',
                second:'2-digit'
            }) 
            },
		{
			name: 'Actions',
			button: true,
			width: '60px',
			cell: row => {return (permissions?.edit) ?
				(
				<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setProject({label:row?.project?.name,value:row?.project?.id})
						setType({label:row?.type,value:row?.type})
						setMaterialType({label:row?.material_type,value:row?.material_type})
						setOnUpdateItem(row)}
					}
					href="#modalUpdate" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<VscEdit />
				</a>
				)
				:
				""
			},
		},
		{
			name: '',
			button: true,
			width:'60px',
			cell: row => {return (permissions?.otherCost) ?
				(
				<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						navigate('/mold/mold-other-cost',{state:{id:row?.id}})
					}}
					 rel="noopener noreferrer">
					<FaMoneyBill />
					{/* Other costs */}
				</a>
			)
			:
			""
		},
		},
		{
			name: '',
			button: true,
			width:'60px',
			cell: row => {return (permissions?.materialCost) ?
				(
				<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						navigate('/mold/mold-material',{state:{id:row?.id}})
					}} 
					rel="noopener noreferrer">
					<CiViewList />
					{/* Materials */}
				</a>
				)
				:
				""
			},
		},
		{
			name: '',
			button: true,
			width: '40px',
			cell: row => {return (permissions?.delete) ?
				(
				<a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)}
					}
					href="#modalDelete" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<RiDeleteBin6Line />
				</a>
			)
			:
			""
		},
		},
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		if (selectedItems.length > 0){
			let promises = selectedItems.map(async (item,index)=>{
				return new Promise((resolve,reject)=>{
				setTimeout(() => 
					MoldApi.deleteMold(item.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			// Await all promises to settle
			const results = await Promise.allSettled(promises);
			const hasErrors = results.some(result => result.status === 'rejected');
			if (hasErrors) {
				results.forEach(result => {
				if (result?.status === 'rejected') {
					if (result.reason?.response && result.reason.response?.status === 404) {
						notify("Item not found, please refresh the page and retry");
						}
					else if (result?.reason?.response && result?.reason?.response?.status === 405) {
					notify("You don't have permission to perform this action.");
					} else {
					notify('An issue occurred during deleting the mold');
					}
				}
				});
			}
			else{
				setSelectedItems([])
				handleClearRows()
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			}
		}
	} 

	const deleteSingle = async () => {
		MoldApi.deleteMold(onUpdateItem.id)
		.then(res=>{
			setFilterText('')
			setSelectedItems([])
			handleClearRows()
			setPageNumber(1)
			setRefreshTable(!refreshTable)
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				notify("You don't have permission to perform this action.")
			}
			else{
				notify('An issue occured while deleting the mold')
			}
		})
	} 

	const updateItem = () => {
		if(onUpdateItem.code !== "" &&  project?.value !== "" &&  onUpdateItem.meter !== ""
			&&  onUpdateItem.meter > 0 && type?.value !== "" && materialType?.value !== ""
		){
			MoldApi.updateMold(onUpdateItem.id,onUpdateItem.code,project?.value,
				onUpdateItem.meter,type?.value,materialType?.value)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				setType({label:"Select...",value:""})
				setProject({label:"Select...",value:""})
				setMaterialType({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
			.catch(err=>{
				console.log(err)
				if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the mold please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to update the mold")
		}
	}

	const createItem = () => {
		if(code !== "" && project?.value !== "" && meter !== "" && meter > 0 
			&& type?.value !=="" && materialType?.value !==""
		){
			const data = {
				"project_id":project?.value,
				"code":code,
				"meter":meter,
				"type":type?.value,
				"production_date":getCurrentDate(),
				"material_type":materialType?.value
			}
			MoldApi.createMold(data)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				setMeter(0)
				setCode("")
				setType({label:"Select...",value:""})
				setProject({label:"Select...",value:""})
				setMaterialType({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			})
			.catch(err=>{
				if(err?.response?.data?.message){
					notify(err?.response?.data?.message)
				}
				else if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the mold please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to create a material.")
		}
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
		if(paginationPerPage !== newPerPage){
			setPaginationPerPage(newPerPage)
		}
	};

	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
			<ul className="breadcrumb">
				<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
				<li className="breadcrumb-item active">MOLD</li>
			</ul>

			<div className="row d-flex align-items-center mb-3">
				<div className='col-12 col-lg-3 col-md-3 col-sm-12 mb-3'>
					<h1 className="page-header mb-0">Mold</h1>
				</div>
				
				{(permissions?.create) && (
					<div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12 d-flex ms-auto"
					style={{gap:10,justifyContent: "flex-end",flexWrap:'wrap'}}>
					<a href="#modalAddMaterial" style={{justifyContent: "center",alignItems:'center'}}  data-bs-toggle="modal" 
						className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex btn btn-theme">
						<i className="fa fa-plus-circle fa-fw me-1"></i>
						Add
					</a>
					</div>
				)}
			</div>

			{/* <div className="row d-flex align-items-center mb-3">
				<div className='col-12 col-lg-3 col-md-3 col-sm-5 mb-3'>
					<h1 className="page-header mb-0">MOLD</h1>
				</div>
				<div className="ms-auto col-12 col-xl-2 col-lg-2 col-md-2 col-sm-3 d-flex  mb-3" style={{gap:10}}>
					<a href="#modalAddMaterial" style={{justifyContent: "center"}} data-bs-toggle="modal" className="btn btn-theme w-100"><i className="fa fa-plus-circle fa-fw me-1"></i>New</a>
				</div>
			</div> */}
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							{/* <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button>
							<div className="dropdown-menu">
								<button className="dropdown-item" href="#modalDelete" data-bs-toggle="modal" target="_blank"
									rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Delete selected</button>
							</div> */}
							<div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search By Code"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div>
						</div>

						{/* filters */}
						<div className='row mb-4'>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Project</label>
								<Select options={projectList} classNamePrefix="react-select" 
									value={selectedProject} onChange={(e)=>setSelectedProject(e)} 
									isClearable={true} onInputChange={(e)=>setProjectFilterText(e)}/>
							</div>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Type</label>
								<Select options={typeList} classNamePrefix="react-select" 
									value={fType} onChange={(e)=>setFType(e)} 
									isClearable={true}/>
							</div>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Material Type</label>
								<Select options={moldMaterialTypeList} classNamePrefix="react-select" 
									value={materialTypeF} isClearable={true} onChange={(e)=>setMaterialTypeF(e)}/>
							</div>
						</div>

						
						<div className='row mb-4' style={{justifyContent:'space-between'}}>
							<div className='col-12 col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<div className='d-flex' style={{alignItems:'center',gap:10}}>
										<h5 className="form-label costText">Mold Cost: </h5>
										<h6>{formatNumber(moldReport?.materialCost + moldReport?.otherCost)}</h6>
								</div>
							</div>
						</div>
						
						<div>
							<DataTable
								columns={columns}
								data={allItems}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

            {/* add material modal */}
            <div className="modal fade" id="modalAddMaterial">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">New Mold</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Code</label>
								<input type="text" className="form-control" placeholder="code" 
									onChange={(e)=>setCode(e.target.value)} value={code}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Project</label>
								<Select options={projectList} classNamePrefix="react-select" 
									value={project} onChange={(e)=>setProject(e)} onInputChange={(e)=>setProjectFilterText(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Meter</label>
								<input type="number" className="form-control" placeholder="meter" 
									onChange={(e)=>setMeter(e.target.value)} value={meter}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Type</label>
								<Select options={typeList} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Material Type</label>
								<Select options={moldMaterialTypeList} classNamePrefix="react-select" 
									value={materialType} onChange={(e)=>setMaterialType(e)}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>createItem()}>Create</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of add modal */}

			{/* update modal */}
			<div className="modal fade" id="modalUpdate">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update Mold</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Code</label>
								<input type="text" className="form-control" placeholder="code" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.code = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem?.code}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Project</label>
								<Select options={projectList} classNamePrefix="react-select" 
									value={project} onChange={(e)=>setProject(e)} onInputChange={(e)=>setProjectFilterText(e)}/>

							</div>
							<div className="mb-3">
								<label className="form-label">Meter</label>
								<input type="text" className="form-control" placeholder="meter" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.meter = e.target.value
										setOnUpdateItem(data)
										}} 
									value={onUpdateItem?.meter}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Type</label>
								<Select options={typeList} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Material Type</label>
								<Select options={moldMaterialTypeList} classNamePrefix="react-select" 
									value={materialType} onChange={(e)=>setMaterialType(e)}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>updateItem()}>Update</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}

			{/* delete modal */}
			<div className="modal fade" id="modalDelete">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Delete Mold</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							{(onUpdateItem?.code) ?
								(<p>Are you sure you want to delete {onUpdateItem?.code} mold?</p>)
								:
								(selectedItems?.length) ?
									(<p>Are you sure you want to delete {selectedItems?.length} molds?</p>)
									:
									(<p>No material was selected for deleting</p>)
							}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							{(onUpdateItem?.code) ?
								(							
								<button type="button" className="btn btn-theme" onClick={()=>deleteSingle()}>Delete</button>
								)
								:
								(selectedItems?.length) ?
									(
										<button type="button" className="btn btn-theme" onClick={()=>batchDelete()}>Batch Delete</button>
									)
									:
									("")
							}
						</div>
					</div>
				</div>
			</div>
            {/* end of delete modal */}

		</div>
	)
}

export default Mold;