import React, { useEffect, useState  } from 'react';
import { Card } from './../components/card/card.jsx';
import { expenseApi, transactionApi,projectApi, deptApi, userApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { VscEdit } from "react-icons/vsc";
import { FaListAlt,FaMoneyBillAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import Select from 'react-select';
import { boldTableStyle, deptTypeList, formatDate, formatNumber, ModalLoading, notify, purposeList, ScreenLoading, transactionType } from '../utils.js';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';

const $ = require('jquery');

function Dept({permissions}) {
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allItems);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);

	//new states
    const [amount,setAmount] = useState(0)
    const [deptType,setDeptType] = useState({value:"all",label:"All"})
    const [purpose,setPurpose] = useState({label:"All",value:""})
    const [createdBy,setCreatedBy] = useState({label:"All",value:""})

    const [description,setDescription] = useState("")
	const [cashboxLoading,setCashboxLoading] = useState(true)

	const [projectFilterText, setProjectFilterText] = useState('');
	const [projectList,setProjectList] = useState([])
	const [project,setProject] = useState({label:"Select...",value:""})
	const [fProject,setFProject] = useState({label:"Select...",value:""})//for filter
    const [type,setType] = useState({value:"",label:"Select..."})
    const [typeF,setTypeF] = useState({value:"",label:"Select..."})
    const [invoice_1,setInvoice_1] = useState("")
    const [invoice_2,setInvoice_2] = useState("")

    const [transactionDate, setTransactionDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

	const [deptAmount,setDeptAmount] = useState("")
	const [totalOutgoing,setTotalOutgoing] = useState("")
	const [currentAmount,setCurrentAmount] = useState("")

    //createdBy
	const [createdByFilterText, setCreatedByFilterText] = useState('');
	const [createdByList, setCreatedByList] = useState([]);
	const [fCreatedBy, setFCreatedBy] = useState({label:"Select...",value:""});

    const handleTDateChange = (date) => {
        if (date) {
          const formattedDate = format(date, 'yyyy-MM-dd'); // Format date to 'yyyy-MM-dd'
          setTransactionDate(date); // Save the date object
        } else {
            setTransactionDate(null);
        }
      };

    const handleToDateChange = (date) => {
    if (date) {
        const formattedDate = format(date, 'yyyy-MM-dd'); // Format date to 'yyyy-MM-dd'
        setToDate(date); // Save the date object
    } else {
        setToDate(null);
    }
    };

    const handleFromDateChange = (date) => {
    if (date) {
        const formattedDate = format(date, 'yyyy-MM-dd'); // Format date to 'yyyy-MM-dd'
        setFromDate(date); // Save the date object
    } else {
        setFromDate(null);
    }
    };

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		deptApi.getAllDept(
            pageNumber,
            paginationPerPage,
            purpose?.value  !== '' && purpose?.value !== undefined  ? `&purpose=${purpose?.value}` : '',
            deptType?.value  !== '' && deptType?.value !== undefined  ? `&deptType=${deptType?.value}` : '',
            (fromDate !==null && toDate !==null && fromDate !== undefined && toDate !== undefined) ? 
            `&date=${formatDate(fromDate)},${formatDate(toDate)}` : '',
            createdBy?.value  !== '' && createdBy?.value !== undefined  ? `&createdBy=${createdBy?.value}` : '',
        )
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllItems(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable,paginationPerPage,
        purpose?.value,deptType?.value,toDate,fromDate,createdBy?.value
    ])

	useEffect(()=>{
        if(permissions?.checkDepts){
            getDeptAmount(deptType?.value)
        }
    },[deptType?.value,refreshTable])

    const getDeptAmount=(deptType)=>{
        deptApi.getDeptAmounts(deptType)
		.then(res=>{
			setDeptAmount(res?.data?.data)
		})
		.catch(err=>{
			notify("An issue occured while getting loan amounts.")
		})
    }

    //created by filter
	useEffect(()=>{
		let users = []
		if(createdByFilterText === undefined){
			setCreatedByFilterText('')
		}
		userApi.getAllUsers(1,createdByFilterText,15)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				users = [...users, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setCreatedByList(users)
			})
		})
		.catch(err=>{
			notify("Unable to fetch created by list")
		})
	},[createdByFilterText])

	const columns = [
		{ name: "ID", width: '80px',selector: row=>row.id,
			cell: (row) => row.id.toString(),sortable: true },
		{ name: "Purpose", width: '150px',selector: row=>row?.purpose,
			cell: (row) => row?.purpose,sortable: true },
		{ name: "Amount", selector: row=>row.amount,
            cell: (row) => formatNumber(row?.amount)?.toString(), sortable: true },
        { name: "Paid Amount", selector: row=>row?.paid_amount,
            cell: (row) => formatNumber(row?.paid_amount)?.toString(), sortable: true },
		{ name: "Date", width: '150px',selector: row=>row?.date,
			cell: (row) => row?.date,sortable: true },
		{ name: `Reference ID`, width: '150px',selector: row=>row?.deptable_id,
			cell: (row) => row?.deptable_id,sortable: true },
        { name: "Created By", width: '150px',selector: row=>row?.createdBy?.name,
            cell: (row) => row?.createdBy?.name,sortable: true },
		{
			name: 'Actions',
			button: true,
            width:'60px',
			cell: row => {
                return (permissions?.edit) &&
                (
                    <a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)
                    }
					}
					href="#modalUpdate" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<VscEdit />
				</a>
                )
            },
		},
		{
			name: '',
			button: true,
            width:'60px',
			cell: row => {
				return (row?.type ==="incoming"  && permissions?.delete) &&
                (
				<a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)}
					}
					href="#modalDelete" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<RiDeleteBin6Line />
				</a>
				)
			},
		},
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		if (selectedItems.length > 0){
			let promises = selectedItems.map(async (item,index)=>{
				return new Promise((resolve,reject)=>{
				setTimeout(() => 
					expenseApi.deleteExpense(item.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			// Await all promises to settle
			const results = await Promise.allSettled(promises);
			const hasErrors = results.some(result => result.status === 'rejected');
			if (hasErrors) {
				results.forEach(result => {
				if (result?.status === 'rejected') {
					if (result.reason?.response && result.reason.response?.status === 404) {
						notify("Item not found, please refresh the page and retry");
						}
					else if (result?.reason?.response && result?.reason?.response?.status === 405) {
					notify("You don't have permission to perform this action.");
					} else {
					notify('An issue occurred during deleting the item');
					}
				}
				});
			}
			else{
				setSelectedItems([])
				handleClearRows()
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			}
		}
	} 

	const updateItem = () => {
		if(amount !== "" && amount > 0){
            let data = {
                pay_amount:amount,
            }
			deptApi.updateDept(onUpdateItem?.id,data)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
                setAmount(0)
                setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
			.catch(err=>{
				if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the item please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to update the item")
		}
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
	if(paginationPerPage !== newPerPage){
		setPaginationPerPage(newPerPage)
	}
	};

	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
			<ul className="breadcrumb">
				<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
				<li className="breadcrumb-item active">Loan</li>
			</ul>
			<div className="row d-flex align-items-center mb-3">
				<div className='col-12 col-lg-3 col-md-3 col-sm-5 mb-3'>
					<h1 className="page-header mb-0">Loan</h1>
				</div>
			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">

                        {/* filters */}
						<div className='row mb-4'>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Purpose</label>
								<Select options={purposeList} classNamePrefix="react-select" 
									value={purpose} onChange={(e)=>setPurpose(e)} 
									isClearable={true}/>
							</div>
                            <div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Loan Type</label>
								<Select options={deptTypeList} classNamePrefix="react-select" 
									value={deptType} onChange={(e)=>setDeptType(e)} 
									isClearable={false}/>
							</div>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">From</label>
                                <DatePicker 
                                    showIcon 
                                    dateFormat="yyyy-MM-dd" // Change to desired format
                                    selected={fromDate} 
                                    onChange={handleFromDateChange} 
                                    className="form-control"
                                    isClearable
                                />
							</div>
                            <div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">To</label>
                                <DatePicker 
                                    showIcon 
                                    dateFormat="yyyy-MM-dd" // Change to desired format
                                    selected={toDate} 
                                    onChange={handleToDateChange} 
                                    className="form-control"
                                    isClearable
                                />
							</div>
                            <div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Created By</label>
								<Select options={createdByList} classNamePrefix="react-select" 
									value={createdBy} onChange={(e)=>setCreatedBy(e)} 
									isClearable={true} onInputChange={(e)=>setCreatedByFilterText(e)}/>
							</div>
						</div>

                        <div className='row mb-4' style={{justifyContent:'space-between'}}>
							<div className='col-12 col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<div className='d-flex' style={{alignItems:'center',gap:10}}>
									<h4 className="form-label costText">Total Loan Amount: </h4>
									<h6>{formatNumber(deptAmount?.total_dept_amount)}</h6>
								</div>
							</div>
							<div className='col-12 col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<div className='d-flex' style={{alignItems:'center',gap:10}}>
										<h5 className="form-label costText">Total Paid Amount: </h5>
										<h6>{formatNumber(deptAmount?.total_paid_amount)}</h6>
								</div>
							</div>
						</div>

						<div>
							<DataTable
								columns={columns}
								data={allItems}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								// selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								// onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								// clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

			{/* update modal */}
			<div className="modal fade" id="modalUpdate">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Pay Loan</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Amount</label>
								<input type="number" className="form-control" placeholder="Amount" 
									onChange={(e)=>{setAmount(e.target.value)}} 
										value={amount}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>updateItem()}>Submit</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}
		</div>
	)
}

export default Dept;