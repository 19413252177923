import React, { useEffect,useRef, useState  } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader } from './../components/card/card.jsx';
import { materialApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { VscEdit } from "react-icons/vsc";
import { FaListAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import validator from 'validator';
import { boldTableStyle } from '../utils.js';

const $ = require('jquery');

function Purchase() {
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allItems);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);

	//new states
	const [name,setName] = useState("")
    const [type,setType] = useState("")
	const [description,setDescription] = useState("")
    const [password,setPassword] = useState("")
    const [confirmPass,setConfirmPass] = useState("")

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		materialApi.getAllMaterial(pageNumber,filterText,paginationPerPage)
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllItems(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable])

	const columns = [
		{ name: "ID", maxWidth: '50px',selector: row=>row.id,
			cell: (row) => row.id.toString(),sortable: true },
		{ name: "Name", selector: row=>row.name, sortable: true },
		{ name: "Description", selector: row=>row.description, sortable: true },
		{ name: "Type", selector: row=>row.type, sortable: true },
		{ name: "Quantity", maxWidth: '100px',selector: row=>row.quantity,
			cell: (row) => row.quantity.toString(),sortable: true },
		{
			name: '',
			button: true,
			cell: row => (
				<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setErrorMessage("")
						setOnUpdateItem(row)}
					}
					href="#modalUpdate" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<VscEdit />
					Edit
				</a>
			),
		},
		{
			name: '',
			button: true,
			cell: row => (
				<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setErrorMessage("")
						setOnUpdateItem(row)}
					}
					href="#modalDelete" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<RiDeleteBin6Line />
					Delete
				</a>
			),
		},
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		setErrorMessage('')
		if (selectedItems.length > 0){
			let promises = []
			selectedItems.map(async (item,index)=>{
				promises = new Promise((resolve,reject)=>{
				setTimeout(() => 
					materialApi.deleteMaterial(item.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			Promise.allSettled([promises]).then((results) =>{
				const hasErrors = results.some(result => result?.status === 'rejected');
				if (hasErrors) {
					results.forEach(result => {
					if (result?.status === 'rejected') {
						if (result?.reason?.response && result?.reason?.response?.status === 405) {
						setErrorMessage("You don't have permission to perform this action.");
						} else {
						setErrorMessage('An issue occurred during deleting the material');
						}
					}
					});
				}
				else{
					setSelectedItems([])
					handleClearRows()
					setFilterText('')
					setPageNumber(1)
					setRefreshTable(!refreshTable)
					$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				}
			})
		}
	} 

	const deleteSingle = async () => {
		setErrorMessage('')
		materialApi.deleteMaterial(onUpdateItem.id)
		.then(res=>{
			setFilterText('')
			setPageNumber(1)
			setRefreshTable(!refreshTable)
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				setErrorMessage("You don't have permission to perform this action.")
			}
			else{
				setErrorMessage('An issue occured while deleting the material')
			}
		})
	} 

	const updateItem = () => {
		setErrorMessage("")
		if(onUpdateItem.name !== "" &&  onUpdateItem.type !== "" &&  onUpdateItem.description !== ""){
			materialApi.updateMaterial(onUpdateItem.id,onUpdateItem.name,onUpdateItem.type,onUpdateItem.description)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				setName("")
				setDescription("")
				setType("")
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
			.catch(err=>{
				console.log(err)
				if(err.response.status === 405){
					setErrorMessage("You don't have permission to perform this action.")
				}
				else{
					setErrorMessage("Failed to create the material please refresh the page and retry.")
				}
			})
		}
		else{
			setErrorMessage("Please fill all required information to update the material")
		}
	}

	const createItem = () => {
		setErrorMessage("")
		if(name !== "" && type !== "" && description !== ""){
			setErrorMessage("")
			const data = {
				"name":name,
				"type":type,
				"description":description
			}
			materialApi.createMaterial(data)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				// setFilteredItems(res.data)
				setName("")
				setDescription("")
				setType("")
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			})
			.catch(err=>{
				console.log(err)
				if(err?.response?.data?.message){
					setErrorMessage(err?.response?.data?.message)
				}
				else if(err.response.status === 405){
					setErrorMessage("You don't have permission to perform this action.")
				}
				else{
					setErrorMessage("Failed to create the material please refresh the page and retry.")
				}
			})
		}
		else{
			setErrorMessage("Please fill all required information to create a material.")
		}
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	return (
		<div className='general_style'>
			<div className="d-flex align-items-center mb-3">
				<div>
					<ul className="breadcrumb">
						<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
						<li className="breadcrumb-item active">INVENTORY</li>
					</ul>
					<h1 className="page-header mb-0">INVENTORY</h1>
				</div>
				
				<div className="ms-auto" style={{display:'flex',gap:10}}>
					<a data-bs-toggle="modal" className="btn btn-theme text_icon_center">
						<FaListAlt />Order History
					</a>
					<a data-bs-toggle="modal" className="btn btn-theme text_icon_center">
						<FaListAlt />Purchase History
					</a>
					<a href="#modalAddOrder" data-bs-toggle="modal" className="btn btn-theme"><i className="fa fa-plus-circle fa-fw me-1"></i> New Order</a>
					<a href="#modalAddMaterial" data-bs-toggle="modal" className="btn btn-theme"><i className="fa fa-plus-circle fa-fw me-1"></i> New Material</a>
				</div>
			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							<button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button>
							<div className="dropdown-menu">
								<button className="dropdown-item" href="#modalDelete" data-bs-toggle="modal" target="_blank"
									rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Delete selected</button>
							</div>
							<div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search Material"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div>
						</div>
						
						<div>
							<DataTable
								columns={columns}
								data={allItems}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								onSelectedRowsChange={handleChange}
								clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

            {/* add material modal */}
            <div className="modal fade" id="modalAddMaterial">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Add New Material</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Name*</label>
								<input type="text" className="form-control" placeholder="name" 
									onChange={(e)=>setName(e.target.value)} value={name}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Type*</label>
								<input type="text" className="form-control" placeholder="type"
									onChange={(e)=>setType(e.target.value)} value={type}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Description*</label>
								<textarea className="form-control" rows="2" maxLength={200}
									onChange={(e)=>setDescription(e.target.value)} value={description}></textarea>
							</div>
							{(errorMessage) && (
								<div className='text-center'>
									<p style={{color:'red'}} >{errorMessage}</p>
								</div>
							)}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>createItem()}>Create</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of add modal */}

			{/* update modal */}
			<div className="modal fade" id="modalUpdate">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update Material</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">name</label>
								<input type="text" className="form-control" placeholder="name" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.name = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem?.name}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Type</label>
								<input type="type" className="form-control" placeholder="Type"
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.type = e.target.value
										setOnUpdateItem(data)
										}}
									value={(onUpdateItem?.type) ? onUpdateItem?.type : ""}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Description</label>
								<textarea className="form-control" rows="3" maxLength={200}
									onChange={(e)=>{
									let data = {...onUpdateItem}
									data.description = e.target.value
									setOnUpdateItem(data)
									}}
									value={(onUpdateItem?.description) ? onUpdateItem?.description : ""}/>
							</div>
							{/* <div className="mb-3">
								<label className="form-label">Password*</label>
								<input type="text" className="form-control" placeholder="Password" 
									onChange={(e)=>setPassword(e.target.value)} value={onUpdateItem.password}/>
							</div> */}
							{/* <div className="mb-3">
								<label className="form-label">Status*</label>
								<select className="form-select" onChange={(e)=>{
										let data = {...onUpdateItem}
										data.status = e.target.value
										setOnUpdateItem(data)
										}}
										value={onUpdateItem.status}>
									<option value="">Select One</option>
									<option value="active">Active</option>
									<option value="suspended">Suspended</option>
								</select>
							</div> */}
							{(errorMessage) && (
								<div className='text-center'>
									<p style={{color:'red'}} >{errorMessage}</p>
								</div>
							)}
							{/* <div className="mb-3">
								<label className="form-label">Tags</label>
								<TagsInput value={tags} name="tags" placeHolder="enter tags" />
							</div> */}
							{/* <div className="form-group">
								<label>Description</label>
								<textarea className="form-control" rows="10"></textarea>
							</div> */}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>updateItem()}>Update</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}

			{/* delete modal */}
			<div className="modal fade" id="modalDelete">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Delete Material</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							{(onUpdateItem?.name) ?
								(<p>Are you sure you want to delete {onUpdateItem?.name} material?</p>)
								:
								(selectedItems?.length) ?
									(<p>Are you sure you want to delete {selectedItems?.length} materials?</p>)
									:
									(<p>No material was selected for deleting</p>)
							}
						</div>
						{(errorMessage) && (
							<div className='text-center'>
								<p style={{color:'red'}} >{errorMessage}</p>
							</div>
						)}
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							{(onUpdateItem?.name) ?
								(							
								<button type="button" className="btn btn-theme" onClick={()=>deleteSingle()}>Delete</button>
								)
								:
								(selectedItems?.length) ?
									(
										<button type="button" className="btn btn-theme" onClick={()=>batchDelete()}>Batch Delete</button>
									)
									:
									("")
							}
						</div>
					</div>
				</div>
			</div>
            {/* end of delete modal */}

		</div>
	)
}

export default Purchase;