import React, { useEffect,useRef, useState  } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader } from './../components/card/card.jsx';
import { dailyWorkingTypeApi, materialApi,projectApi,finishingApi,workerApi,
    steelApi,expenseTypeApi,
    workerSalaryApi
 } from '../api/index.js';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { VscEdit } from "react-icons/vsc";
import { FaListAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import validator from 'validator';
import Select from 'react-select';
import { FaMoneyBill } from "react-icons/fa";
import { CiViewList } from "react-icons/ci";
import { Button, ButtonToolbar, ButtonGroup, Modal, Panel, Divider  } from 'rsuite';
import { notify,AlertModal, ScreenLoading, getCurrentDate, boldTableStyle, formatNumber } from '../utils.js';
import { FaCheck } from "react-icons/fa6";
import DatePicker from 'react-datepicker';
import { getDate, getMonth, getYear } from 'date-fns';
import { PiInvoiceBold } from "react-icons/pi";
import ReactToPrint from 'react-to-print';
import { FaPrint } from "react-icons/fa6";
import logo from '../assets/nubin_logo_png.png'


const $ = require('jquery');

let typeList = [
	{label:"Monthly",value:"monthly"},
	{label:"Daily",value:"daily"}
]

let statusList = [
	{label:"Paid",value:"paid"},
	{label:"Unpaid",value:"unpaid"}
]

let paymentList = [
	{label:"Cash",value:"cash"},
	{label:"Bank",value:"bank"}
]

const invoiceData = {
    id: 22,
    worker_id: 40,
    paid_date: "2024-10-23",
    amount: "1000.00",
    note: "test amount",
    created_at: "2024-10-23T18:44:59.000000Z",
    updated_at: "2024-10-23T18:44:59.000000Z"
  };

function WorkerSalary({permissions}) {
	const componentRef = useRef();
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allItems);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});
    const [message,setMessage] = useState("")
	const [isAlertOpen,setIsAlertOpen] = useState(false)
	// Modal visibility state
	const [open, setOpen] = useState(false);
	  // Open and close modal functions
	  const handleOpen = () => setOpen(true);
	  const handleClose = () => setOpen(false);
	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);
    const [amount,setAmount] = useState(0)

	//new states
	const [description,setDescription] = useState("")

	//expense type dropdown filter
	const [materialTypeFilterText, setMaterialTypeFilterText] = useState('');

	const [paymentMethod,setPaymentMethod] = useState({label:"Select...",value:""})
	const [fPaymentMethod,setFPaymentMethod] = useState({label:"Select...",value:""})

	//projects
	const [projectFilterText, setProjectFilterText] = useState('');
	const [projectList,setProjectList] = useState([])
	const [project,setProject] = useState({label:"Select...",value:""})
	const [iProject,setIProject] = useState({label:"Select...",value:""})//for insert

    const [workerFilterText, setWorkerFilterText] = useState('');
	const [workersList,setWorkersList] = useState([])
    const [fWorker,setFWorker] = useState({label:"Select...",value:""})
    const [worker,setWorker] = useState({label:"Select...",value:""})//for insert

    const [fType,setFType] = useState({label:"Select...",value:""})
	const [type,setType] = useState({label:"Select...",value:""})

    const [fStatus,setFStatus] = useState({label:"Select...",value:""})
	const [status,setStatus] = useState({label:"Select...",value:""})

    //expense type dropdown filter
	const [expenseTypeFilterText, setExpenseTypeFilterText] = useState('');
    const [expensesTypeList,setExpensesTypeList] = useState([])
    const [expenses_type,setExpenses_type] = useState({value:"",label:"Select..."})
    const [iExpenses_type,setIExpenses_type] = useState({value:"",label:"Select..."})
	
	//insert date form
	const [startDate, setStartDate] = useState(new Date());
    
	
	function handleDateChange(date) {
		console.log(getYear(date),"year")
		console.log(getMonth(date)+1,"month")
		console.log(getDate(date),"date") 
	  	setStartDate(date);
	}

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		workerSalaryApi.getWorkerSalary(
			pageNumber,
			paginationPerPage,
			fWorker?.value  !== '' && fWorker?.value !== undefined  ? `&workerID=${fWorker?.value}` : '',
            fType?.value  !== '' && fType?.value !== undefined  ? `&type=${fType?.value}` : '',
            fStatus?.value  !== '' && fStatus?.value !== undefined  ? `&status=${fStatus?.value}` : '',
			fPaymentMethod?.value  !== '' && fPaymentMethod?.value !== undefined  ? `&paymentMethod=${fPaymentMethod?.value}` : '',
		)
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllItems(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[
		resetPaginationToggle,pageNumber,refreshTable,paginationPerPage,
		fWorker?.value,fType?.value,fStatus?.value,fPaymentMethod?.value
	])

    //worker filter
	useEffect(()=>{
		// setLoading(true)
		let workerList = []
		if(workerFilterText === undefined){
			setWorkerFilterText('')
		}
		workerApi.getAllWorkers(1,workerFilterText,10)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				workerList = [...workerList, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setWorkersList(workerList)
			})
		})
		.catch(err=>{
			notify("failed to fetch workers")
		})
	},[workerFilterText])

	//project filter
	useEffect(()=>{
		let projectsList = []
		if(projectFilterText === undefined){
			setProjectFilterText('')
		}
		projectApi.getAllProject(1,projectFilterText,10,'')
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				projectsList = [...projectsList, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setProjectList(projectsList)
			})
		})
		.catch(err=>{
			notify("failed to fetch materials")
		})
	},[projectFilterText])

	const columns = [
		{ name: "Worker", width: '150px',selector: row=>row?.worker?.name,
			cell: (row) => row?.worker?.name?.toString(),sortable: true },
		{ name: "Date", width: '150px',selector: row=>row?.paid_date, 
            cell: (row) => row?.paid_date?.toString(), sortable: true },
        { name: "Year", width: '150px',selector: row=>row?.year, 
            cell: (row) => row?.year?.toString(), sortable: true }, 
		{ name: "Month", selector: row=>row?.month, 
			cell: (row) => row?.month?.toString(), sortable: true }, 
		{ name: "Salary",  width: '150px',selector: row=>row?.worker?.salary, 
			cell: (row) => formatNumber(row?.worker?.salary)?.toString(), sortable: true }, 
        { name: "Working Days",  width: '150px',selector: row=>row?.salary?.workingDays, 
            cell: (row) => row?.salary?.workingDays?.toString(), sortable: true },
		{ name: "Total Salary",  width: '150px',selector: row=>row?.salary?.sumOfDailySalary, 
			cell: (row) => formatNumber(row?.salary?.sumOfDailySalary)?.toString(), sortable: true },
		{ name: "Paid In Advance",  width: '170px',selector: row=>row?.salary?.paidAmount, 
			cell: (row) => formatNumber(row?.salary?.paidAmount)?.toString(), sortable: true },
		{ name: "Paid Salary",  width: '150px',selector: row=>row?.amount, 
			cell: (row) => formatNumber(row?.amount)?.toString(), sortable: true }, 
        { name: "Note",  width: '250px',selector: row=>row?.note, 
            cell: (row) => row?.note, sortable: true },
        { name: "Created At" , width: '250px', selector: row=>row.created_at,sortable: true,
            cell: (row) => new Date(`${row.created_at}`).toLocaleDateString(
            'en-gb',
            {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                timeZone: 'utc',
                hour: 'numeric',
                minute: 'numeric',
                second:'2-digit'
            }) 
        },
		{
            name: 'Actions',
            button: true,
            width: '60px',
            cell: row => 
				{return (permissions?.print) ?
					(
                <a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
                    onClick={()=>{
                        setOnUpdateItem(row)
						handleOpen()
					}
                    }
                    // href="#modalInvoice" data-bs-toggle="modal"
                    target="_blank" rel="noopener noreferrer">
                    <FaPrint />
                </a>
				)
				:
				""
			},
        },
        // {
        //     name: 'Actions',
        //     button: true,
        //     width: '60px',
        //     cell: row => (
        //         <a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
        //             onClick={()=>{
        //                 // setWorker({label:row?.worker?.name,value:row?.worker?.id})
        //                 setOnUpdateItem(row)}
        //             }
        //             href="#modalUpdate" data-bs-toggle="modal"
        //             target="_blank" rel="noopener noreferrer">
        //             <VscEdit />
        //         </a>
        //     ),
        // },
        {
            name: '',
            button: true,
            width: '60px',
            cell: row => 
				{return (permissions?.delete) ?
					(
                <a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
                    onClick={()=>{
                        setOnUpdateItem(row)}
                    }
                    href="#modalDelete" data-bs-toggle="modal"
                    target="_blank" rel="noopener noreferrer">
                    <RiDeleteBin6Line />
                </a>
			)
				:
				""
			},
        },
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		if (selectedItems.length > 0){
			let promises = selectedItems.map(async (item,index)=>{
				return new Promise((resolve,reject)=>{
				setTimeout(() => 
					workerSalaryApi.deleteWorkerSalary(item.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			// Await all promises to settle
			const results = await Promise.allSettled(promises);
			const hasErrors = results.some(result => result.status === 'rejected');
			if (hasErrors) {
				results.forEach(result => {
				if (result?.status === 'rejected') {
					if (result.reason?.response && result.reason.response?.status === 404) {
						notify("Item not found, please refresh the page and retry");
						}
					else if (result?.reason?.response && result?.reason?.response?.status === 405) {
					notify("You don't have permission to perform this action.");
					} else {
					notify('An issue occurred during deleting the item');
					}
				}
				});
			}
			else{
				setSelectedItems([])
				handleClearRows()
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			}
		}
	} 

	const deleteSingle = async () => {
		workerSalaryApi.deleteWorkerSalary(onUpdateItem.id)
		.then(res=>{
			setFilterText('')
			setSelectedItems([])
			handleClearRows()
			setPageNumber(1)
			setRefreshTable(!refreshTable)
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				notify("You don't have permission to perform this action.")
			}
			else{
				notify('An issue occured while deleting the item')
			}
		})
	} 

	const updateItem = () => {
		if(onUpdateItem.amount !== "" && onUpdateItem.amount > 0 &&  iProject?.value !== ""
            &&  worker?.value !== "" &&  iExpenses_type?.value !== ""
        ){ 
			finishingApi.updateFinishing(onUpdateItem.id,worker?.value,iProject?.value,
                iExpenses_type?.value, Number(onUpdateItem?.amount),
				onUpdateItem?.note ? onUpdateItem?.note : ''
            )
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				setIProject({label:"Select...",value:""})
                setWorker({label:"Select...",value:""})
				setIExpenses_type({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
			.catch(err=>{
				console.log(err)
				if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the item please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to update the item")
		}
	}

	const createItem = () => {
		if(worker?.value !== "" &&  amount !== "" && amount > 0 && startDate !==""
		){
			const data = {
				"worker_id":worker?.value,
				"year":getYear(startDate),
				"amount":amount,
				// "type":type?.value,
				"month":(getMonth(startDate)+1),
				"paid_date":getCurrentDate(),
				// "status":status?.value,
				// "payment_method":paymentMethod?.value,
				"note":description
			}
			workerSalaryApi.createWorkerSalary(data)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				// setFilteredItems(res.data)
				setAmount(0)
				setDescription("")
				setWorker({label:"Select...",value:""})
                setStatus({label:"Select...",value:""})
				setType({label:"Select...",value:""})
				setPaymentMethod({label:"Select...",value:""})
				notify("salary submitted")
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			})
			.catch(err=>{
				console.log(err)
				if(err?.response?.data?.message){
					notify(err?.response?.data?.message)
				}
				else if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the item please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to create a item.")
		}
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
		if(paginationPerPage !== newPerPage){
			setPaginationPerPage(newPerPage)
		}
	};

	const onOpenAlert = () =>{
        setIsAlertOpen(!isAlertOpen)
        setMessage("This action will Fix the item !, are you sure you want to procced?")
    }

	const renderMonthContent = (month, shortMonth, longMonth, day) => {
		const fullYear = new Date(day).getFullYear();
		const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;
		// console.log(fullYear,"year")
		// console.log(month,"month")
		return <span title={tooltipText}>{shortMonth}</span>;
	  };

	useEffect(()=>{
		if(worker?.value !=="" && startDate){
			workerSalaryApi.getPreparedSalary(
				worker?.value,
				`${getYear(startDate)}-${(getMonth(startDate)+1)}`
			)
			.then(res=>{
				setAmount(res?.data?.sumOfDailySalary-res?.data?.paidAmount)
			})
			.catch(err=>{
				if(err?.response?.data?.message){
					notify(err?.response?.data?.message)
					setAmount("")
				}
				else{
					notify("Failed to get this month's salary.")
					setAmount("")
				}
			})
		}
	},[worker?.value,startDate])

	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
            {/* <AlertModal message={message} onOpen={isAlertOpen} onSubmit={()=>onFixSteel()}
				closeAlert={()=>setIsAlertOpen(false)}/> */}
			<div className="d-flex align-items-center mb-3">
				<div>
					<ul className="breadcrumb">
						<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
						<li className="breadcrumb-item active">WORKER SALARY</li>
					</ul>
					<h1 className="page-header mb-0">WORKER SALARY</h1>
				</div>
				
			
				{(permissions?.create) && (
					<div className="ms-auto" style={{display:'flex',gap:10}}>
						<a href="#modalAdd" data-bs-toggle="modal" className="btn btn-theme"><i className="fa fa-plus-circle fa-fw me-1"></i>Add</a>
					</div>
				)}
			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							{/* <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button>
							<div className="dropdown-menu">
								<button className="dropdown-item" href="#modalDelete" data-bs-toggle="modal" target="_blank"
									rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Delete selected</button>
							</div> */}
							{/* <div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search by Code"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div> */}
						</div>

						{/* filters */}
						<div className='row mb-4'>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Worker</label>
								<Select options={workersList} classNamePrefix="react-select" 
									value={fWorker} onChange={(e)=>setFWorker(e)} 
									isClearable={true}/>
							</div>

                            {/* <div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Status</label>
								<Select options={statusList} classNamePrefix="react-select" 
									value={fStatus} onChange={(e)=>setFStatus(e)} 
									isClearable={true}/>
							</div>

							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Payment Method</label>
								<Select options={paymentList} classNamePrefix="react-select" 
									value={fPaymentMethod} onChange={(e)=>setFPaymentMethod(e)} 
									isClearable={true}/>
							</div> */}
						</div>
						
						<div>
							<DataTable
								columns={columns}
								data={allItems}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								// selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

            {/* add material modal */}
            <div className="modal fade" id="modalAdd">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Add New Item</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Worker</label>
								<Select options={workersList} classNamePrefix="react-select" 
									value={worker} onChange={(e)=>setWorker(e)} onInputChange={(e)=>setWorkerFilterText(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Year and Month</label>
								<DatePicker 
									showIcon 
									dateFormat="MM/yyyy"
									selected={startDate} 
									onChange={handleDateChange} 
									renderMonthContent={renderMonthContent}
									className="form-control"
									showMonthYearPicker
									/>
							</div>
                            <div className="mb-3">
								<label className="form-label">Amount</label>
								<input type="number" className="form-control" placeholder="amount" 
	                            	value={amount} disabled/>
							</div>
							{/* <div className="mb-3">
								<label className="form-label">Status</label>
								<Select options={statusList} classNamePrefix="react-select" 
									value={status} onChange={(e)=>setStatus(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Payment Method</label>
								<Select options={paymentList} classNamePrefix="react-select" 
									value={paymentMethod} onChange={(e)=>setPaymentMethod(e)}/>
							</div> */}
                            <div className="mb-3">
                            <label className="form-label">Description</label>
								<textarea className="form-control" rows="2" maxLength={200}
									onChange={(e)=>{setDescription(e.target.value)}} value={description}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>createItem()}>Create</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of add modal */}

			{/* update modal */}
			<div className="modal fade" id="modalUpdate">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update Item</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Project</label>
								<Select options={projectList} classNamePrefix="react-select" 
									value={iProject} onChange={(e)=>setIProject(e)}
                                    onInputChange={(e)=>setProjectFilterText(e)}/>
							</div>
                            <div className="mb-3">
								<label className="form-label">Worker</label>
								<Select options={workersList} classNamePrefix="react-select" 
									value={worker} onChange={(e)=>setWorker(e)}
                                    onInputChange={(e)=>setWorkerFilterText(e)}/>
							</div>
                            <div className="mb-3">
								<label className="form-label">Expense Type</label>
								<Select options={iExpenses_type} classNamePrefix="react-select" 
									value={iExpenses_type} onChange={(e)=>setIExpenses_type(e)}
                                    onInputChange={(e)=>setExpenseTypeFilterText(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Amount</label>
								<input type="text" className="form-control" placeholder="amount" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.amount = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem?.amount}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Note</label>
								<input type="text" className="form-control" placeholder="note" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.note = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem?.note}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>updateItem()}>Update</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}

			{/* delete modal */}
			<div className="modal fade" id="modalDelete">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Delete Item</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							{(onUpdateItem?.id) ?
								(<p>Are you sure you want to delete This Item?</p>)
								:
								(selectedItems?.length) ?
									(<p>Are you sure you want to delete {selectedItems?.length} Item?</p>)
									:
									(<p>No steel was selected for deleting</p>)
							}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							{(onUpdateItem?.id) ?
								(							
								<button type="button" className="btn btn-theme" onClick={()=>deleteSingle()}>Delete</button>
								)
								:
								(selectedItems?.length) ?
									(
										<button type="button" className="btn btn-theme" onClick={()=>batchDelete()}>Batch Delete</button>
									)
									:
									("")
							}
						</div>
					</div>
				</div>
			</div>
            {/* end of delete modal */}

			{/* Modal for Invoice */}
			<Modal open={open} onClose={handleClose} size="lg" dir="rtl">
				{/* <Modal.Header>
					<Modal.Title>فاکتور #{onUpdateItem?.id}</Modal.Title>
				</Modal.Header> */}
				<Modal.Body>
					{/* Printable Invoice Content */}
					<div ref={componentRef} style={{ padding: '20px', fontFamily: 'k24_bold ,Arial, sans-serif', direction: 'rtl', textAlign: 'right' }}>
						<Panel bordered>
							{/* Company Header */}
							<div style={styles.header}>
								<div>
									<h2 style={{ margin: 0,fontFamily:'k24_bold' }}>کارگەی نووبین</h2>
									<p style={{ margin: 0 }}>شەقامی سەرەکی، هەولێر، دهۆک</p>
									<p style={{ margin: 0 }}>ئیمەیڵ: info@nubingrc.com</p>
									<p style={{ margin: 0 }}> مۆبایل: ٠٧٥٠٥٤٩٨٥٨٠</p>

								</div>

								{/* Logo section */}
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
									<img src={logo} alt="Logo" style={{ width: '150px', height: 'auto', margin: '0 auto' }} />
								</div>

								<div style={{ textAlign: 'left' }}>
									{/* <h3  style={{fontFamily:'k24_bold' }}>فاکتور</h3> */}
									<h2 style={{ margin: 0 }}>Nubin GRC & GRP</h2>
									<p style={{ margin: 0 }}>ڕێکەوت: {new Date().toLocaleDateString()}</p>
									<p style={{ margin: 0 }}>ژمارەی وەصڵ:{onUpdateItem?.id}</p>
								</div>
							</div>

							<Divider />

							{/* Client Information */}
							<div style={styles.section}>
								<h4 style={{fontFamily:'k24_bold' }}>پارەدان بۆ:</h4>
								<p style={{fontSize:18}}><strong>ناو:</strong> {onUpdateItem?.worker?.name}</p>
							</div>

							<Divider />

							{/* Payment Details */}
							<div style={styles.section}>
								<h4 style={{fontFamily:'k24_bold' }}>وردەکاری پارەدان</h4>
								<div style={styles.table}>
									<div style={styles.tableRow}>
										<div style={styles.tableCell}><strong>ڕێکەوتی پارەدان:</strong></div>
										<div style={styles.tableCell}>{onUpdateItem?.paid_date}</div>
									</div>
									<div style={styles.tableRow}>
										<div style={styles.tableCell}><strong>بڕ:</strong></div>
										<div style={styles.tableCell}>${formatNumber(onUpdateItem?.amount)}</div>
									</div>
									<div style={styles.tableRow}>
										<div style={styles.tableCell}><strong>مانگ:</strong></div>
										<div style={styles.tableCell}>{onUpdateItem?.month} - {onUpdateItem?.year}</div>
									</div>
									<div style={styles.tableRow}>
										<div style={styles.tableCell}><strong>تێبینی:</strong></div>
										<div style={styles.tableCell}>{onUpdateItem?.note}</div>
									</div>
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' ,
								paddingLeft:100,paddingRight:100,paddingBottom:50}}>
								<h4 style={{fontFamily:'k24_bold' }}><strong>واژووی پێدەر</strong></h4>
								<h4 style={{fontFamily:'k24_bold' }}><strong>واژووی وەرگر</strong></h4>
							</div>
						</Panel>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<ReactToPrint
						trigger={() => <Button appearance="primary">چاپکردن</Button>}
						content={() => componentRef.current}
					/>
					<Button onClick={handleClose} appearance="subtle">
						داخستن
					</Button>
				</Modal.Footer>
			</Modal>

		</div>
	)
}

// Custom styles for the invoice
const styles = {
	header: {
	  display: 'flex',
	  justifyContent: 'space-between',
	  marginBottom: '20px',
	},
	section: {
	  marginBottom: '20px',
	},
	table: {
	  display: 'table',
	  width: '100%',
	},
	tableRow: {
	  display: 'table-row',
	},
	tableCell: {
	  display: 'table-cell',
	  padding: '5px 10px',
	  borderBottom: '1px solid #ddd',
	},
	footer: {
	  textAlign: 'center',
	  marginTop: '20px',
	},
	signatureRow:{
		display: 'flex',
		flex:1,
		justifyContent:'justify-between'
	}
  };
  

export default WorkerSalary;