import http from "../http-common";

export const addRole = (user_id,role)=> {
    return http.post(`/permission/add-role/${user_id}/${role}`);
}

export const removeRole = (user_id,role)=> {
    return http.post(`/permission/remove-role/${user_id}/${role}`);
}

export const getAllRoles = ()=> {
    return http.get(`/permission/roles`);
}

export const addPermission = (user_id,permission)=> {
    return http.post(`/permission/add-permission/${user_id}/${permission}`);
}

export const removePermission = (user_id,permission)=> {
    return http.post(`/permission/remove-permission/${user_id}/${permission}`);
}

export const getAllPermissions = ()=> {
    return http.get(`/permission`);
}

export const getUserPermissions = ()=> {
    return http.get(`/auth/userPermissions`);
}

