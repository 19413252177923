import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, useRoutes, useLocation,useNavigate } from "react-router-dom";
import AppRoute from './config/app-route.jsx';
import { slideToggle } from './composables/slideToggle.js';

//redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';

// bootstrap
import 'bootstrap';

// css
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/styles.scss';
import { authApi,permissionsApi } from './api/index.js';
// import { setupInterceptors } from './api/http-common.js';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import 'rsuite/dist/rsuite-no-reset.min.css';
import { CustomProvider} from 'rsuite';
import 'react-datepicker/dist/react-datepicker.css';

const container = document.getElementById('root');
const root = createRoot(container);
function App() {

	const [token,setToken]=useState(useSelector((state)=>state.auth.token))
	const navigate = useNavigate();
	let element = useRoutes(AppRoute(useSelector((state)=>state.auth.token)));
	let location = useLocation();

	// const [token,setToken]=useState(useSelector((state)=>state.auth.token))
	// React.useEffect(() => {
		// authApi.roles()
		// .then(res=>{
		// 	console.log(res,"roles")
		// })
		// .catch(err=>{
		// 	console.log(err,"roles error")

		// })
	// }, []);



	
	// on every route change
	React.useEffect(() => {
		var elm = document.querySelector('.app');
		if (elm) {
			elm.classList.remove('app-sidebar-mobile-toggled');
		}
		var elm2 = document.querySelector('.app-top-nav');
		if (elm2 && elm2.style.display === 'block') {
			slideToggle(document.querySelector('.app-top-nav'));
		}

	}, [location]);

	return element;
}

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<BrowserRouter>
				<CustomProvider theme="light">
					<App />
				</CustomProvider>
			</BrowserRouter>
		</PersistGate>
  	</Provider>
);
