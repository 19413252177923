import http from "../http-common";

export const getWorkerSalary = (page,per_page,workerID,type,status,paymentMethod)=> {
    return http.get(`/workerSalary?sort=-created_at&include=worker&pagination=true&perPage=${per_page}&page=${page}${workerID}${type}${status}${paymentMethod}`);
}

export const createWorkerSalary = (data)=> {
    return http.post(`/workerSalary`,data);
}

export const deleteWorkerSalary = (id)=> {
    return http.delete(`/workerSalary/${id}`);
}

export const updateWorkerSalary = (id,data)=> {
    return http.put(`/workerSalary/${id}`,data);
}

export const getPreparedSalary = (worker_id,date)=> {
    return http.get(`/prepare-salary-of-month?worker_id=${worker_id}&date=${date}`);
}