import React, { useEffect,useRef, useState  } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader } from './../components/card/card.jsx';
import { orderApi, userApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { VscEdit } from "react-icons/vsc";
import { FaListAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import validator from 'validator';
import { FaCheck } from "react-icons/fa";
import { useForm, useFieldArray,Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { AlertModal, boldTableStyle, formatNumber, getCurrentDate, notify, ScreenLoading } from '../utils.js';
import { TbListDetails } from "react-icons/tb";
import Select from 'react-select';
import { Toggle } from 'rsuite';

const $ = require('jquery');

const acceptedList = [
	{label:"True",value:1},
	{label:"False",value:0}
]

function Orders({permissions}) {
	const navigate = useNavigate()
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allItems);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});
	const [userId,SetUserId]=useState(useSelector((state)=>state.auth.user))
	console.log(userId,"iser id")
	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);

	//new states
	const [material,setMaterial] = useState("")
    const [quantity,setQuantity] = useState("")
	const [price,setPrice] = useState("")
    const [password,setPassword] = useState("")
    const [confirmPass,setConfirmPass] = useState("")
	const [rowId,setRowId] = useState("")
	const [invoiceId_1,setInvoiceId_1] = useState("")
	const [invoiceId_2,setInvoiceId_2] = useState("")
	const [isDept,setIsDept] = useState(false)

	//filtering
	const [userFilterText, setUserFilterText] = useState('');
	const [userList, setUserList] = useState([]);
	const [selectedUser, setSelectedUser] = useState({label:"Select...",value:""});
	const [selectedAccepted, setSelectedAccepted] = useState({label:"Select...",value:""});

	//approve form
	const { register, control, handleSubmit, reset, trigger, setError } = useForm({
        // you can populate the fields by this attribute 
        defaultValues: {
            orders: [{ material: "", quantity: "",price:"" ,accepted:""}
			]
          }
      });
      const { fields, append,replace, remove } = useFieldArray({
        control,
        name: "orders"
      });

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		orderApi.getAllOrders(
			pageNumber,
			paginationPerPage,
			filterText ? `&invoice=*${filterText}*` : '',
			selectedAccepted?.value !=='' &&  selectedAccepted?.value !== undefined ? `&accepted=${selectedAccepted?.value}` : '',
			selectedUser?.value  !=='' &&  selectedUser?.value !== undefined ? `&createdBy=${selectedUser?.value}` : ''
		)
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllItems(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable,paginationPerPage,
		selectedAccepted?.value,selectedUser?.value
	])

	const onPrepareApprove = (row) =>{
		let orderList = []
		const answersRequest = () => Promise.all(row?.items?.map(item => {
			orderList = [...orderList, {
				id:item?.id,
				material_id:item?.material_id,
				material_name:item?.material?.name,
				quantity:item?.quantity,
				price:item?.price,
				accepted:item?.quantity
			}]
		}))
		answersRequest().then(()=>{
			replace(orderList)
		})
	}

	const onPrepareEdit = (row) =>{
		let orderList = []
		const answersRequest = () => Promise.all(row?.items?.map(item => {
			orderList = [...orderList, {
				id:item?.id,
				material:{value:item?.material_id,label:item?.material?.name},
				quantity:item?.quantity,
				price:item?.price,
				accepted:0
			}]
		}))
		answersRequest().then(()=>{
			navigate('/order/edit-order',{state:{items:orderList,orderId:row?.id}})
		})
	}

	const columns = [
		{ name: "ID", maxWidth: '50px',selector: row=>row.id,
			cell: (row) => row.id.toString(),sortable: true },
		{ name: "Invoice", selector: row=>row?.invoice,
			cell: (row) => row?.invoice,sortable: true },
		{ name: "Invoice2", selector: row=>row?.invoice2,
			cell: (row) => row?.invoice2,sortable: true },
		{ name: "Created By",  width: '150px',selector: row=>row?.createdBy?.name,
			cell: (row) => row?.createdBy?.name,sortable: true },
		{ name: "Purchased By",  width: '150px',selector: row=>row?.purchased_by?.name,
			cell: (row) => row?.purchased_by?.name, sortable: true },
		{ name: "Purchased At",  width: '150px',selector: row=>row?.purchased_at,
			cell: (row) => row?.purchased_at, sortable: true },
		{ name: "Price", selector: row=>row.price, 
			cell: (row) => formatNumber(row?.price) ,sortable: true },
		{ name: "Accepted",  width: '150px', selector: row=>row.accepted, sortable: true,
			cell: (row) => (row.accepted) ? "Approved" : "Not Approved"
		},
		{ name: "Dept",  width: '150px', selector: row=>row.is_dept, sortable: true,
			cell: (row) => (row.is_dept) ? "Dept" : "Cash"
		},
		{ name: "Created At" , width: '250px', selector: row=>row.created_at,sortable: true,
		cell: (row) => new Date(`${row.created_at}`).toLocaleDateString(
		'en-gb',
		{
		  year: 'numeric',
		  month: 'long',
		  day: 'numeric',
		  timeZone: 'utc',
		  hour: 'numeric',
		  minute: 'numeric',
		  second:'2-digit'
		}) 
		},
		{
			name: 'Actions',
			button: true,
			width: '60px',
			cell: row => (
				(permissions?.edit)
				?(
					(row?.accepted) ?
					(				
						<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
							onClick={()=>{
								onPrepareApprove(row)
								setRowId(row?.id)
								setOnUpdateItem(row)
								setInvoiceId_1(row?.invoice)
								setInvoiceId_2(row?.invoice2)
							}}
							href="#modalDetails" data-bs-toggle="modal"
							target="_blank" rel="noopener noreferrer">
							<VscEdit />	
						</a>
					)
					:
					(
						<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
							onClick={()=>{
								onPrepareEdit(row)
								setInvoiceId_1("")
								setInvoiceId_2("")
							}}>
							<VscEdit /> 
						</a>
					)
				)
				:
				''
			),
		},
		{
			name: '',
			button: true,
			width: '60px',
			cell: row => {return (permissions?.accept) ?
				(
				<a style={{color:"green", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						onPrepareApprove(row)
						setRowId(row?.id)
						setInvoiceId_1("")
						setInvoiceId_2("")
					}}
					href="#modalApprove" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					{(row.accepted) ? "" : <FaCheck /> }	
				</a>
				)
				:
				""
			},
		},
		{
			name: '',
			button: true,
			width: '60px',
			cell: row => {return (permissions?.delete) ?
				(
				<a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)}
					}
					href="#modalDelete" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					{(row.accepted) ? "" : <RiDeleteBin6Line /> }	
				</a>
			)
			:
			""
		},
		},
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		if (selectedItems.length > 0){
				// let promises = []
				let promises = selectedItems.map(async (item,index)=>{
					return new Promise((resolve,reject)=>{
					setTimeout(() => {
						orderApi.deleteOrders(item.id)
						.then(res=>{
							resolve(res)
						})
						.catch(err=>{
							console.log(err)
							reject(err)
						})
					}
					,300 * index);
				})})

				// Await all promises to settle
				const results = await Promise.allSettled(promises);
				const hasErrors = results.some(result => result.status === 'rejected');
				if (hasErrors) {
					results.forEach(result => {
					if (result?.status === 'rejected') {
						if (result.reason?.response && result.reason.response?.status === 404) {
							notify("Item not found, please refresh the page and retry");
						  }
						else if (result?.reason?.response && result?.reason?.response?.status === 405) {
						notify("You don't have permission to perform this action.");
						} else {
						notify('An issue occurred during deleting the order');
						}
					}
					});
				}
				else{
					setSelectedItems([])
					handleClearRows()
					setFilterText('')
					setPageNumber(1)
					setRefreshTable(!refreshTable)
					$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				}
		}
	} 

	const deleteSingle = async () => {
		orderApi.deleteOrders(onUpdateItem.id)
		.then(res=>{
			setFilterText('')
			setPageNumber(1)
			setSelectedItems([])
			handleClearRows()
			setRefreshTable(!refreshTable)
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				notify("You don't have permission to perform this action.")
			}
			else{
				notify('An issue occured while deleting the order')
			}
		})
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
	if(paginationPerPage !== newPerPage){
		setPaginationPerPage(newPerPage)
	}
	};

	const submitOrder = ({orders}) => {
		let items = []
        const answersRequest = () => Promise.all(orders?.map(item => {
            items = [...items, {
			id:Number(item?.id),
            material_id:Number(item?.material_id),
			quantity:item?.quantity,
            quantity_accepted:Number(item?.accepted),
            price:Number(item?.price),
            accepted:true
            }]
        }))
		
        answersRequest().then(()=>{
			let data = {
				accepted:true,
				invoice_number:invoiceId_1,
				invoice_number2:invoiceId_2,
				purchased_by:userId.id,
				purchased_at:getCurrentDate(),
				items: items,
				is_dept:isDept
			}
			orderApi.updateOrders(rowId,data)
			.then(res=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			})
			.catch(err=>{
				console.log(err)
				if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the order please refresh the page and retry.")
				}
			})
        })
    }

	const onUpdateApprovedOrder = ({orders}) => {
		let data = {
			accepted:true,
			invoice_number:invoiceId_1,
			invoice_number2:invoiceId_2,
		}
		orderApi.updateOrders(rowId,data)
		.then(res=>{
			setFilterText('')
			setPageNumber(1)
			setRefreshTable(!refreshTable)
			notify("order updated")
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			console.log(err)
			if(err.response.status === 405){
				notify("You don't have permission to perform this action.")
			}
			else{
				notify("Failed to update the order please refresh the page and retry.")
			}
		})
    }

	//users filter
	useEffect(()=>{
		let users = []
		if(userFilterText === undefined){
			setUserFilterText('')
		}
		userApi.getAllUsers(1,userFilterText,15)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				users = [...users, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setUserList(users)
			})
		})
		.catch(err=>{
			notify("Unable to fetch users list")
		})
    },[userFilterText])

	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
			<ul className="breadcrumb">
				<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
				<li className="breadcrumb-item active">ORDERS</li>
			</ul>
			<div className="row d-flex mb-3">
				<div className='col-6'>
					<h1 className="page-header mb-0">ORDERS</h1>
				</div>
				
				{(permissions?.create) && (
					<div className="col-6 d-flex">
						<Link to={'/order/new-order'} className="btn btn-theme ms-auto"><i className="fa fa-plus-circle fa-fw me-1"></i> New</Link>
					</div>
				)}
			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							{/* <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button>
							<div className="dropdown-menu">
								<button className="dropdown-item" href="#modalDelete" data-bs-toggle="modal" target="_blank"
									rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Delete selected</button>
							</div> */}
							<div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search By Invoice 1"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div>
						</div>
						{/* filters */}
						<div className='row mb-4'>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Created By</label>
								<Select options={userList} classNamePrefix="react-select" 
									value={selectedUser} onChange={(e)=>setSelectedUser(e)} 
									isClearable={true}
									onInputChange={(e)=>setUserFilterText(e)}/>
							</div>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12  mb-2'>
								<label className="form-label">Accepted</label>
								<Select options={acceptedList} classNamePrefix="react-select" 
									value={selectedAccepted} isClearable={true}
									onChange={(e)=>setSelectedAccepted(e)}/>
							</div>
						</div>

						<div>
							<DataTable
								columns={columns}
								data={allItems}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								// selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								// onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								// clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

            {/* add order modal */}
            <div className="modal fade" id="modalAddOrder">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Add New Order</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Material*</label>
								<input type="text" className="form-control" placeholder="Material" 
									onChange={(e)=>setMaterial(e.target.value)} value={material}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Quantity*</label>
								<input type="text" className="form-control" placeholder="quantity"
									onChange={(e)=>setQuantity(e.target.value)} value={quantity}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Price*</label>
								<input type="text" className="form-control" placeholder="price"
									onChange={(e)=>setPrice(e.target.value)} value={price}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>console.log("clicked")}>Create</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of add modal */}

			{/* approve modal */}
			<div className="modal fade" id="modalApprove">
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Approving Order</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<form onSubmit={handleSubmit(data => submitOrder(data))}>
								<div className="modal-body">
                                    {fields.map((item, index) => (
                                    <div key={item.id} className='order_container row pb-3'>
                                        <Controller
                                        render={({ field }) => (
                                            <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                                                <label className="form-label">Material:</label>
												<input type="text" className="form-control" {...field}
													disabled/>
                                            </div>
                                        )}
                                        name={`orders.${index}.material_name`}
                                        control={control}
                                        rules={{ required: true }}
                                        />
                                        <Controller
                                        render={({ field }) => (
                                            <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                                                <label className="form-label">Requested Quantity:</label>
                                                <input className="form-control"  type="number" {...field} disabled/>
                                            </div>
                                        )}
                                        name={`orders.${index}.quantity`}
                                        control={control}
                                        rules={{ required: true }}
                                        />
                                        <Controller
											render={({ field }) => (
												<div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
													<label className="form-label">Price:</label>
													<input className="form-control"  type="number" {...field}/>
												</div>
											)}
											name={`orders.${index}.price`}
											control={control}
											rules={{ required: true }}
                                        />
										<Controller
											render={({ field }) => (
												<div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
													<label className="form-label">Accepted Quantity:</label>
													<input className="form-control"  type="number"{...field} />
												</div>
											)}
											name={`orders.${index}.accepted`}
											control={control}
											rules={{ required: true }}
                                        />
                                    </div>
                                    ))}

								<div className='row pt-5' style={{gap:'10px'}}>
									<div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
										<label className="form-label">Invoice ID 1:</label>
										<input className="form-control"  type="text" value={invoiceId_1}
											onChange={(e)=>setInvoiceId_1(e.target.value)}/>
									</div>
									<div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
										<label className="form-label">Invoice ID 2:</label>
										<input className="form-control"  type="text" value={invoiceId_2}
											onChange={(e)=>setInvoiceId_2(e.target.value)}/>
									</div>
									<div className='col-lg-3 col-md-3 col-sm-12 col-xs-12' style={{alignContent:'flex-end'}}>
										<Toggle onChange={(e)=>setIsDept(e)}>Dept</Toggle>
									</div>
								</div>
                                <div className='form_buttons row'>
                                	</div>
								</div>
								<div className="modal-footer">
									<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
									<button type="submit" className="btn btn-theme">Approve</button>
								</div>
                            </form>

							{/* <div className="mb-3">
								<label className="form-label">Material</label>
								<input type="text" className="form-control" placeholder="material" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.material = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem.material}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Quantity</label>
								<input type="type" className="form-control" placeholder="Quantity"
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.quantity = e.target.value
										setOnUpdateItem(data)
										}}
									value={(onUpdateItem?.quantity) ? onUpdateItem?.quantity : ""}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Price</label>
								<input type="text" className="form-control" placeholder="price"
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.price = e.target.value
										setOnUpdateItem(data)
										}}
										value={(onUpdateItem?.price) ? onUpdateItem?.price : ""}/>
							</div> */}
							{/* <div className="mb-3">
								<label className="form-label">Password*</label>
								<input type="text" className="form-control" placeholder="Password" 
									onChange={(e)=>setPassword(e.target.value)} value={onUpdateItem.password}/>
							</div> */}
							{/* <div className="mb-3">
								<label className="form-label">Status*</label>
								<select className="form-select" onChange={(e)=>{
										let data = {...onUpdateItem}
										data.status = e.target.value
										setOnUpdateItem(data)
										}}
										value={onUpdateItem.status}>
									<option value="">Select One</option>
									<option value="active">Active</option>
									<option value="suspended">Suspended</option>
								</select>
							</div> */}

							{/* <div className="mb-3">
								<label className="form-label">Tags</label>
								<TagsInput value={tags} name="tags" placeHolder="enter tags" />
							</div> */}
							{/* <div className="form-group">
								<label>Description</label>
								<textarea className="form-control" rows="10"></textarea>
							</div> */}

					</div>
				</div>
			</div>
            {/* end of update modal */}

			{/* details modal */}
			<div className="modal fade" id="modalDetails">
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Edit Order</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<form onSubmit={handleSubmit(data => onUpdateApprovedOrder(data))}>
								<div className="modal-body">
                                    {fields.map((item, index) => (
                                    <div key={item.id} className='order_container row pb-3'>
                                        <Controller
                                        render={({ field }) => (
                                            <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                                                <label className="form-label">Material:</label>
												<input type="text" className="form-control" {...field}
													disabled/>
                                            </div>
                                        )}
                                        name={`orders.${index}.material_name`}
                                        control={control}
                                        rules={{ required: true }}
                                        />
                                        <Controller
                                        render={({ field }) => (
                                            <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                                                <label className="form-label">Requested Quantity:</label>
                                                <input className="form-control"  type="number" {...field} disabled/>
                                            </div>
                                        )}
                                        name={`orders.${index}.quantity`}
                                        control={control}
                                        rules={{ required: true }}
                                        />
                                        <Controller
											render={({ field }) => (
												<div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
													<label className="form-label">Price:</label>
													<input className="form-control"  type="number" {...field} disabled/>
												</div>
											)}
											name={`orders.${index}.price`}
											control={control}
											rules={{ required: true }}
                                        />
										<Controller
											render={({ field }) => (
												<div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
													<label className="form-label">Accepted Quantity:</label>
													<input className="form-control"  type="number"{...field} disabled/>
												</div>
											)}
											name={`orders.${index}.accepted`}
											control={control}
											rules={{ required: true }}
                                        />
                                    </div>
                                    ))}

								<div className='row pt-5'>
									<div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
										<label className="form-label">Invoice ID 1:</label>
										<input className="form-control"  type="text" value={invoiceId_1}
											onChange={(e)=>setInvoiceId_1(e.target.value)}/>
									</div>
									<div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
										<label className="form-label">Invoice ID 2:</label>
										<input className="form-control"  type="text" value={invoiceId_2}
											onChange={(e)=>setInvoiceId_2(e.target.value)}/>
									</div>
									<div className='col-lg-3 col-md-3 col-sm-12 col-xs-12' style={{alignContent:'flex-end'}}>
										<Toggle checked={onUpdateItem?.is_dept}>Dept</Toggle>
									</div>
								</div>
                                <div className='form_buttons row'>
                                	</div>
								</div>
								<div className="modal-footer">
									<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
									<button type="submit" className="btn btn-theme">Update</button>
								</div>
                            </form>
					</div>
				</div>
			</div>
            {/* end of details modal */}

			{/* delete modal */}
			<div className="modal fade" id="modalDelete">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Delete Order</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							{(onUpdateItem?.id) ?
								(<p>Are you sure you want to delete {onUpdateItem?.invoice} order?</p>)
								:
								(selectedItems?.length) ?
									(<p>Are you sure you want to delete {selectedItems?.length} orders?</p>)
									:
									(<p>No order was selected for deleting</p>)
							}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							{(onUpdateItem?.id) ?
								(							
								<button type="button" className="btn btn-theme" onClick={()=>deleteSingle()}>Delete</button>
								)
								:
								(selectedItems?.length) ?
									(
										<button type="button" className="btn btn-theme" onClick={()=>batchDelete()}>Batch Delete</button>
									)
									:
									("")
							}
						</div>
					</div>
				</div>
			</div>
            {/* end of delete modal */}

		</div>
	)
}

export default Orders;