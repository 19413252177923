import React, { useEffect, useState  } from 'react';
import { Card } from './../components/card/card.jsx';
import {MoldApi,moldCostApi,projectApi,reportApi} from '../api/index.js';
import DataTable from "react-data-table-component";
import Select from 'react-select';
import { boldTableStyle, formatNumber, notify, ScreenLoading } from '../utils.js';
import DatePicker from 'react-datepicker';
import { getMonth, getYear } from 'date-fns';

const $ = require('jquery');

function MoldMonthlyReport() {
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(false)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allItems);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});
    const [message,setMessage] = useState("")

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);

	//new states
	const [code,setCode] = useState("")
    const [steel,setSteel] = useState({label:"Select...",value:""})
	const [fSteel,setFSteel] = useState({label:"Select...",value:""})
	const [description,setDescription] = useState("")
	const [meter,setMeter] = useState(0)
	const [status,setStatus] = useState({label:"Select...",value:""})
	const [fStatus,setFStatus] = useState({label:"Select...",value:""})
	const [type,setType] = useState({label:"Select...",value:""})
	const [fType,setFType] = useState({label:"Select...",value:""})
	const [moldReport,setMoldReport] = useState({})

	//expense type dropdown filter
	const [steelFilterText, setSteelFilterText] = useState('');
	const [unusedSteelFilterText, setUnusedSteelFilterText] = useState('');

	const [moldFilterText, setMoldFilterText] = useState('');
	const [moldList,setMoldList] = useState([])
	const [mold,setMold] = useState({label:"Select...",value:""})

	//projects
	const [projectFilterText, setProjectFilterText] = useState('');
	const [projectList,setProjectList] = useState([])
	const [project,setProject] = useState({label:"Select...",value:""})
	const [fProject,setFProject] = useState(projectList[0])//for filter
	const [totalCosts,setTotalCosts] = useState(0)//for filter

	//insert date form
	const [startDate, setStartDate] = useState(new Date());

    function handleDateChange(date) {
		// console.log(getYear(date),"year")
		// console.log(getMonth(date)+1,"month")
		// console.log(getDate(date),"date") 
	  	setStartDate(date);
	}

	useEffect(()=>{
		if(filterText === undefined){
			setFilterText('')
		}
            setLoading(true)
            reportApi.monthlMoldReport(
				mold?.value  !== '' && mold?.value !== undefined  ? `&mold=${mold?.value}` : '',
				fProject?.value  !== '' && fProject?.value !== undefined  ? `&project=${fProject?.value}` : `&project=${projectList[0]?.value}`,
				startDate !== '' && startDate !== undefined && startDate !== null ? `&month=${(getMonth(startDate)+1)}` : '',
				startDate !== '' && startDate !== undefined && startDate !== null  ? `&year=${(getYear(startDate))}` : '',
            )
            .then(res=>{
				console.log(res)
                setAllItems(res?.data)
				// calculateCosts()
                setLoading(false)
            })
            .catch(err=>{
                setLoading(false)
            })
    },[refreshTable,fProject?.value,mold?.value,startDate])

	useEffect(()=>{
		moldCostApi.getMoldReports(
			fProject?.value  !== '' && fProject?.value !== undefined  ? `project=${fProject?.value}` : '',
		)
		.then(res=>{
			setMoldReport(res?.data)
		})
		.catch(err=>{
			notify("failed to fetch mold report")
		})
	},[fProject?.value])

	//mold filter
	useEffect(()=>{
		let moldsList = []
		if(moldFilterText === undefined){
			setMoldFilterText('')
		}
		MoldApi.getAllMold(
			1,
			10,
			moldFilterText ? `&code=*${moldFilterText}*` : '',
			project?.value  !== '' && project?.value !== undefined  ? `&project=${project?.value}` : `&project=${projectList[0]?.value}`,
			''
		)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				moldsList = [...moldsList, {
				value: item.id,
				label: item.code
				}]
			}))
			answersRequest().then(()=>{
				setMoldList(moldsList)
			})
		})
		.catch(err=>{
			notify("failed to fetch mold list")
		})
	},[moldFilterText,project?.value])

	//project filter
	useEffect(()=>{
		let projectsList = []
		if(projectFilterText === undefined){
			setProjectFilterText('')
		}
		projectApi.getAllProject(1,projectFilterText,10,'')
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				projectsList = [...projectsList, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setProjectList(projectsList)
			})
		})
		.catch(err=>{
			notify("failed to fetch projects")
		})
	},[projectFilterText])

	const columns = [
		{ name: "Code", width: '150px',selector: row=>row.code,
			cell: (row) => row.code,sortable: true },
        { name: "Casting Cost",width: '150px', selector: row=>row['ready-cost'], 
            cell: (row) =>formatNumber(row['ready-cost']).toString(), width: '150px',sortable: true },
		{ name: "Steel Cost",width: '150px', selector: row=>row['steel-cost'], 
			cell: (row) =>formatNumber(row['steel-cost']).toString(), width: '150px',sortable: true },
		{ name: "Shipping Cost", selector: row=>row['shipping-cost'], 
			cell: (row) =>formatNumber(row['shipping-cost']), width: '170px',sortable: true },
		{ name: "Installation Cost", selector: row=>row['used-cost'], 
			cell: (row) =>formatNumber(row['used-cost']).toString(), width: '170px',sortable: true },
		{ name: "Finishing Cost", selector: row=>row['finishing-cost'], 
			cell: (row) =>formatNumber(row['finishing-cost']).toString(), width: '170px',sortable: true },
		{ name: "Fixing Cost", selector: row=>row['fixing-cost'], 
			cell: (row) =>formatNumber(row['fixing-cost']).toString(), width: '150px',sortable: true },
		{ name: "Total Cost" , width: '170px', sortable: true,
			cell: (row) => 
				formatNumber(row['ready-cost']+row['used-cost']+row['finishing-cost']+row['fixing-cost']+row['shipping-cost'])
		},
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
		if(paginationPerPage !== newPerPage){
			setPaginationPerPage(newPerPage)
		}
	};

    const renderMonthContent = (month, shortMonth, longMonth, day) => {
		const fullYear = new Date(day).getFullYear();
		const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;
		// console.log(fullYear,"year")
		// console.log(month,"month")
		return <span title={tooltipText}>{shortMonth}</span>;
	  };

	  useEffect(()=>{
		let costs = 0
		const answersRequest = () => Promise.all(allItems?.map(item=>{
			costs += 
			(item['ready-cost'] || 0) + 
			(item['steel-cost'] || 0) +
			(item['used-cost'] || 0) + 
			(item['finishing-cost'] || 0) + 
			(item['fixing-cost'] || 0) + 
			(item['shipping-cost'] || 0);
		
		}))
		answersRequest().then(()=>{
			setTotalCosts(formatNumber(costs))
		})
	  },[allItems])


// const calculateCosts=()=> {
// 	let costs = 0
// 	const answersRequest = () => Promise.all(allItems?.map(item=>{
// 		costs = costs + (item['ready-cost']+item['used-cost']+item['finishing-cost']+item['fixing-cost']+item['shipping-cost'])
// 	}))
// 	answersRequest().then(()=>{
// 		console.log(costs,"here")
// 		setTotalCosts(costs)
// 	})
// }
	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
			<ul className="breadcrumb">
				<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
				<li className="breadcrumb-item active">MONTHLY REPORT</li>
			</ul>
			<div className="row d-flex align-items-center mb-3">
				<div className='col-12 col-lg-3 col-md-3 col-sm-12 mb-3'>
					<h1 className="page-header mb-0">Monthly Report</h1>
				</div>
			</div>
			
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						{/* filters */}
						<div className='row mb-4'>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Project</label>
								<Select options={projectList} classNamePrefix="react-select" 
									value={fProject ? fProject : projectList[0]} onChange={(e)=>setFProject(e)}
									isClearable={false} onInputChange={(e)=>setProjectFilterText(e)}/>
							</div>

							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Mold</label>
								<Select options={moldList} classNamePrefix="react-select" 
									value={mold} onChange={(e)=>setMold(e)} 
									isClearable={true} onInputChange={(e)=>setMoldFilterText(e)}/>
							</div>

							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Year and month</label>
								<DatePicker 
										showIcon 
										dateFormat="MM/yyyy"
										selected={startDate} 
										onChange={handleDateChange} 
										renderMonthContent={renderMonthContent}
										className="form-control"
										isClearable
										showMonthYearPicker
										/>
							</div>
			
						</div>

						<div className='row mb-4' style={{justifyContent:'space-between'}}>
							<div className='col-12 col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<div className='d-flex' style={{alignItems:'center',gap:10}}>
									<h4 className="form-label costText">Total Cost: </h4>
									<h6>{formatNumber(totalCosts)}</h6>
								</div>
							</div>
							<div className='col-12 col-lg-4 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<div className='d-flex' style={{alignItems:'center',gap:10}}>
										<h5 className="form-label costText">Mold Cost: </h5>
										<h6>{formatNumber(moldReport?.materialCost + moldReport?.otherCost)}</h6>
								</div>
							</div>
						</div>

							{/* <div className='d-flex' style={{alignItems:'center',gap:10}}>
								<h4 className="form-label costText">Total Cost: </h4>
								<h6>{totalCosts}</h6>
							</div> */}
						<div>
								
					

							<DataTable
								columns={columns}
								data={allItems}
								// pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								// selectableRows
								persistTableHead
								// paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								// onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								// clearSelectedRows={toggledClearRows}
								// paginationPerPage={paginationPerPage}
								// onChangePage={handlePageChange}
								// paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>
		</div>
	)
}

export default MoldMonthlyReport;