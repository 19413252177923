import { getCurrentDate } from "../../utils";
import http from "../http-common";

export const getAll = (page,per_page,purpose)=> {
    return http.get(`/materialExpenses?sort=-id&pagination=true&include=material&perPage=${per_page}&page=${page}&purpose=${purpose}`);
}

export const getDailyCasting = (page,per_page,purpose)=> {
    return http.get(`/materialExpenses?sort=-id&pagination=true&include=material&perPage=${per_page}&page=${page}&purpose=${purpose}&date=${getCurrentDate()}`);
}

export const create = (data)=> {
    return http.post(`/materialExpenses`,data);
}

export const deleteExpense = (data)=> {
    return http.delete(`/materialExpenses/${data}`);
}

// export const update = (id,code,type)=> {
//     return http.put(`/dailyExpense/${id}?code=${code}&type=${type}`);
// }
