import React,{useEffect,useState} from 'react';
import App from './../app.jsx';
import { Navigate } from 'react-router-dom';

import Dashboard from './../pages/dashboard/dashboard.js';
import Analytics from './../pages/analytics/analytics.js';
// import EmailInbox from './../pages/email/inbox.js';
// import EmailCompose from './../pages/email/compose.js';
// import EmailDetail from './../pages/email/detail.js';
// import Widgets from './../pages/widgets/widgets.js';
// import PosCustomerOrder from './../pages/pos/customer-order.js';
// import PosKitchenOrder from './../pages/pos/kitchen-order.js';
// import PosCounterCheckout from './../pages/pos/counter-checkout.js';
// import PosTableBooking from './../pages/pos/table-booking.js';
// import PosMenuStock from './../pages/pos/menu-stock.js';
import UiBootstrap from './../pages/ui/bootstrap.js';
import UiButtons from './../pages/ui/buttons.js';
import UiCard from './../pages/ui/card.js';
import UiIcons from './../pages/ui/icons.js';
import UiModalNotifications from './../pages/ui/modal-notifications.js';
import UiTypography from './../pages/ui/typography.js';
import UiTabsAccordions from './../pages/ui/tabs-accordions.js';
import FormElements from './../pages/form/elements.js';
import FormPlugins from './../pages/form/plugins.js';
import FormWizards from './../pages/form/wizards.js';
// import TableElements from './../pages/table/elements.js';
// import TablePlugins from './../pages/table/plugins.js';
import ChartJs from './../pages/charts/js.js';
import ChartApex from './../pages/charts/apex.js';
// import Map from './../pages/map/map.js';
// import LayoutStarter from './../pages/layout/starter.js';
// import LayoutFixedFooter from './../pages/layout/fixed-footer.js';
// import LayoutFullHeight from './../pages/layout/full-height.js';
// import LayoutFullWidth from './../pages/layout/full-width.js';
// import LayoutBoxedLayout from './../pages/layout/boxed-layout.js';
// import LayoutMinifiedSidebar from './../pages/layout/minified-sidebar.js';
// import LayoutTopNav from './../pages/layout/top-nav.js';
// import LayoutMixedNav from './../pages/layout/mixed-nav.js';
// import LayoutMixedNavBoxedLayout from './../pages/layout/mixed-nav-boxed-layout.js';
// import PagesScrumBoard from './../pages/pages/scrum-board.js';
// import PagesProducts from './../pages/pages/products.js';
// import PagesProductDetails from './../pages/pages/product-details.js';
// import PagesOrders from './../pages/pages/orders.js';
// import PagesOrderDetails from './../pages/pages/order-details.js';
// import PagesGallery from './../pages/pages/gallery.js';
// import PagesSearchResults from './../pages/pages/search-results.js';
// import PagesComingSoon from './../pages/pages/coming-soon.js';
import PagesError from './../pages/pages/error.js';
// import PagesLogin from './../pages/pages/login.js';
// import PagesRegister from './../pages/pages/register.js';
// import PagesMessenger from './../pages/pages/messenger.js';
// import PagesDataManagement from './../pages/pages/data-management.js';
// import PagesFileManager from './../pages/pages/file-manager.js';
// import PagesPricing from './../pages/pages/pricing.js';
// import Profile from './../pages/profile/profile.js';
// import Calendar from './../pages/calendar/calendar.js';
// import Settings from './../pages/settings/settings.js';
// import Helper from './../pages/helper/helper.js';

//application routes
import Login from './../screens/login.js';
import Users from './../screens/users.js';
import Workers from './../screens/workers.js';
import Settings from './../screens/settings.js';
import Inventory from './../screens/inventory.js'
import Expense from '../screens/expense.js'
import Order from '../screens/order.js'
import NewOrder from '../screens/newOrder.js'
import EditOrder from '../screens/editOrder.js'
import Purchase from '../screens/purchase.js'
import { store } from '../store';
import Projects from '../screens/projects.js';
import Steel from '../screens/steel.js';
import SteelUsedMaterial from '../screens/steelUsedMaterials.js';
import SteelOtherCosts from '../screens/steelOtherCosts.js';
import Casting from '../screens/castingDailyWorking.js';
import SteelFixing from '../screens/steelFixing.js';
import CastingDailyExpense from '../screens/castingDailyExpense.js';
import CastingStoreExpense from '../screens/castingStoreExpense.js';
import Shipping from '../screens/shipping.js';
import Mold from '../screens/mold.js';
import MoldDailyExpense from '../screens/moldDailyExpense.js';
import MoldStoreExpense from '../screens/moldStoreExpense.js';
import Assembly from '../screens/assembly.js';
import AssembleDailyExpense from '../screens/assembleDailyExpense.js';
import AssembleStoreExpense from '../screens/assembleStoreExpense.js';
import Finishing from '../screens/finishing.js';
import FinishingStoreExpense from '../screens/finishingStoreExpense.js';
import FinishingDailyExpense from '../screens/finishingDailyExpense.js';
import FixingDailyExpense from '../screens/fixingDailyExpense.js';
import FixingStoreExpense from '../screens/fixingStoreExpense.js';
import WorkerSalary from '../screens/workerSalary.js';
import MoldMonthlyReport from '../screens/moldMonthlyReport.js';
import { permissionsApi } from '../api/index.js';
import MoldCost from '../screens/moldCost.js';
import MoldMaterial from '../screens/moldMaterial.js';
import DailyCasting from '../screens/dailyCasting.js';
import FixingHistory from '../screens/fixingHistory.js';
import TotalProductExpense from '../screens/totalProductExpense.js';
import TotalProductStoreExpense from '../screens/totalProductStoreExpense.js';
import AdvancePayment from '../screens/advancePayment.js';
import Transaction from '../screens/transaction.js';
import Dept from '../screens/dept.js';

// const local = JSON.parse(localStorage.getItem('persist:root'));
// let token = ''
// if(local?.auth){
// 	const authObj = JSON.parse(local?.auth);
// 	 token = authObj?.token
// }
// else{
// 	 token = ''
// }
const AppRoute = (data) => {
	const [permissions, setPermissions] = useState(null);
	const [roles, setRoles] = useState(null);
	useEffect(() => {
		if(data){
			// Fetch permissions from the API
			permissionsApi.getUserPermissions()  
			.then((response) => {
				if (response.status === 200) {
				setPermissions(response.data.data);
				setRoles(response?.data?.roles)
				}
			})
			.catch((error) => {
				console.error("Failed to fetch permissions", error);
			});
		}
	}, [data]);


  // Check if permissions are not loaded yet
//   if (!permissions) return <div>Loading...</div>;

// Define routes conditionally based on permissions
  const routes = [
    {
      path: '',
      element: <Navigate to='/projects'  permissions={permissions}/>,
    },
    permissions?.user?.view && { path: 'users', element: <Users permissions={permissions?.user}/> }, 
	permissions?.moldMonthlyReport?.view && { path: 'mold-monthly-report', element: <MoldMonthlyReport permissions={permissions?.moldMonthlyReport}/> }, 
	permissions?.project?.view && { path: 'projects', element: <Projects permissions={permissions?.project} roles={roles}/> },
	permissions?.order?.view && { path: 'order', element: <Order permissions={permissions?.order}/> }, 
	permissions?.order?.create && { path: 'order/new-order', element: <NewOrder permissions={permissions?.order}/> }, 
	permissions?.order?.edit && { path: 'order/edit-order', element: <EditOrder permissions={permissions?.order}/> }, 
	permissions?.steel?.view && { path: 'steel', element: <Steel permissions={permissions?.steel} roles={roles}/> }, 
	permissions?.steelMaterialExpense?.view && { path: 'steel/steel-material', element: <SteelUsedMaterial permissions={permissions?.steelMaterialExpense}/> }, 
	permissions?.steelCost?.view && { path: 'steel/steel-other-costs', element: <SteelOtherCosts permissions={permissions?.steelCost}/> }, 
	permissions?.totalProduct.view && { path: 'total-products', element: <Casting permissions={permissions?.totalProduct} roles={roles}/> }, 
	permissions?.dailyExpense.view && { path: 'total-products/total-product-expense', element: <TotalProductExpense permissions={permissions?.dailyExpense}/> }, 
	permissions?.materialExpenses.view && { path: 'total-products/total-product-store-expense', element: <TotalProductStoreExpense  permissions={permissions?.materialExpenses}/> }, 
	permissions?.casting.view && { path: 'daily-casting', element: <DailyCasting permissions={permissions?.casting} roles={roles}/> }, 
	permissions?.dailyExpense.view && { path: 'daily-casting/daily-expense', element: <CastingDailyExpense permissions={permissions?.dailyExpense}/> }, 
	permissions?.materialExpenses.view && { path: 'daily-casting/store-expense', element: <CastingStoreExpense permissions={permissions?.materialExpenses}/> }, 
	permissions?.fixing.view && { path: 'fixing', element: <SteelFixing permissions={permissions?.fixing} roles={roles}/> }, 
	permissions?.fixing.history && { path: 'fixing/history', element: <FixingHistory permissions={permissions?.fixing} roles={roles}/> }, 
	permissions?.dailyExpense.view && { path: 'fixing/daily-expense', element: <FixingDailyExpense permissions={permissions?.dailyExpense}/> }, 
	permissions?.materialExpenses.view && { path: 'fixing/store-expense', element: <FixingStoreExpense permissions={permissions?.materialExpenses}/> }, 
	permissions?.mold.view && { path: 'mold', element: <Mold permissions={permissions?.mold} roles={roles}/> }, 
	permissions?.moldCost.view && { path: 'mold/mold-other-cost', element: <MoldCost permissions={permissions?.moldCost}/> }, 
	permissions?.moldMaterialExpense?.view && { path: 'mold/mold-material', element: <MoldMaterial permissions={permissions?.moldMaterialExpense}/> }, 
	permissions?.installation?.view && { path: 'installation', element: <Assembly permissions={permissions?.installation} roles={roles}/> }, 
	permissions?.dailyExpense.view && { path: 'installation/daily-expense', element: <AssembleDailyExpense permissions={permissions?.dailyExpense}/> }, 
	permissions?.materialExpenses.view && { path: 'installation/store-expense', element: <AssembleStoreExpense permissions={permissions?.materialExpenses}/> }, 
	permissions?.shipping.view && { path: 'shipping', element: <Shipping permissions={permissions?.shipping}/> }, 
	permissions?.finishing.view && { path: 'finishing', element: <Finishing permissions={permissions?.finishing} roles={roles}/> }, 
	permissions?.dailyExpense.view && { path: 'finishing/daily-expense', element: <FinishingDailyExpense permissions={permissions?.dailyExpense}/> }, 
	permissions?.materialExpenses.view && { path: 'finishing/store-expense', element: <FinishingStoreExpense permissions={permissions?.materialExpenses}/> }, 
	permissions?.expenses.view && { path: 'expense', element: <Expense permissions={permissions?.expenses}/> }, 
	permissions?.transaction.view && { path: 'transaction', element: <Transaction permissions={permissions?.transaction}/> }, 
	permissions?.dept.view && { path: 'loan', element: <Dept permissions={permissions?.dept}/> },
	permissions?.material.view && { path: 'inventory', element: <Inventory permissions={permissions?.material} roles={roles}/> }, 
	permissions?.worker.view && { path: 'workers', element: <Workers permissions={permissions?.worker}/> }, 
	permissions?.paidAmount.view && { path: 'workers/advance-payment', element: <AdvancePayment permissions={permissions?.paidAmount}/> }, 
	permissions?.workerSalary.view && { path: 'worker-salary', element: <WorkerSalary permissions={permissions?.workerSalary}/> },
	{ path: 'login', element: <Login /> },
	// { path: 'settings', element: <Settings /> },
	// { path: '*', element: <Login /> },
  ].filter(Boolean); // Remove `undefined` routes when permissions are false
  return [
    {
      path: '*',
      element: <App />,
      children: (routes.length > 0 && data) ? routes : [{ path: '*', element: <Login /> }],
    },
  ];
}
			
			// { path: 'expenses', element: <Expenses /> },
		
			// { path: 'dashboard', element: <Dashboard /> }, //old routes until the end
			// { path: 'analytics', element: <Analytics /> },
			// { path: 'email/*',
			// 	children: [
			// 			{ path: 'inbox', element: <EmailInbox /> },
			// 			{ path: 'compose', element: <EmailCompose /> },
			// 			{ path: 'detail', element: <EmailDetail /> }
			// 		]
			// 	},
			// { path: 'widgets', element: <Widgets /> },
			// { path: 'pos/*',
			// 	children: [
			// 			{ path: 'customer-order', element: <PosCustomerOrder /> },
			// 			{ path: 'kitchen-order', element: <PosKitchenOrder /> },
			// 			{ path: 'counter-checkout', element: <PosCounterCheckout /> },
			// 			{ path: 'table-booking', element: <PosTableBooking /> },
			// 			{ path: 'menu-stock', element: <PosMenuStock /> }
			// 		]
			// 	},
			// { path: 'ui/*',
			// 	children: [
			// 			{ path: 'bootstrap', element: <UiBootstrap /> },
			// 			{ path: 'buttons', element: <UiButtons /> },
			// 			{ path: 'card', element: <UiCard /> },
			// 			{ path: 'icons', element: <UiIcons /> },
			// 			{ path: 'modal-notifications', element: <UiModalNotifications /> },
			// 			{ path: 'typography', element: <UiTypography /> },
			// 			{ path: 'tabs-accordions', element: <UiTabsAccordions /> },
			// 		]
			// 	},
			// { path: 'form/*',
			// 	children: [
			// 			{ path: 'elements', element: <FormElements /> },
			// 			{ path: 'plugins', element: <FormPlugins /> },
			// 			{ path: 'wizards', element: <FormWizards /> }
			// 		]
			// 	},
			// { path: 'table/*',
			// 	children: [
			// 			{ path: 'elements', element: <TableElements /> },
			// 			{ path: 'plugins', element: <TablePlugins /> }
			// 		]
			// 	},
			// { path: 'chart/*',
			// 	children: [
			// 			{ path: 'chart-js', element: <ChartJs /> },
			// 			{ path: 'chart-apex', element: <ChartApex /> }
			// 		]
			// 	},
			// { path: 'layout/*',
			// 	children: [
			// 			{ path: 'starter-page', element: <LayoutStarter /> },
			// 			{ path: 'fixed-footer', element: <LayoutFixedFooter /> },
			// 			{ path: 'full-height', element: <LayoutFullHeight /> },
			// 			{ path: 'full-width', element: <LayoutFullWidth /> },
			// 			{ path: 'boxed-layout', element: <LayoutBoxedLayout /> },
			// 			{ path: 'minified-sidebar', element: <LayoutMinifiedSidebar /> },
			// 			{ path: 'top-nav', element: <LayoutTopNav /> },
			// 			{ path: 'mixed-nav', element: <LayoutMixedNav /> },
			// 			{ path: 'mixed-nav-boxed-layout', element: <LayoutMixedNavBoxedLayout /> }
			// 		]
			// 	},
			// { path: 'pages/*',
			// 	children: [
			// 			{ path: 'scrum-board', element: <PagesScrumBoard /> },
			// 			{ path: 'products', element: <PagesProducts /> },
			// 			{ path: 'product-details', element: <PagesProductDetails /> },
			// 			{ path: 'orders', element: <PagesOrders /> },
			// 			{ path: 'order-details', element: <PagesOrderDetails /> },
			// 			{ path: 'gallery', element: <PagesGallery /> },
			// 			{ path: 'search-results', element: <PagesSearchResults /> },
			// 			{ path: 'coming-soon', element: <PagesComingSoon /> },
			// 			{ path: 'error', element: <PagesError /> },
			// 			{ path: 'login', element: <PagesLogin /> },
			// 			{ path: 'register', element: <PagesRegister /> },
			// 			{ path: 'messenger', element: <PagesMessenger /> },
			// 			{ path: 'data-management', element: <PagesDataManagement /> },
			// 			{ path: 'file-manager', element: <PagesFileManager /> },
			// 			{ path: 'pricing', element: <PagesPricing /> }
			// 		]
			// 	},
			// { path: 'map', element: <Map /> },
			// { path: 'profile', element: <Profile /> },
			// // { path: 'calendar', element: <Calendar /> },
			// { path: 'helper', element: <Helper /> },
			// { path: '*', element: <PagesError /> }


export default AppRoute;