import { useEffect, useState } from "react";
import { permissionsApi } from "../api";

const Menu = () =>{
	const [permissions, setPermissions] = useState(null);
	const [sidebarData, setSidebarData] = useState([]);

	useEffect(() => {
			// Fetch permissions from the API
			permissionsApi.getUserPermissions()  
			.then((response) => {
				if (response?.status === 200) {
					setPermissions(response?.data?.data);
				}
			})
			.catch((error) => {
				console.error("Failed to fetch permissions", error);
			});
	}, []);

	useEffect(()=>{
		const data = [
			{ is_header: true, title: 'Pages' },
			permissions?.moldMonthlyReport?.view && { path: '/mold-monthly-report', icon: 'fa fa-chart-line', title: 'Monthly Report' },
			permissions?.project?.view && { path: '/projects', icon: 'fa fa-project-diagram', title: 'Projects' },
			permissions?.material?.view && { path: '/inventory', icon: 'fa fa-boxes', title: 'Inventory' },
			permissions?.order?.view && { path: '/order', icon: 'fa fa-shopping-cart', title: 'Orders' },
			permissions?.mold?.view && { path: '/mold', icon: 'fa fa-cube', title: 'Mold' },
			permissions?.steel?.view && { path: '/steel', icon: 'fa fa-industry', title: 'Steel' },
			permissions?.totalProduct?.view && { path: '/total-products', icon: 'fa fa-warehouse', title: 'Total Products' },
			permissions?.casting?.view && { path: '/daily-casting', icon: 'fa fa-industry', title: 'Daily Casting' },
			permissions?.fixing?.view && { path: '/fixing', icon: 'fa fa-wrench', title: 'Fixing' },
			permissions?.shipping?.view && { path: '/shipping', icon: 'fa fa-truck', title: 'Shipping' },
			permissions?.installation?.view && { path: '/installation', icon: 'fa fa-screwdriver', title: 'Installation' },
			permissions?.finishing?.view && { path: '/finishing', icon: 'fa fa-check-square', title: 'Finishing' },
			permissions?.dept?.view && { path: '/loan', icon: 'fa fa-hand-holding-usd', title: 'Loan' },
			permissions?.transaction?.view && { path: '/transaction', icon: 'fa fa-exchange-alt', title: 'Transaction' },
			permissions?.expenses?.view && { path: '/expense', icon: 'fa fa-money-bill-wave', title: 'Expense' },
			permissions?.worker?.view && { path: '/workers', icon: 'fa fa-user-friends', title: 'Workers' },
			permissions?.workerSalary?.view && { path: '/worker-salary', icon: 'fa fa-dollar-sign', title: 'Worker Salary' },
			permissions?.user?.view && { path: '/users', icon: 'fa fa-users-cog', title: 'Users' },
			// { is_divider: true }, 
			// { is_header: true, title: 'Navigation' },
			// { path: '/dashboard', icon: 'fa fa-laptop', title: 'Dashboard' },
			// { path: '/analytics', icon: 'fa fa-chart-pie', title: 'Analytics' },
			// { path: '/email', icon: 'fa fa-envelope', title: 'Email', badge: '4',
			// 	children: [
			// 		{ path: '/email/inbox', title: 'Inbox' }, 
			// 		{ path: '/email/compose', title: 'Compose' }, 
			// 		{ path: '/email/detail', title: 'Detail' }
			// 	]
			// }, 
			// { is_divider: true }, 
			// { is_header: true, title: 'Components' }, 
			// { path: '/widgets', icon: 'fa fa-qrcode', title: 'Widgets' }, 
			// { path: '/pos', icon: 'fa fa-wallet', title: 'POS System',
			// 	children: [
			// 		{ path: '/pos/customer-order', title: 'Customer Order' }, 
			// 		{ path: '/pos/kitchen-order', title: 'Kitchen Order' }, 
			// 		{ path: '/pos/counter-checkout', title: 'Counter Checkout' }, 
			// 		{ path: '/pos/table-booking', title: 'Table Booking' }, 
			// 		{ path: '/pos/menu-stock', title: 'Menu Stock' }
			// 	]
			// }, 
			// { path: '/ui', icon: 'fa fa-heart', title: 'UI Kits',
			// 	children: [
			// 		{ path: '/ui/bootstrap', title: 'Bootstrap' }, 
			// 		{ path: '/ui/buttons', title: 'Buttons' }, 
			// 		{ path: '/ui/card', title: 'Card' }, 
			// 		{ path: '/ui/icons', title: 'Icons' }, 
			// 		{ path: '/ui/modal-notifications', title: 'Modal & Notifications' }, 
			// 		{ path: '/ui/typography', title: 'Typography' }, 
			// 		{ path: '/ui/tabs-accordions', title: 'Tabs & Accordions' }
			// 	]
			// }, 
			// { path: '/form', icon: 'fa fa-file-alt', title: 'Forms',
			// 	children: [
			// 		{ path: '/form/elements', title: 'Form Elements' }, 
			// 		{ path: '/form/plugins', title: 'Form Plugins' }, 
			// 		{ path: '/form/wizards', title: 'Wizards' }
			// 	]
			// }, 
			// { path: '/table', icon: 'fa fa-table', title: 'Tables',
			// 	children: [
			// 		{ path: '/table/elements', title: 'Table Elements' },
			// 		{ path: '/table/plugins', title: 'Table Plugins' }
			// 	]
			// }, 
			// { path: '/chart', icon: 'fa fa-chart-bar', title: 'Charts',
			// 	children: [
			// 		{ path: '/chart/chart-js', title: 'Chart.js' },
			// 		{ path: '/chart/chart-apex', title: 'Apexcharts.js' }
			// 	]
			// }, 
			// { path: '/map', icon: 'fa fa-map-marker-alt', title: 'Map' }, 
			// { path: '/layout', icon: 'fa fa-code-branch', title: 'Layout',
			// 	children: [
			// 		{ path: '/layout/starter-page', title: 'Starter Page' }, 
			// 		{ path: '/layout/fixed-footer', title: 'Fixed Footer' }, 
			// 		{ path: '/layout/full-height', title: 'Full Height' }, 
			// 		{ path: '/layout/full-width', title: 'Full Width' }, 
			// 		{ path: '/layout/boxed-layout', title: 'Boxed Layout' }, 
			// 		{ path: '/layout/minified-sidebar', title: 'Minified Sidebar' }, 
			// 		{ path: '/layout/top-nav', title: 'Top Nav' }, 
			// 		{ path: '/layout/mixed-nav', title: 'Mixed Nav' }, 
			// 		{ path: '/layout/mixed-nav-boxed-layout', title: 'Mixed Nav Boxed Layout' }
			// 	]
			// }, 
			// { path: '/pages', icon: 'fa fa-globe', title: 'Pages',
			// 	children: [
			// 		{ path: '/pages/scrum-board', title: 'Scrum Board' }, 
			// 		{ path: '/pages/products', title: 'Products' }, 
			// 		{ path: '/pages/product-details', title: 'Product Details' }, 
			// 		{ path: '/pages/orders', title: 'Orders' }, 
			// 		{ path: '/pages/order-details', title: 'Order Details' }, 
			// 		{ path: '/pages/gallery', title: 'Gallery' }, 
			// 		{ path: '/pages/search-results', title: 'Search Results' }, 
			// 		{ path: '/pages/coming-soon', title: 'Coming Soon Page' }, 
			// 		{ path: '/pages/error', title: 'Error Page' }, 
			// 		{ path: '/pages/login', title: 'Login' }, 
			// 		{ path: '/pages/register', title: 'Register' }, 
			// 		{ path: '/pages/messenger', title: 'Messenger' }, 
			// 		{ path: '/pages/data-management', title: 'Data Management' },
			// 		{ path: '/pages/file-manager', title: 'File Manager' }, 
			// 		{ path: '/pages/pricing', title: 'Pricing Page' }
			// 	]
			// }, 
			// { is_divider: true }, 
			// { is_header: true, title: 'Users' }, 
			// { path: '/profile', icon: 'fa fa-user-circle', title: 'Profile' }, 
			// { path: '/calendar', icon: 'fa fa-calendar', title: 'Calendar' }, 
			// { path: '/settings', icon: 'fa fa-cog', title: 'Settings' }, 
			// { path: '/helper', icon: 'fa fa-question-circle',	title: 'Helper' }
		].filter(Boolean);
		setSidebarData(data);
	},[permissions])
	return sidebarData
}


export default Menu;