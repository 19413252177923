import http from "../http-common";

//new routes
export const getAllUsers = (page,name,per_page)=> {
  return http.get(`/user?sort=-updated_at&include=roles&pagination=true&perPage=${per_page}&page=${page}&name=*${name}*`);
}

export const createUser = (data)=> {
  return http.post(`/user`,data);
}

export const deleteUser = (data)=> {
  return http.delete(`/user/${data}`);
}

export const updateUser = (id,name,email)=> {
  return http.put(`/user/${id}?name=${name}&email=${email}`);
}