import http from "../http-common";

export const getAllMoldMaterial = (page,per_page)=> {
    return http.get(`/moldMaterial?sort=-updated_at&pagination=true&perPage=${per_page}&page=${page}&include=material`);
}

export const createMoldMaterial = (data)=> {
    return http.post(`/moldMaterial`,data);
}

export const deleteMoldMaterial = (data)=> {
    return http.delete(`/moldMaterial/${data}`);
}

export const updateMoldMaterial = (id,mold_id,material_id,quantity)=> {
    return http.put(`/moldMaterial/${id}?mold_id=${mold_id}&material_id=${material_id}&quantity=${quantity}`);
}

export const getSingleMoldMaterial = (page,per_page,mold_id)=> {
    return http.get(`/moldMaterial?sort=-updated_at&pagination=true&perPage=${per_page}&page=${page}&include=mold,material&mold=${mold_id}`);
}
