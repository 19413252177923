import http from "../http-common";
import axios from "axios";

export const login = (data)=> {
    return http.post("/auth/login", data);
}

export const logout = ()=> {
  return http.post("/auth/logout");
}

export const roles = ()=> {
  return http.get("/auth/roles");
}

export const permissions = ()=> {
  return http.get("/auth/permissions");
}

export const getEngineerList = (per_page,name,page)=> {
  return http.get(`/auth/engineers?pagination=true&include=roles,permissions&perPage=${per_page}&page=${page}&name=*${name}*&role=engineer`);
}

// export const getCsrfCookie = ()=> {
//   return axios.get(`${process.env.REACT_APP_API_URL}/sanctum/csrf-cookie`, {
//     "Content-type": "application/json",
//     "withCredentials": true,
//     "Access-Control-Allow-Credentials":true,
//   });
// }