import React, { useEffect,useRef, useState  } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader } from './../components/card/card.jsx';
import { dailyWorkingTypeApi, materialApi,projectApi,ShippingApi,steelApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { VscEdit } from "react-icons/vsc";
import { FaListAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import validator from 'validator';
import Select from 'react-select';
import { FaMoneyBill } from "react-icons/fa";
import { CiViewList } from "react-icons/ci";
import { Button, ButtonToolbar, ButtonGroup } from 'rsuite';
import { notify,AlertModal, ScreenLoading, boldTableStyle, formatNumber } from '../utils.js';
import { TbListDetails } from "react-icons/tb";

const $ = require('jquery');

let typeList = [
	{label:"GRC",value:"grc"},
	{label:"GRP",value:"grp"}
]

let StatusList = [
	{label:"Used",value:"used"},
	{label:"Ready",value:"ready"},
	{label:"Broken",value:"broken"},
	{label:"Shipped",value:"shipped"}
]

function Shipping({permissions}) {
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allItems);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState([]);
    const [message,setMessage] = useState("")
	const [isAlertOpen,setIsAlertOpen] = useState(false)
	const [shippedCodes, setShippedCodes] = useState([]);

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);

	//new states
	const [code,setCode] = useState("")
    const [steel,setSteel] = useState({label:"Select...",value:""})
	const [description,setDescription] = useState("")
	const [meter,setMeter] = useState(0)
	const [status,setStatus] = useState({label:"Select...",value:""})
	const [type,setType] = useState({label:"Select...",value:""})

	const [newSteel,setNewSteel] = useState({label:"Select...",value:""})

	//expense type dropdown filter
	const [materialTypeFilterText, setMaterialTypeFilterText] = useState('');
	const [steelList,setSteelList] = useState([])

	//projects
	const [projectFilterText, setProjectFilterText] = useState('');
	const [projectList,setProjectList] = useState([])
	const [project,setProject] = useState({label:"Select...",value:""})

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		ShippingApi.getAll(pageNumber,paginationPerPage)
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllItems(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable,paginationPerPage])

	//material type filter
	useEffect(()=>{
		// setLoading(true)
		let steelsList = []
		if(materialTypeFilterText === undefined){
			setMaterialTypeFilterText('')
		}
		steelApi.getAllSteel(1,materialTypeFilterText,10)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				steelsList = [...steelsList, {
				value: item.id,
				label: item.code
				}]
			}))
			answersRequest().then(()=>{
				setSteelList(steelsList)
			})
			// setLoading(false)
		})
		.catch(err=>{
			notify("failed to fetch materials")
		})
	},[materialTypeFilterText])

	//project filter
	useEffect(()=>{
		let projectsList = []
		if(projectFilterText === undefined){
			setProjectFilterText('')
		}
		projectApi.getAllProject(1,projectFilterText,10,'')
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				projectsList = [...projectsList, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setProjectList(projectsList)
			})
		})
		.catch(err=>{
			notify("failed to fetch materials")
		})
	},[projectFilterText])

	//prepare code details
	useEffect(()=>{
		ShippingApi.getShippedCodes(1,10,onUpdateItem)
		.then(res=>{
			setShippedCodes(res?.data?.data)
		})
		.catch(err=>{
			notify("failed to fetch codes")
		})
	},[onUpdateItem])

	const columns = [
		{ name: "ID", width: '80px',selector: row=>row.id,
			cell: (row) => row.id.toString(),sortable: true },
		{ name: "Driver Name", width: '150px',selector: row=>row.driver_name,sortable: true },
		{ name: "Truck Plate Number", width: '170px',selector: row=>row.truck_plate_number,sortable: true },
		{ name: "Kren Plate Number", width: '150px',selector: row=>row.kren_plate_number,sortable: true },
		{ name: "Kren Driver Name", width: '150px',selector: row=>row.kren_driver_name,sortable: true },
		{ name: "Truck Cost", width: '150px',selector: row=>row.truck_cost, 
            cell: (row) => formatNumber(row?.truck_cost)?.toString(), sortable: true },
		{ name: "Kren Cost", width: '150px',selector: row=>row.kren_cost, 
			cell: (row) => formatNumber(row?.kren_cost)?.toString(), sortable: true },
		{ name: "Other Costs", width: '150px',selector: row=>row.other_costs, 
			cell: (row) => formatNumber(row?.other_costs)?.toString(), sortable: true },
		{ name: "Dept",  width: '150px', selector: row=>row.is_dept, sortable: true,
			cell: (row) => (row.is_dept) ? "Dept" : "Cash"
		},
		{ name: "Created At" , width: '250px',selector: row=>row.created_at,sortable: true,
			cell: (row) => new Date(`${row.created_at}`).toLocaleDateString(
			'en-gb',
			{
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				timeZone: 'utc',
				hour: 'numeric',
				minute: 'numeric',
				second:'2-digit'
			}) 
		},
		{
			name: 'Actions',
			button: true,
			cell: row => 
				{return (permissions?.codeDetails) ?
					(
				<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row?.id)
					}}
					rel="noopener noreferrer" href="#detailsModal" data-bs-toggle="modal" >
					{<TbListDetails />}					
				</a>
				)
				:
				""
			},
		},
	  ];

	const detailColumns = [
		{ name: "Code", width: '150px',selector: row=>row?.code,
			cell: (row) => row?.code,sortable: true },
		{ name: "Project", width: '150px',selector: row=>row?.project?.name,
			cell: (row) => row?.project?.name,sortable: true },
		{ name: "Meter", width: '150px',selector: row=>row?.meter,
			cell: (row) => formatNumber(row?.meter)?.toString(),sortable: true },
		{ name: "Type",selector: row=>row?.type,
			cell: (row) => row?.type?.toString(),sortable: true },
		{ name: "Date", selector: row=>row?.shipping_at,
			cell: (row) => row?.shipping_at?.toString(),sortable: true },
		{ name: "Cost", selector: row=>row?.cost,
			cell: (row) => formatNumber(row?.cost)?.toString(),sortable: true },
	];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
		if(paginationPerPage !== newPerPage){
			setPaginationPerPage(newPerPage)
		}
	};

	const onOpenAlert = () =>{
        setIsAlertOpen(!isAlertOpen)
        setMessage("This action will Fix the item !, are you sure you want to procced?")
    }

	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
			<div className="d-flex align-items-center mb-3">
				<div>
					<ul className="breadcrumb">
						<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
						<li className="breadcrumb-item active">SHIPPING</li>
					</ul>
					<h1 className="page-header mb-0">SHIPPING</h1>
				</div>
				
				<div className="ms-auto" style={{display:'flex',gap:10}}>
					{/* <Link to={'/#'} className="btn btn-theme text_icon_center">
						<FaListAlt /> Daily Expenses
					</Link>
                    <Link to={'/#'} className="btn btn-theme text_icon_center">
						<FaListAlt /> Store Expenses
					</Link> */}
					{/* <Link to={'/purchase'} className="btn btn-theme text_icon_center">
						<FaListAlt />Purchase History
					</Link> */}
					{/* <a href="#modalAddOrder" data-bs-toggle="modal" className="btn btn-theme"><i className="fa fa-plus-circle fa-fw me-1"></i> New Order</a> */}
					{/* <a href="#modalAdd" data-bs-toggle="modal" className="btn btn-theme"><i className="fa fa-plus-circle fa-fw me-1"></i>Add</a> */}
				</div>
			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							{/* <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button>
							<div className="dropdown-menu">
								<button className="dropdown-item" href="#modalDelete" data-bs-toggle="modal" target="_blank"
									rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Delete selected</button>
							</div> */}
							{/* <div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search Material"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div> */}
						</div>
						
						<div>
							<DataTable
								columns={columns}
								data={allItems}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								// selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					</div>
				</div>
			</Card>

			{/* details modal */}
            <div className="modal fade" id="detailsModal">
				<div className="modal-dialog modal-xl modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Details</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<DataTable
								columns={detailColumns}
								data={shippedCodes}
								pagination
								theme="default"
								// progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								// selectableRows
								persistTableHead
								// paginationServer
								paginationDefaultPage={1}
								// onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								// paginationPerPage={paginationPerPage}
								// onChangePage={handlePageChange}
								paginationTotalRows={onUpdateItem?.total}
							/>
						</div>
					</div>
				</div>
			</div>
            {/* end of details modal */}

		</div>
	)
}

export default Shipping;