import React, { useEffect,useRef, useState  } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader } from './../components/card/card.jsx';
import { userApi, authApi, permissionsApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { VscEdit } from "react-icons/vsc";
import { FaListAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import validator from 'validator';
import Select from 'react-select';
import { boldTableStyle, ScreenLoading } from '../utils.js';

const $ = require('jquery');

var selectOptions = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ];

function Users({permissions}) {
    const [allUsers,setAllUsers] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allUsers);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});
	const [roleList,setRoleList] = useState([])

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);

	//new user states
	const [name,setName] = useState("")
    const [email,setEmail] = useState("")
	const [role,setRole] = useState({label:"",value:""})
    const [password,setPassword] = useState("")
    const [confirmPass,setConfirmPass] = useState("")

	//update user states
	const [newRole,setNewRole] = useState({label:"Select...",value:""})

	useEffect(()=>{
		let roles = []
		permissionsApi.getAllRoles()
		.then(async res=> {
			//english
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				roles = [...roles, {
				value: item.name,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setRoleList(roles)
				setRole({value:"",label:"Select..."})
			})
		})
		.catch(err => {
			console.log(err)
			console.log("failed to get roles")
		})
    },[])

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		userApi.getAllUsers(pageNumber,filterText,paginationPerPage)
		.then(res=>{
			console.log(res?.data)
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllUsers(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable,paginationPerPage])

	const columns = [
		{ name: "ID", maxWidth: '50px',selector: row=>row.id,
			cell: (row) => row.id.toString(),sortable: true },
		{ name: "Name", selector: row=>row.name, sortable: true },
		{ name: "Email", selector: row=>row.email, sortable: true },
		{ name: "Role", selector: row=>row.roles[0], sortable: true },
		{ name: "Updated At" , selector: row=>row.updated_at,sortable: true,
			cell: (row) => new Date(`${row.updated_at}`).toLocaleDateString(
			'en-gb',
			{
			  year: 'numeric',
			  month: 'long',
			  day: 'numeric',
			  timeZone: 'utc',
			  hour: 'numeric',
			  minute: 'numeric',
			  second:'2-digit'
			}) 
		},
		{
			name: 'Actions',
			button: true,
			width:'60px',
			cell: row => 
				{return (permissions?.edit) ?
					(
						<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
						onClick={()=>{
							setErrorMessage("")
							if(row?.roles[0]){
								setNewRole({label:row.roles[0],value:row.roles[0]})
							}
							else{
								setNewRole({label:"Select...",value:""})
							}
							setOnUpdateItem(row)}
						}
						href="#modalUpdate" data-bs-toggle="modal"
						target="_blank" rel="noopener noreferrer">
						<VscEdit />
					</a>
					)
					:
					""
				},
		},
		{
			name: '',
			width:'60px',
			button: true,
			cell: row => 
				{return (permissions?.delete) ?
					(
				<a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setErrorMessage("")
						setOnUpdateItem(row)}
					}
					href="#modalDelete" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<RiDeleteBin6Line />
				</a>
				)
				:
				""
			},
		},
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const batchDeleteUser = async () => {
		setErrorMessage('')
		if (selectedItems.length > 0){
			let promises = selectedItems.map(async (user,index)=>{
				return new Promise((resolve,reject)=>{
				setTimeout(() => 
					userApi.deleteUser(user.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			// Await all promises to settle
			const results = await Promise.allSettled(promises);
			const hasErrors = results.some(result => result.status === 'rejected');
			if (hasErrors) {
				results.forEach(result => {
				if (result?.status === 'rejected') {
					if (result.reason?.response && result.reason.response?.status === 404) {
						setErrorMessage("Item not found, please refresh the page and retry");
						}
					else if (result?.reason?.response && result?.reason?.response?.status === 405) {
					setErrorMessage("You don't have permission to perform this action.");
					} else {
					setErrorMessage('An issue occurred during deleting the user');
					}
				}
				});
			}
			else{
				setSelectedItems([])
				handleClearRows()
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			}
		}
	} 

	const deleteUser = async () => {
		setErrorMessage('')
		userApi.deleteUser(onUpdateItem.id)
		.then(res=>{
			setFilterText('')
			setSelectedItems([])
			handleClearRows()
			setPageNumber(1)
			setRefreshTable(!refreshTable)
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				setErrorMessage("You don't have permission to perform this action.")
			}
			else{
				setErrorMessage('An issue occured while deleting the user')
			}
		})
	} 

	const updateUser = () => {
		setErrorMessage("")
		if(onUpdateItem.name !== "" &&  onUpdateItem.email !== ""){
			if(validator.isEmail(onUpdateItem.email)){
				// const data = {
				// 	"name":onUpdateItem.name,
				// 	"email":onUpdateItem.email,
				// }
				console.log(onUpdateItem.roles[0],"onUpdateItem.roles[0]")
				console.log(newRole?.value,"newRole")
				userApi.updateUser(onUpdateItem?.id,onUpdateItem?.name,onUpdateItem?.email)
				.then(resUser=>{
					//if role changed
					if(newRole?.value && onUpdateItem?.roles[0] !== newRole?.value){
						// removing old role and adding the new role
						if(onUpdateItem?.roles[0]){
							permissionsApi.removeRole(onUpdateItem.id,onUpdateItem?.roles[0])
							.then(()=>{
								permissionsApi.addRole(onUpdateItem?.id,newRole?.value)
								.then(()=>{
									setFilterText('')
									setPageNumber(1)
									setRefreshTable(!refreshTable)
									setNewRole({value:"",label:"Select..."})
									$("[data-bs-dismiss='modal']").trigger({ type: "click" });
								})
								.catch(err=>{
									setErrorMessage("An issue occured while adding new role")
								})
							})
							.catch(err=>{
								setErrorMessage("An issue occured while removing old role")
							})
						}else{ 
						
							// if user didn't have any old role and we only add new one
							permissionsApi.addRole(onUpdateItem.id,newRole?.value)
							.then(()=>{
								setFilterText('')
								setPageNumber(1)
								setRefreshTable(!refreshTable)
								setNewRole({value:"",label:"Select..."})
								$("[data-bs-dismiss='modal']").trigger({ type: "click" });
							})
							.catch(err=>{
								setErrorMessage("An issue occured while adding new role")
							})
						}
					}
					else if (!newRole?.value && onUpdateItem?.roles[0]){
						permissionsApi.removeRole(onUpdateItem.id,onUpdateItem?.roles[0])
						.then(()=>{
							setFilterText('')
							setPageNumber(1)
							setRefreshTable(!refreshTable)
							setNewRole({value:"",label:"Select..."})
							$("[data-bs-dismiss='modal']").trigger({ type: "click" });
						})
						.catch(err=>{
							setErrorMessage("An issue occured while removing old role")
						})
					}
					else{
						setFilterText('')
						setPageNumber(1)
						setRefreshTable(!refreshTable)
						setNewRole({value:"",label:"Select..."})
						$("[data-bs-dismiss='modal']").trigger({ type: "click" });
					}

					})
				.catch(err=>{
					if(err?.response?.status === 405){
						setErrorMessage("You don't have permission to perform this action.")
					}
					else{
						setErrorMessage("Failed to update the user please refresh the page and retry.")
					}
				})
			}
			else{
				setErrorMessage("Email address is not valid")
			}
		}
		else{
			setErrorMessage("Please fill all required information to update the user")
		}
	}

	const createUser = () => {
		setErrorMessage("")
		if(name !== "" && email !== "" && password !== "" && confirmPass !== "" && role.value !== ""){
			if(validator.isEmail(email)){
				if(password.length >= 6){
					//if password matches then create
					if(password === confirmPass){
						setErrorMessage("")
						const data = {
							"name":name,
							"email":email,
							"password":password,
							"password_confirmation":confirmPass,
						}
						userApi.createUser(data)
						.then(resUser=>{
							if(resUser?.data?.data?.id){
								permissionsApi.addRole(resUser?.data?.data?.id,role.label)
								.then(permRes=>{
									setFilterText('')
									setPageNumber(1)
									setRefreshTable(!refreshTable)
									setConfirmPass("")
									setName("")
									setPassword("")
									setEmail("")
									setRole({value:"",label:"Select One"})
									$("[data-bs-dismiss='modal']").trigger({ type: "click" });
								})
								.catch(permErr=>{
									console.log()
									setErrorMessage("User Created but failed to add Role to the new user.")
								})
							}
							else{
								setErrorMessage("User Created but failed to add Role to the new user.")
							}
						})
						.catch(err=>{
							console.log(err)
							if(err?.response?.data?.message){
								setErrorMessage(err?.response?.data?.message)
							}
							else if(err.response.status === 405){
								setErrorMessage("You don't have permission to perform this action.")
							}
							else{
								setErrorMessage("Failed to create the user please refresh the page and retry.")
							}
						})
					}
					//if password doesn't match
					else{
						setErrorMessage("password doesn't match.")
					}
				}
				else{
					setErrorMessage("The password field must be at least 6 characters.")
				}
			}
			else{
				setErrorMessage("Email address is not valid.")
			}

		}
		else{
			setErrorMessage("Please fill all required information to create a user.")
		}
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	  const handlePerRowsChange = async (newPerPage, page) => {
		if(paginationPerPage !== newPerPage){
			setPaginationPerPage(newPerPage)
		}
	  };

	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
			<div className="d-flex align-items-center mb-3">
				<div>
					<ul className="breadcrumb">
						<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
						<li className="breadcrumb-item active">USERS</li>
					</ul>
					<h1 className="page-header mb-0">USERS</h1>
				</div>
				{(permissions?.create) && (
					<div className="ms-auto">
						<a href="#modalAdd"  onClick={()=>setErrorMessage("")} data-bs-toggle="modal" className="btn btn-theme"><i className="fa fa-plus-circle fa-fw me-1"></i> Add User</a>
					</div>
				)}

			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							{/* {(permissions?.delete) &&
								(
									<>
									<button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button>
									<div className="dropdown-menu">
										<button className="dropdown-item" href="#modalDelete" data-bs-toggle="modal" target="_blank"
											rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Delete selected</button>
									</div>
									</>
								)
							} */}
							
							<div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search Users"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div>
						</div>
						
						<div>
							<DataTable
								columns={columns}
								data={allUsers}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								// selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								// onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								// clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

            {/* add modal */}
            <div className="modal fade" id="modalAdd">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Add New User</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Name*</label>
								<input type="text" className="form-control" placeholder="name" 
									onChange={(e)=>setName(e.target.value)} value={name}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Email*</label>
								<input type="email" className="form-control" placeholder="email"
									onChange={(e)=>setEmail(e.target.value)} value={email}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Role*</label>
								<Select options={roleList} classNamePrefix="react-select" 
									value={role} onChange={(e)=>setRole(e)}/>
							</div>
							
							<div className="mb-3">
								<label className="form-label">Password*</label>
								<input type="text" className="form-control" placeholder="password" 
									onChange={(e)=>setPassword(e.target.value)} value={password}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Confim Password*</label>
								<input type="text" className="form-control" placeholder="confim password" 
									onChange={(e)=>setConfirmPass(e.target.value)} value={confirmPass}/>
							</div>
							{(errorMessage) && (
								<div className='text-center'>
									<p style={{color:'red'}} >{errorMessage}</p>
								</div>
							)}
							{/* <div className="mb-3">
								<label className="form-label">Tags</label>
								<TagsInput value={tags} name="tags" placeHolder="enter tags" />
							</div> */}
							{/* <div className="form-group">
								<label>Description</label>
								<textarea className="form-control" rows="10"></textarea>
							</div> */}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>createUser()}>Create</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of add modal */}

			{/* update modal */}
			<div className="modal fade" id="modalUpdate">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update User</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">name</label>
								<input type="text" className="form-control" placeholder="name" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.name = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem?.name}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Email</label>
								<input type="email" className="form-control" placeholder="Email"
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.email = e.target.value
										setOnUpdateItem(data)
										}}
									value={(onUpdateItem?.email) ? onUpdateItem?.email : ""}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Role</label>
								<Select options={roleList} classNamePrefix="react-select" 
									value={newRole} onChange={(e)=>setNewRole(e)}
									isClearable={true}/>
							</div>
							{/* <div className="mb-3">
								<label className="form-label">Password*</label>
								<input type="text" className="form-control" placeholder="Password" 
									onChange={(e)=>setPassword(e.target.value)} value={onUpdateItem.password}/>
							</div> */}
							{/* <div className="mb-3">
								<label className="form-label">Status*</label>
								<select className="form-select" onChange={(e)=>{
										let data = {...onUpdateItem}
										data.status = e.target.value
										setOnUpdateItem(data)
										}}
										value={onUpdateItem.status}>
									<option value="">Select One</option>
									<option value="active">Active</option>
									<option value="suspended">Suspended</option>
								</select>
							</div> */}
							{(errorMessage) && (
								<div className='text-center'>
									<p style={{color:'red'}} >{errorMessage}</p>
								</div>
							)}
							{/* <div className="mb-3">
								<label className="form-label">Tags</label>
								<TagsInput value={tags} name="tags" placeHolder="enter tags" />
							</div> */}
							{/* <div className="form-group">
								<label>Description</label>
								<textarea className="form-control" rows="10"></textarea>
							</div> */}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>updateUser()}>Update</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}

			{/* delete modal */}
			<div className="modal fade" id="modalDelete">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Delete User</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							{(onUpdateItem?.name) ?
								(<p>Are you sure you want to delete {onUpdateItem?.name} user?</p>)
								:
								(selectedItems?.length) ?
									(<p>Are you sure you want to delete {selectedItems?.length} users?</p>)
									:
									(<p>No user was selected for deleting</p>)
							}
						</div>
						{(errorMessage) && (
							<div className='text-center'>
								<p style={{color:'red'}} >{errorMessage}</p>
							</div>
						)}
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							{(onUpdateItem?.name) ?
								(							
								<button type="button" className="btn btn-theme" onClick={()=>deleteUser()}>Delete</button>
								)
								:
								(selectedItems?.length) ?
									(
										<button type="button" className="btn btn-theme" onClick={()=>batchDeleteUser()}>Batch Delete</button>
									)
									:
									("")
							}
						</div>
					</div>
				</div>
			</div>
            {/* end of delete modal */}

		</div>
	)
}

export default Users;