import http from "../http-common";

export const getAllExpense = (page,per_page)=> {
    return http.get(
        `/expenses?sort=-id&pagination=true&perPage=${per_page}&page=${page}&include=createdBy`
    );
}

export const createExpense = (data)=> {
    return http.post(`/expenses`,data);
}

export const deleteExpense = (data)=> {
    return http.delete(`/expenses/${data}`);
}

export const updateExpense = (id,amount,description)=> {
    return http.put(
        `/expenses/${id}?amount=${amount}&description=${description}`
    );
}
