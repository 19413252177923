import React, { useEffect, useState } from 'react';
import { Card } from './../components/card/card.jsx';
import { NavScrollTo } from './../components/nav-scroll-to/nav-scroll-to.jsx';
import { generalInfo } from '../api/index.js';
const $ = require('jquery');

function Settings() {
    const [pricePerPage,setPricePerPage] = useState("")
    const [newPrice,setNewPrice] = useState(pricePerPage)

    useEffect(()=>{
        generalInfo.getAllGeneral()
        .then(res=>{
            console.log(res.data)
            res.data.map(item=>{
                if(item.name === "price_per_page"){
                    setPricePerPage(item.value)
                    setNewPrice(item.value)
                }
            })
        })
        .catch(err=>{
            console.log(err)
        })
    },[])

    const updatePricePerPage = () => {
        generalInfo.updateGeneral("price_per_page",{value:newPrice})
        .then(res=>{
            generalInfo.getAllGeneral()
            .then(res2=>{
                console.log(res2.data)
                res2.data.map(item=>{
                    if(item.name === "price_per_page"){
                        setPricePerPage(item.value)
                    }
                    $("[data-bs-dismiss='modal']").trigger({ type: "click" });
                })
            })
            .catch(err2=>{
                console.log(err2)
                $("[data-bs-dismiss='modal']").trigger({ type: "click" });
            })
        })
        .catch(err=>{
            console.log(err)
            $("[data-bs-dismiss='modal']").trigger({ type: "click" });
        })
    }

	return (
		<div>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-xl-10">
						<div className="row">
							<div className="col-xl-9">
								{/* <div id="general" className="mb-5">
									<h4><i className="far fa-user fa-fw"></i> General</h4>
									<p>View and update your general account information and settings.</p>
									<Card>
										<div className="list-group list-group-flush">
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Name</div>
													<div className="text-gray-500">Sean Ngu</div>
												</div>
												<div className="w-100px">
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Username</div>
													<div className="text-gray-500">@seantheme</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Phone</div>
													<div className="text-gray-500">+1-202-555-0183</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Email address</div>
													<div className="text-gray-500">support@react-studio.com</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Password</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
										</div>
									</Card>
								</div> */}
								
								{/* <div id="notifications" className="mb-5">
									<h4><i className="far fa-bell fa-fw"></i> Notifications</h4>
									<p>Enable or disable what notifications you want to receive.</p>
									<Card>
										<div className="list-group list-group-flush">
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Comments</div>
													<div className="text-gray-500 d-flex align-items-center">
														<i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> Enabled (Push, SMS)
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Tags</div>
													<div className="text-gray-500 d-flex align-items-center">
														<i className="fa fa-circle fs-8px fa-fw text-muted me-1"></i> Disabled
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Reminders</div>
													<div className="text-gray-500 d-flex align-items-center">
														<i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> Enabled (Push, Email, SMS)
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>New orders</div>
													<div className="text-gray-500 d-flex align-items-center">
														<i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> Enabled (Push, Email, SMS)
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
										</div>
									</Card>
								</div>
								 */}
								{/* <div id="privacyAndSecurity" className="mb-5">
									<h4><i className="far fa-copyright fa-fw"></i> Privacy and security</h4>
									<p>Limit the account visibility and the security settings for your website.</p>
									<Card>
										<div className="list-group list-group-flush">
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Who can see your future posts?</div>
													<div className="text-gray-500 d-flex align-items-center">
														Friends only
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Photo tagging</div>
													<div className="text-gray-500 d-flex align-items-center">
														<i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> Enabled
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Location information</div>
													<div className="text-gray-500 d-flex align-items-center">
														<i className="fa fa-circle fs-8px fa-fw text-muted me-1"></i> Disabled
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Firewall</div>
													<div className="text-gray-500 d-block d-xl-flex align-items-center">
														<div className="d-flex align-items-center"><i className="fa fa-circle fs-8px fa-fw text-muted me-1"></i> Disabled</div>
														<span className="bg-warning bg-opacity-10 text-warning ms-xl-3 mt-1 d-inline-block mt-xl-0 px-1 rounded-sm">
															<i className="fa fa-exclamation-circle text-warning fs-12px me-1"></i> 
															<span className="text-warning">Please enable the firewall for your website</span>
														</span>
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
										</div>
									</Card>
								</div> */}
								
								<div id="prices" className="mb-5">
									<h4><i className="far fa-credit-card fa-fw"></i> Prices</h4>
									<p>Manage your website printing prices</p>
									<Card>
										<div className="list-group list-group-flush">
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Price per page (IQD)</div>
													<div className="text-gray-500">
                                                        {pricePerPage}
													</div>
												</div>
												<div>
													<a href="#modalPrice" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
										</div>
									</Card>
								</div>
								
								{/* <div id="shipping" className="mb-5">
									<h4><i className="far fa-paper-plane fa-fw"></i> Shipping</h4>
									<p>Allowed shipping area and zone setting</p>
									<Card>
										<div className="list-group list-group-flush">
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Allowed shipping method</div>
													<div className="text-gray-500">
														Local, Domestic
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
										</div>
									</Card>
								</div> */}
								
								{/* <div id="mediaAndFiles" className="mb-5">
									<h4><i className="far fa-images fa-fw"></i> Media and Files</h4>
									<p>Allowed files and media format upload setting</p>
									<Card>
										<div className="list-group list-group-flush">
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Allowed files and media format</div>
													<div className="text-gray-500">
														.png, .jpg, .gif, .mp4
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Media and files cdn</div>
													<div className="text-gray-500 d-flex align-items-center">
														<i className="fa fa-circle fs-8px fa-fw text-muted me-1"></i> Disabled
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
										</div>
									</Card>
								</div> */}
								
								{/* <div id="languages" className="mb-5">
									<h4><i className="fa fa-language fa-fw"></i> Languages</h4>
									<p>Choose your language</p>
									<Card>
										<div className="list-group list-group-flush">
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Language</div>
													<div className="text-gray-500">
														English
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Auto translation</div>
													<div className="text-gray-500 d-flex align-items-center">
														<i className="fa fa-circle fs-8px fa-fw text-success me-1"></i> Enabled
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Edit</a>
												</div>
											</div>
										</div>
									</Card>
								</div> */}
								
								{/* <div id="system" className="mb-5">
									<h4><i className="far fa-hdd fa-fw"></i> System</h4>
									<p>System storage, bandwidth and database setting</p>
									<Card>
										<div className="list-group list-group-flush">
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Web storage</div>
													<div className="text-gray-500">
														40.8gb / 100gb
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px">Manage</a>
												</div>
											</div>
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Monthly bandwidth</div>
													<div className="text-gray-500">
														Unlimited
													</div>
												</div>
											</div>
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Database</div>
													<div className="text-gray-500">
														MySQL version 8.0.19
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-default w-100px disabled">Update</a>
												</div>
											</div>
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Platform</div>
													<div className="text-gray-500">
														PHP 7.4.4, NGINX 1.17.0
													</div>
												</div>
												<div>
													<a href="#modalEdit" data-bs-toggle="modal" className="btn btn-success w-100px">Update</a>
												</div>
											</div>
										</div>
									</Card>
								</div> */}
								
								{/* <div id="resetSettings" className="mb-5">
									<h4><i className="fa fa-redo fa-fw"></i> Reset settings</h4>
									<p>Reset all website setting to factory default setting.</p>
									<Card>
										<div className="list-group list-group-flush">
											<div className="list-group-item d-flex align-items-center">
												<div className="flex-fill">
													<div>Reset Settings</div>
													<div className="text-gray-500">
														This action will clear and reset all the current website setting.
													</div>
												</div>
												<div>
													<a href="#/" className="btn btn-default w-100px">Reset</a>
												</div>
											</div>
										</div>
									</Card>
								</div> */}
							</div>
							{/* <div className="col-xl-3">
								<NavScrollTo>
									<nav className="nav">
										<a className="nav-link" href="#general" data-toggle="scroll-to">General</a>
										<a className="nav-link" href="#notifications" data-toggle="scroll-to">Notifications</a>
										<a className="nav-link" href="#privacyAndSecurity" data-toggle="scroll-to">Privacy and security</a>
										<a className="nav-link" href="#prices" data-toggle="scroll-to">Prices</a>
										<a className="nav-link" href="#shipping" data-toggle="scroll-to">Shipping</a>
										<a className="nav-link" href="#mediaAndFiles" data-toggle="scroll-to">Media and Files</a>
										<a className="nav-link" href="#languages" data-toggle="scroll-to">Languages</a>
										<a className="nav-link" href="#system" data-toggle="scroll-to">System</a>
										<a className="nav-link" href="#resetSettings" data-toggle="scroll-to">Reset settings</a>
									</nav>
								</NavScrollTo>
							</div> */}
						</div>
					</div>
				</div>
			</div>
			
            <div className="modal fade" id="modalPrice">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Edit Price Per Page</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Value</label>
								<div className="row">
									<div className="col-12">
										<input className="form-control" placeholder="Value" value={newPrice} 
                                            onChange={(e)=>setNewPrice(e.target.value)}/>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-default" data-bs-dismiss="modal">Close</button>
							<button type="button" className="btn btn-theme" onClick={()=>updatePricePerPage()}>Save changes</button>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="modalEdit">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Edit name</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Name</label>
								<div className="row">
									<div className="col-4">
										<input className="form-control" placeholder="First" value="Sean" />
									</div>
									<div className="col-4">
										<input className="form-control" placeholder="Middle" value="" />
									</div>
									<div className="col-4">
										<input className="form-control" placeholder="Last" value="Ngu" />
									</div>
								</div>
							</div>
							<div className="alert bg-body">
								<b>Please note:</b> 
								If you change your name, you can't change it again for 60 days. 
								Don't add any unusual capitalization, punctuation, characters or random words. 
								<a href="#/" className="alert-link">Learn more.</a>
							</div>
							<div className="mb-3">
								<label className="form-label">Other Names</label>
								<div>
									<a href="#/" className="btn btn-outline-default"><i className="fa fa-plus fa-fw"></i> Add other names</a>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-default" data-bs-dismiss="modal">Close</button>
							<button type="button" className="btn btn-theme">Save changes</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Settings;