import http from "../http-common";

export const getAllSteelCosts = (page,per_page)=> {
    return http.get(`/steelCost?sort=-updated_at&pagination=true&perPage=${per_page}&page=${page}&include=expensesType,worker,steel`);
}

export const createSteelCosts = (data)=> {
    return http.post(`/steelCost`,data);
}

export const deleteSteelCosts = (data)=> {
    return http.delete(`/steelCost/${data}`);
}

export const updateSteelCosts = (id,steel_id,worker_id,cost,note)=> {
    return http.put(`/steelCost/${id}?steel_id=${steel_id}&worker_id=${worker_id}&cost=${cost}&note=${note}`);
}
export const getSingleSteelCosts = (page,per_page,steel_id)=> {
    return http.get(`/steelCost?sort=-updated_at&pagination=true&perPage=${per_page}&page=${page}&include=worker,steel&steel=${steel_id}`);
}
