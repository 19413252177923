import React, { useEffect,useRef, useState  } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader } from './../components/card/card.jsx';
import { dailyWorkingTypeApi, materialApi,MoldApi,projectApi,ShippingApi,steelApi, userApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { VscEdit } from "react-icons/vsc";
import { FaListAlt, FaCheckCircle,FaTasks,FaMoneyBill,FaCheck  } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import validator from 'validator';
import Select from 'react-select';
import { CiViewList } from "react-icons/ci";
import { Button, ButtonToolbar, ButtonGroup, Toggle } from 'rsuite';
import { notify, AlertModal, ScreenLoading,getCurrentDate, boldTableStyle, formatNumber } from '../utils.js';
import { MdBrokenImage } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";

const $ = require('jquery');

let typeList = [
	{label:"GRC",value:"grc"},
	{label:"GRP",value:"grp"}
]

let StatusList = [
	{label:"Used",value:"used"},
	{label:"Ready",value:"ready"},
	{label:"Broken",value:"broken"},
	{label:"Shipped",value:"shipped"}
]

function CastingDailyWorking({permissions,roles}) {
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allItems);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});
    const [message,setMessage] = useState("")

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);

	//new states
	const [code,setCode] = useState("")
    const [steel,setSteel] = useState({label:"Select...",value:""})
	const [fSteel,setFSteel] = useState({label:"Select...",value:""})
	const [description,setDescription] = useState("")
	const [meter,setMeter] = useState(0)
	const [status,setStatus] = useState({label:"Select...",value:""})
	const [fStatus,setFStatus] = useState({label:"Select...",value:""})
	const [type,setType] = useState({label:"Select...",value:""})
	const [fType,setFType] = useState({label:"Select...",value:""})
    const [isDept,setIsDept] = useState(false)

	const [newSteel,setNewSteel] = useState({label:"Select...",value:""})
	const [updateData,setUpdateData] = useState("")

	//expense type dropdown filter
	const [steelFilterText, setSteelFilterText] = useState('');
	const [unusedSteelFilterText, setUnusedSteelFilterText] = useState('');
	const [steelList,setSteelList] = useState([])
	const [unusedSteelList,setUnusedSteelList] = useState([])

	const [moldFilterText, setMoldFilterText] = useState('');
	const [moldFilteringText, setMoldFilteringText] = useState('');//filtering section
	const [moldList,setMoldList] = useState([])
	const [mold,setMold] = useState({label:"Select...",value:""})
	const [fMold,setFMold] = useState({label:"Select...",value:""})

	//createdBy
	const [createdByFilterText, setCreatedByFilterText] = useState('');
	const [createdByList, setCreatedByList] = useState([]);
	const [fCreatedBy, setFCreatedBy] = useState({label:"Select...",value:""});

	//projects
	const [projectFilterText, setProjectFilterText] = useState('');
	const [projectList,setProjectList] = useState([])
	const [project,setProject] = useState({label:"Select...",value:""})
	const [fProject,setFProject] = useState({label:"Select...",value:""})//for filter

	const [isAlertOpen,setIsAlertOpen] = useState(false)

	//shipping states
	const [driver_name, setDriver_name] = useState('');
	const [truck_plate_number, setTruck_plate_number] = useState('');
	const [kren_plate_number, setKren_plate_number] = useState('');
	const [kren_driver_name, setKren_driver_name] = useState('');
	const [truck_cost, setTruck_cost] = useState(0);
	const [kren_cost, setKren_cost] = useState(0);
	const [other_costs, setOther_costs] = useState(0);


	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		dailyWorkingTypeApi.getAllCasting(
			pageNumber,
			paginationPerPage,
			filterText ? `&code=*${filterText}*` : '',
			fProject?.value  !== '' && fProject?.value !== undefined  ? `&project=${fProject?.value}` : '',
			fType?.value !== '' && fType?.value !== undefined ? `&type=${fType?.value}` : '',
			fStatus?.value !== '' && fStatus?.value !== undefined ? `&status=${fStatus?.value}` : '',
			fSteel?.value !== '' && fSteel?.value !== undefined ? `&steel=${fSteel?.value}` : '',
			fMold?.value !== '' && fMold?.value !== undefined ? `&mold=${fMold?.value}` : '',
			fCreatedBy?.value !== '' && fCreatedBy?.value !== undefined ? `&createdBy=${fCreatedBy?.value}` : ''
		)
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllItems(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable,paginationPerPage,
		fProject?.value,fType?.value,fStatus?.value,fSteel?.value,fMold,fCreatedBy?.value
	])

	//created by filter
	useEffect(()=>{
		let users = []
		if(createdByFilterText === undefined){
			setCreatedByFilterText('')
		}
		userApi.getAllUsers(1,createdByFilterText,15)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				users = [...users, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setCreatedByList(users)
			})
		})
		.catch(err=>{
			notify("Unable to fetch created by list")
		})
	},[createdByFilterText])

	//unused steel filter
	useEffect(()=>{
		// setLoading(true)
		let steelsList = []
		if(unusedSteelFilterText === undefined){
			setUnusedSteelFilterText('')
		}
		steelApi.getUnusedSteel(1,unusedSteelFilterText,10)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				steelsList = [...steelsList, {
				value: item.id,
				label: item.code
				}]
			}))
			answersRequest().then(()=>{
				setUnusedSteelList(steelsList)
			})
			// setLoading(false)
		})
		.catch(err=>{
			notify("failed to fetch materials")
		})
	},[unusedSteelFilterText])

	//all steel filter
	useEffect(()=>{
		// setLoading(true)
		let steelsList = []
		if(steelFilterText === undefined){
			setSteelFilterText('')
		}
		steelApi.getAllSteel(1,steelFilterText,10,'')
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				steelsList = [...steelsList, {
				value: item.id,
				label: item.code
				}]
			}))
			answersRequest().then(()=>{
				setSteelList(steelsList)
			})
			// setLoading(false)
		})
		.catch(err=>{
			notify("failed to fetch materials")
		})
	},[steelFilterText])

	//mold create and update filter
	useEffect(()=>{
		let moldsList = []
		if(moldFilterText === undefined){
			setMoldFilterText('')
		}
		MoldApi.getAllMold(
			1,
			10,
			moldFilterText ? `&code=*${moldFilterText}*` : '',
			// project?.value  !== '' && project?.value !== undefined  ? `&project=${project?.value}` : '',
			'',
			''
		)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				moldsList = [...moldsList, {
				value: item.id,
				label: item.code
				}]
			}))
			answersRequest().then(()=>{
				setMoldList(moldsList)
			})
		})
		.catch(err=>{
			notify("failed to fetch mold list")
		})
	},[moldFilterText])

	//mold filter section
	useEffect(()=>{
		let moldsList = []
		if(moldFilteringText === undefined){
			setMoldFilteringText('')
		}
		MoldApi.getAllMold(
			1,
			10,
			moldFilteringText ? `&code=*${moldFilteringText}*` : '',
			'',
			''
		)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				moldsList = [...moldsList, {
				value: item.id,
				label: item.code
				}]
			}))
			answersRequest().then(()=>{
				setMoldList(moldsList)
			})
		})
		.catch(err=>{
			notify("failed to fetch mold filter list")
		})
	},[moldFilteringText])

		
	//project filter
	useEffect(()=>{
		let projectsList = []
		if(projectFilterText === undefined){
			setProjectFilterText('')
		}
		projectApi.getAllProject(1,projectFilterText,10,'')
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				projectsList = [...projectsList, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setProjectList(projectsList)
			})
		})
		.catch(err=>{
			notify("failed to fetch materials")
		})
	},[projectFilterText])

	const columns = [
		{ name: "ID", width: '80px',selector: row=>row.id,
			cell: (row) => row.id.toString(),sortable: true },
		{ name: "Code", width: '150px',selector: row=>row.code,
			cell: (row) => row.code.toString(),sortable: true },
        { name: "Type", selector: row=>row.type, 
            cell: (row) => row.type, sortable: true }, 
		{ name: "Status", selector: row=>row.status, 
			cell: (row) => row.status, sortable: true }, 
		{ name: "Project", width:"150px",selector: row=>row?.project?.name, 
			cell: (row) => row?.project?.name, sortable: true }, 
		...(roles[0] !== "fixing-engineer" ? [
			{ name: "Meter", width: '150px',selector: row=>row.meter, 
				cell: (row) => formatNumber(row?.meter)?.toString(), sortable: true },
		]: []),
		...((roles[0] !== "casting-engineer" && roles[0] !== "fixing-engineer" && roles[0] !== "engineer")? [
			{ name: "Cost", selector: row=>row.cost, 
				cell: (row) => formatNumber(row?.cost)?.toString(), sortable: true },
			{ name: "Store Cost",  width: '170px',selector: row=>row.material_cost, 
				cell: (row) => formatNumber(row?.material_cost)?.toString(), sortable: true },
			// { name: "Other Cost",  width: '170px', selector: row=>row.worker_cost, 
			// 	cell: (row) => row.worker_cost.toString(), sortable: true },
			{
				name: 'Steel Cost',
				button: true,
				width:'130px',
				cell: row => (
					formatNumber(Number(row?.steel?.material_cost) + Number(row?.steel?.cost))
				),
			},
		]: []),
		...(roles[0] !== "fixing-engineer" ? [
		{
			name: 'Steel',
			button: true,
			width:'140px',
			cell: row => (
				<div>
					{(row?.steel?.code) ? row?.steel?.code : 
					(permissions?.create) ?
					<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setSteel({label:row.type,value:row.type})
						setOnUpdateItem(row)}
					}
					href="#modalSteel" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<Button size="lg"/>
					</a>
					: ''
					}
				</div>
			),
		},
		{ name: "Mold",width:'150px', selector: row=>row?.mold?.code, 
			cell: (row) => row?.mold?.code, sortable: true }, 
		{ name: "Shipping Date",width:'130px', selector: row=>row?.shipping_at, 
			cell: (row) => row?.shipping_at, sortable: true }, 
		{ name: "installation Date",width:'140px', selector: row=>row?.tarkebedat, 
			cell: (row) => row?.tarkebedat, sortable: true }, 
		{ name: "Production Date",width:'140px', selector: row=>row?.production_at, 
			cell: (row) => row?.production_at, sortable: true }, 
		]: []),
		...((roles[0] !== "casting-engineer" && roles[0] !== "fixing-engineer" && roles[0] !== "engineer")? [
			{ name: "Finished Date",width:'140px', selector: row=>row?.finished_at, 
				cell: (row) => row?.finished_at, sortable: true }, 
			{ name: "Ordered Date",width:'140px', selector: row=>row?.ordered_at, 
				cell: (row) => row?.ordered_at, sortable: true }, 
			{ name: "Taslihat Start",width:'140px',selector: row=>row?.taslihat_start, 
				cell: (row) => {
					return(row?.taslihat_start) ?
					<FaCheckCircle size={14} color='green'/>
					:
					""
				}
				, sortable: true }, 
			{ name: "Taslihat End",width:'140px', selector: row=>row?.taslihat_end, 
				cell: (row) => {
					return(row?.taslihat_end) ?
					<FaCheckCircle size={14} color='green'/>
					:
					""
				}
				, sortable: true }, 
			{ name: "Siliconed",width:'140px', selector: row=>row?.sliconed, 
				cell: (row) => {
					return(row?.sliconed) ?
					<FaCheckCircle size={14} color='green'/>
					:
					""		
				}
				, sortable: true }, 
			{ name: "Primer Painting",width:'140px', selector: row=>row?.primer_painting, 
				cell: (row) => {
					return(row?.primer_painting) ?
					<FaCheckCircle size={14} color='green'/>
					:
					""		
				}
				, sortable: true }, 
			{ name: "Painting",width:'140px', selector: row=>row?.painting, 
				cell: (row) => {
					return(row?.painting) ?
					<FaCheckCircle size={14} color='green'/>
					:
					""		
				}
				, sortable: true }, 
		]: []),
		...(roles[0] !== "fixing-engineer" ? [
			{ name: "Created By",width:'170px', selector: row=>row?.createdBy?.name, 
				cell: (row) => row?.createdBy?.name, sortable: true }, 
			{ name: "Created At" ,width:'220px', selector: row=>row.created_at,sortable: true,
				cell: (row) => new Date(`${row.created_at}`).toLocaleDateString(
				'en-gb',
				{
				  year: 'numeric',
				  month: 'long',
				  day: 'numeric',
				  timeZone: 'utc',
				  hour: 'numeric',
				  minute: 'numeric',
				  second:'2-digit'
				}) 
				},
		]: []),
		{
			name: 'Actions',
			button: true,
			width:'60px',
			cell: row => 
				{return (permissions?.break) ?
					(
				<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)
						setUpdateData({status:"broken"})
						onOpenAlert()
					}}
					rel="noopener noreferrer">
					{(row?.status ==="ready" || row?.status ==="shipped") ? <MdBrokenImage />:""  }
					{/* {(row?.status ==="broken") ? "" : "Break" } */}
					
				</a>
				)
				:
				""
			},
		},
		// {
		// 	name: '',
		// 	button: true,
		// 	width:'60px',
		// 	cell: row => (
		// 		<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
		// 			onClick={()=>{
		// 				setProject({label:row?.project?.name,value:row?.project?.id})
		// 				setSteel({label:row?.steel?.code,value:row?.steel?.id})
		// 				setStatus({label:row?.status,value:row?.status})
		// 				setType({label:row?.type,value:row?.type})
		// 				setOnUpdateItem(row)}
		// 			}
		// 			href="#modalUpdate" data-bs-toggle="modal"
		// 			target="_blank" rel="noopener noreferrer">
		// 			<VscEdit />
		// 			{/* Edit */}
		// 		</a>
		// 	),
		// },
		// {
		// 	name: '',
		// 	button: true,
		// 	width:'60px',
		// 	cell: row => (
		// 		<a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
		// 			onClick={()=>{
		// 				setOnUpdateItem(row)}
		// 			}
		// 			href="#modalDelete" data-bs-toggle="modal"
		// 			target="_blank" rel="noopener noreferrer">
		// 			<RiDeleteBin6Line />
		// 			{/* Delete */}
		// 		</a>
		// 	),
		// },
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		if (selectedItems.length > 0){
			let promises = selectedItems.map(async (item,index)=>{
				return new Promise((resolve,reject)=>{
				setTimeout(() => 
					dailyWorkingTypeApi.deleteDailyWorking(item.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			// Await all promises to settle
			const results = await Promise.allSettled(promises);
			const hasErrors = results.some(result => result.status === 'rejected');
			if (hasErrors) {
				results.forEach(result => {
				if (result?.status === 'rejected') {
					if (result.reason?.response && result.reason.response?.status === 404) {
						notify("Item not found, please refresh the page and retry");
						}
					else if (result?.reason?.response && result?.reason?.response?.status === 405) {
					notify("You don't have permission to perform this action.");
					} else {
					notify('An issue occurred during deleting the item');
					}
				}
				});
			}
			else{
				setSelectedItems([])
				handleClearRows()
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			}
		}
	} 

	const deleteSingle = async () => {
		dailyWorkingTypeApi.deleteDailyWorking(onUpdateItem.id)
		.then(res=>{
			setFilterText('')
			setSelectedItems([])
			handleClearRows()
			setPageNumber(1)
			setRefreshTable(!refreshTable)
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				notify("You don't have permission to perform this action.")
			}
			else{
				notify('An issue occured while deleting the item')
			}
		})
	} 

	const updateItem = () => {
		if(onUpdateItem.code !== ""){
			dailyWorkingTypeApi.updateDailyWorking(onUpdateItem.id,onUpdateItem?.code,steel?.value)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				setCode("")
				setDescription("")
				setSteel({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
			.catch(err=>{
				console.log(err)
				if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the item please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to update the item")
		}
	}

	const createItem = () => {
		if(code !== "" && project?.value !== "" &&  meter !== "" && type?.value !== "" 
			&& mold?.value !== "" 
		){
			const data = {
				"code":code,
				"steel_id":steel?.value,
				"project_id":project?.value,
				"status":"ready",
				"meter":meter,
				"type":type?.value,
				"mold_id":mold?.value,
				"production_at":getCurrentDate()
			}
			console.log(data,"data")
			dailyWorkingTypeApi.createDailyWorking(data)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				// setFilteredItems(res.data)
				setCode("")
				setDescription("")
				setSteel({label:"Select...",value:""})
				setMold({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			})
			.catch(err=>{
				console.log(err)
				if(err?.response?.data?.message){
					notify(err?.response?.data?.message)
				}
				else if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the item please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to create a item.")
		}
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
		if(paginationPerPage !== newPerPage){
			setPaginationPerPage(newPerPage)
		}
	};

	const onSelectSteel = () => {
		console.log(onUpdateItem.id,onUpdateItem.meter,
			onUpdateItem.project_id,newSteel.value,onUpdateItem.code)
		dailyWorkingTypeApi.updateDailyWorking(onUpdateItem.id,onUpdateItem.meter,
			onUpdateItem.project_id,newSteel.value,onUpdateItem.code
		)
		.then(res=>{
			notify("Steel was added successfully.")
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			notify("Failed to add steel.")
		})
	}

	const shipItem = () => {
		if (selectedItems.length > 0){
			if(driver_name !== "" && truck_plate_number !== "" &&  kren_plate_number !== "" && kren_driver_name !== ""
				&& (truck_cost !== "" && kren_cost >=0) && (kren_cost !== "" && kren_cost >=0)
				&& (other_costs !== "" && other_costs >=0)
			){
				let codes = [] 
				const answersRequest = () => Promise.all(selectedItems?.map(item => {
					codes = [...codes, item?.id]
				}))
				answersRequest().then(()=>{
					const data = {
						// "project_id":selectedItems[0]?.project_id,
						"driver_name":driver_name,
						"truck_plate_number":truck_plate_number,
						"kren_plate_number":kren_plate_number,
						"kren_driver_name":kren_driver_name,
						"truck_cost":truck_cost,
						"kren_cost":kren_cost,
						"other_costs":other_costs,
						"codes" : codes,
						"is_dept":isDept
					}
					ShippingApi.create(data)
					.then(resUser=>{
						setFilterText('')
						setPageNumber(1)
						setDriver_name('')
						setTruck_plate_number('')
						setKren_plate_number('')
						setKren_driver_name('')
						setTruck_cost(0)
						setKren_cost(0)
						setOther_costs(0)

						const updateRequest = () => Promise.all(selectedItems?.map(item => {
							let updateData = {
								status:"shipped",
								shipping_at:getCurrentDate(),
								shipping_id:resUser?.data?.data?.id
							} 
							dailyWorkingTypeApi.updateCasting(item?.id,updateData)
							.then(res=>{
								// notify("This item is now Broken!")
							})
							.catch(err=>{
								// notify("An issue occured with your request")
							})
						}))

						updateRequest().then(()=>{
							setRefreshTable(!refreshTable)
							$("[data-bs-dismiss='modal']").trigger({ type: "click" });
						})
					})
					.catch(err=>{
						console.log(err)
						if(err?.response?.data?.message){
							notify(err?.response?.data?.message)
						}
						else if(err.response.status === 405){
							notify("You don't have permission to perform this action.")
						}
						else{
							notify("Failed to ship the item please refresh the page and retry.")
						}
					})
				})


			}
			else{
				notify("Please fill all required information to ship a item.")
			}
		}
		else{
			notify("Please select an item.")
		}

	}

	const onBreakSteel = () => {
        if(onUpdateItem?.id){
			dailyWorkingTypeApi.updateStatus(onUpdateItem?.id,"broken")
            .then(res=>{
                notify("This item is now Broken!")
				setRefreshTable(!refreshTable)
                setIsAlertOpen(false)
            })
            .catch(err=>{
                notify("An issue occured with your request")
                setIsAlertOpen(false)
            })
        }
        else{
            notify("Unable to break this item, please refresh the page and retry.")
			setRefreshTable(!refreshTable)
            setIsAlertOpen(false)
        }
    }

	const updateCasting = (data) => {
        if(onUpdateItem?.id){
			dailyWorkingTypeApi.updateCasting(onUpdateItem?.id,data)
            .then(res=>{
                notify("Item updated!")
				setRefreshTable(!refreshTable)
                setIsAlertOpen(false)
            })
            .catch(err=>{
                notify("An issue occured with your request")
                setIsAlertOpen(false)
            })
        }
        else{
            notify("Unable to update this item, please refresh the page and retry.")
			setRefreshTable(!refreshTable)
            setIsAlertOpen(false)
        }
    }
	
	const onOpenAlert = () =>{
        setIsAlertOpen(!isAlertOpen)
        setMessage("This action will update the item !, are you sure you want to procced?")
    }

	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
			<ul className="breadcrumb">
				<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
				<li className="breadcrumb-item active">Total Products</li>
			</ul>
			<AlertModal message={message} onOpen={isAlertOpen} onSubmit={()=>updateCasting(updateData)}
				closeAlert={()=>setIsAlertOpen(false)}/>

			<div className="row d-flex align-items-center mb-3">
				<div className='col-12 col-lg-3 col-md-3 col-sm-12 mb-3'>
					<h1 className="page-header mb-0">Total Products</h1>
				</div>
				
				<div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12 d-flex ms-auto"
					style={{gap:10,justifyContent: "flex-end",flexWrap:'wrap'}}>
					{(permissions?.otherCost) && (
						<Link style={{justifyContent: "center",alignItems:'center'}} to={'/total-products/total-product-expense'} 
						className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex btn btn-theme text_icon_center">
							<FaListAlt /> Daily Expenses
						</Link>
					)}

					{(permissions?.materialCost) && (
                    <Link style={{justifyContent: "center",alignItems:'center'}} to={'/total-products/total-product-store-expense'}
						className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex btn btn-theme text_icon_center">
							<FaListAlt /> Store Expenses
					</Link>
					)}
					{/* <a href="#modalAdd" style={{justifyContent: "center",alignItems:'center'}}  data-bs-toggle="modal" 
						className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex btn btn-theme">
						<i className="fa fa-plus-circle fa-fw me-1"></i>
						Add
					</a> */}
				</div>
			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							{(permissions?.ship) && (
								<>
									<button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button>	
									<div className="dropdown-menu">
										<button className="dropdown-item" href="#modalShipping" data-bs-toggle="modal" target="_blank"
											rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Shipping</button>
									</div>
								</>
							)}

							<div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search by Code"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div>
						</div>

						{/* filters */}
						<div className='row mb-4'>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Project</label>
								<Select options={projectList} classNamePrefix="react-select" 
									value={fProject} onChange={(e)=>setFProject(e)} 
									isClearable={true} onInputChange={(e)=>setProjectFilterText(e)}/>
							</div>

							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Type</label>
								<Select options={typeList} classNamePrefix="react-select" 
									value={fType} onChange={(e)=>setFType(e)} 
									isClearable={true}/>
							</div>

							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Status</label>
								<Select options={StatusList} classNamePrefix="react-select" 
									value={fStatus} onChange={(e)=>setFStatus(e)} 
									isClearable={true}/>
							</div>

							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Steel</label>
								<Select options={steelList} classNamePrefix="react-select" 
									value={fSteel} onChange={(e)=>setFSteel(e)} 
									isClearable={true} onInputChange={(e)=>setSteelFilterText(e)}/>
							</div>

							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Mold</label>
								<Select options={moldList} classNamePrefix="react-select" 
									value={fMold} onChange={(e)=>setFMold(e)} 
									isClearable={true} onInputChange={(e)=>setMoldFilteringText(e)}/>
							</div>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Created By</label>
								<Select options={createdByList} classNamePrefix="react-select" 
									value={fCreatedBy} onChange={(e)=>setFCreatedBy(e)} 
									isClearable={true} onInputChange={(e)=>setCreatedByFilterText(e)}/>
							</div>
						</div>
						
						<div>
							<DataTable
								columns={columns}
								data={allItems}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								selectableRowDisabled={row => row.status !== 'ready'} // Disable rows that don't have "ready" status
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

            {/* add material modal */}
            <div className="modal fade" id="modalAdd">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Add New Item</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Project</label>
								<Select options={projectList} classNamePrefix="react-select" 
									value={project} onChange={(e)=>setProject(e)} onInputChange={(e)=>setProjectFilterText(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Code</label>
								<input type="text" className="form-control" placeholder="code" 
									onChange={(e)=>setCode(e.target.value)} value={code}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Meter</label>
								<input type="number" className="form-control" placeholder="meter" 
								onChange={(e)=>setMeter(e.target.value)} value={meter}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Steel</label>
								<Select options={unusedSteelList} classNamePrefix="react-select" 
									value={steel} onChange={(e)=>setSteel(e)} onInputChange={(e)=>setUnusedSteelFilterText(e)}/>
							</div>
							{/* <div className="mb-3">
								<label className="form-label">Status</label>
								<Select options={StatusList} classNamePrefix="react-select" 
									value={status} onChange={(e)=>setStatus(e)}/>
							</div> */}
							<div className="mb-3">
								<label className="form-label">Type</label>
								<Select options={typeList} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Mold</label>
								<Select options={moldList} classNamePrefix="react-select" 
									value={mold} onChange={(e)=>setMold(e)} onInputChange={(e)=>setMoldFilterText(e)}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>createItem()}>Create</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of add modal */}

			{/* update modal */}
			<div className="modal fade" id="modalUpdate">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update Item</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Project</label>
								<Select options={projectList} classNamePrefix="react-select" 
									value={project} onChange={(e)=>setProject(e)} onInputChange={(e)=>setProjectFilterText(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">code</label>
								<input type="text" className="form-control" placeholder="code" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.code = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem?.code}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Type</label>
								<Select options={typeList} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)}/>

							</div>
							<div className="mb-3">
								<label className="form-label">Meter</label>
								<input type="number" className="form-control" placeholder="meter" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.meter = e.target.value
										setOnUpdateItem(data)
										}} 
									value={onUpdateItem?.meter}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Steel</label>
								<Select options={steelList} classNamePrefix="react-select" 
									value={steel} onChange={(e)=>setSteel(e)} onInputChange={(e)=>setSteelFilterText(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Status</label>
								<Select options={StatusList} classNamePrefix="react-select" 
									value={status} onChange={(e)=>setStatus(e)}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>updateItem()}>Update</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}

			{/* steel modal */}
			<div className="modal fade" id="modalSteel">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Steel</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Select steel</label>
								<Select options={steelList} classNamePrefix="react-select" 
									value={newSteel} onChange={(e)=>setNewSteel(e)} onInputChange={(e)=>setSteelFilterText(e)}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>onSelectSteel()}>Add</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of steel modal */}

			{/* add shipping modal */}
			  <div className="modal fade" id="modalShipping">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Shipping</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Driver Name</label>
								<input type="text" className="form-control" placeholder="Driver Name" 
									onChange={(e)=>setDriver_name(e.target.value)} value={driver_name}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Truck Plate Number</label>
								<input type="text" className="form-control" placeholder="Truck Plate Number" 
									onChange={(e)=>setTruck_plate_number(e.target.value)} value={truck_plate_number}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Kren Plate Number</label>
								<input type="text" className="form-control" placeholder="Kren Plate Number" 
									onChange={(e)=>setKren_plate_number(e.target.value)} value={kren_plate_number}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Kren Driver Name</label>
								<input type="text" className="form-control" placeholder="Kren Driver Name" 
									onChange={(e)=>setKren_driver_name(e.target.value)} value={kren_driver_name}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Truck Cost</label>
								<input type="number" className="form-control" placeholder="Truck Cost" 
									onChange={(e)=>setTruck_cost(e.target.value)} value={truck_cost}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Kren Cost</label>
								<input type="number" className="form-control" placeholder="Kren Cost" 
									onChange={(e)=>setKren_cost(e.target.value)} value={kren_cost}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Other Costs</label>
								<input type="number" className="form-control" placeholder="Other Costs" 
									onChange={(e)=>setOther_costs(e.target.value)} value={other_costs}/>
							</div>
							<div className='col-lg-3 col-md-3 col-sm-12 col-xs-12' style={{alignContent:'flex-end'}}>
								<Toggle onChange={(e)=>setIsDept(e)}>Dept</Toggle>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>shipItem()}>Submit</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of shipping modal */}

			{/* delete modal */}
			<div className="modal fade" id="modalDelete">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Delete Item</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							{(onUpdateItem?.code) ?
								(<p>Are you sure you want to delete {onUpdateItem?.code} Item?</p>)
								:
								(selectedItems?.length) ?
									(<p>Are you sure you want to delete {selectedItems?.length} Item?</p>)
									:
									(<p>No steel was selected for deleting</p>)
							}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							{(onUpdateItem?.code) ?
								(							
								<button type="button" className="btn btn-theme" onClick={()=>deleteSingle()}>Delete</button>
								)
								:
								(selectedItems?.length) ?
									(
										<button type="button" className="btn btn-theme" onClick={()=>batchDelete()}>Batch Delete</button>
									)
									:
									("")
							}
						</div>
					</div>
				</div>
			</div>
            {/* end of delete modal */}

		</div>
	)
}

export default CastingDailyWorking;