import * as actionTypes from '../actions/actionTypes';

const initialState = {
  user: {
    id: "",
    name: "",
    email: "",
  },
  token:""
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_USER_INFO:
      console.log(action,"action")
      return {
        ...state,
        user: action?.userData,
      };
    case actionTypes.SET_USER_TOKEN:
      return {
        ...state,
        token: action?.token?.token,
      };
    default:
      return state;
  }
};
