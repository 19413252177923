import React, { useEffect,useRef, useState  } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader } from './../components/card/card.jsx';
import { dailyWorkingTypeApi, fixingApi, materialApi,MoldApi,projectApi,ShippingApi,steelApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { VscEdit } from "react-icons/vsc";
import { FaListAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import validator from 'validator';
import Select from 'react-select';
import { FaMoneyBill } from "react-icons/fa";
import { CiViewList } from "react-icons/ci";
import { Button, ButtonToolbar, ButtonGroup } from 'rsuite';
import { notify, AlertModal, ScreenLoading, boldTableStyle, formatNumber } from '../utils.js';
import { VscCombine } from "react-icons/vsc";

const $ = require('jquery');

let typeList = [
	{label:"GRC",value:"grc"},
	{label:"GRP",value:"grp"}
]

let StatusList = [
	{label:"Used",value:"used"},
	{label:"Ready",value:"ready"},
	{label:"Broken",value:"broken"},
	{label:"Shipped",value:"shipped"}
]

function FixingHistory({roles}) {
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allItems);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});
    const [message,setMessage] = useState("")

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);

	//new states
	const [code,setCode] = useState("")
    const [steel,setSteel] = useState({label:"Select...",value:""})
	const [description,setDescription] = useState("")
	const [meter,setMeter] = useState(0)
	const [status,setStatus] = useState({label:"Select...",value:""})
	const [type,setType] = useState({label:"Select...",value:""})

	const [newSteel,setNewSteel] = useState({label:"Select...",value:""})

	//expense type dropdown filter
	const [materialTypeFilterText, setMaterialTypeFilterText] = useState('');
	const [steelList,setSteelList] = useState([])

	const [moldFilterText, setMoldFilterText] = useState('');
	const [moldList,setMoldList] = useState([])
	const [mold,setMold] = useState({label:"Select...",value:""})

	//projects
	const [projectFilterText, setProjectFilterText] = useState('');
	const [projectList,setProjectList] = useState([])
	const [project,setProject] = useState({label:"Select...",value:""})
	const [isAlertOpen,setIsAlertOpen] = useState(false)

	//shipping states
	const [driver_name, setDriver_name] = useState('');
	const [truck_plate_number, setTruck_plate_number] = useState('');
	const [kren_plate_number, setKren_plate_number] = useState('');
	const [kren_driver_name, setKren_driver_name] = useState('');
	const [truck_cost, setTruck_cost] = useState(0);
	const [kren_cost, setKren_cost] = useState(0);
	const [other_costs, setOther_costs] = useState(0);

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		fixingApi.getAll(
			pageNumber,
			paginationPerPage,
			filterText ? `&code=*${filterText}*` : '',
			type?.value !== '' && type?.value !== undefined ? `&type=${type?.value}` : '',
		)
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllItems(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable,paginationPerPage,
		project?.value,type?.value,status?.value,steel?.value
	])

	//material type filter
	useEffect(()=>{
		// setLoading(true)
		let steelsList = []
		if(materialTypeFilterText === undefined){
			setMaterialTypeFilterText('')
		}
		steelApi.getAllSteel(1,materialTypeFilterText,10,'')
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				steelsList = [...steelsList, {
				value: item.id,
				label: item.code
				}]
			}))
			answersRequest().then(()=>{
				setSteelList(steelsList)
			})
			// setLoading(false)
		})
		.catch(err=>{
			notify("failed to fetch materials")
		})
	},[materialTypeFilterText])

	//mold filter
	useEffect(()=>{
		let moldsList = []
		if(moldFilterText === undefined){
			setMoldFilterText('')
		}
		MoldApi.getAllMold(
			1,
			10,
			moldFilterText ? `&code=*${moldFilterText}*` : '',
			project?.value  !== '' && project?.value !== undefined  ? `&project=${project?.value}` : '',
			''
		)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				moldsList = [...moldsList, {
				value: item.id,
				label: item.code
				}]
			}))
			answersRequest().then(()=>{
				setMoldList(moldsList)
			})
		})
		.catch(err=>{
			notify("failed to fetch mold list")
		})
	},[moldFilterText,project?.value])

	//project filter
	useEffect(()=>{
		let projectsList = []
		if(projectFilterText === undefined){
			setProjectFilterText('')
		}
		projectApi.getAllProject(1,projectFilterText,10,'')
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				projectsList = [...projectsList, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setProjectList(projectsList)
			})
		})
		.catch(err=>{
			notify("failed to fetch materials")
		})
	},[projectFilterText])

	const columns = [
		{ name: "Code", width: '150px',selector: row=>row?.casting?.code,
			cell: (row) => row?.casting?.code,sortable: true },
		{ name: "Type", selector: row=>row?.casting?.type, 
			cell: (row) => row?.casting?.type, sortable: true }, 
		...(roles[0] !== "fixing-engineer" ? [
			{ name: "Meter", width: '150px',selector: row=>row?.casting?.meter, 
				cell: (row) => formatNumber(row?.casting?.meter)?.toString(), sortable: true },
			{ name: "Other Cost",  width: '170px',selector: row=>row.cost, 
				cell: (row) => formatNumber(row?.cost)?.toString(), sortable: true },
			{ name: "Store Cost",  width: '170px',selector: row=>row.material_cost, 
				cell: (row) => formatNumber(row?.material_cost)?.toString(), sortable: true },
		]: []),
		{ name: "Status", selector: row=>row?.casting?.status, 
				cell: (row) => row?.casting?.status, sortable: true }, 
		{ name: "Date", selector: row=>row?.fixing_date, 
			cell: (row) => row?.fixing_date, sortable: true }, 
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	console.log(selectedRows)
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		if (selectedItems.length > 0){
			let promises = selectedItems.map(async (item,index)=>{
				return new Promise((resolve,reject)=>{
				setTimeout(() => 
					dailyWorkingTypeApi.deleteDailyWorking(item.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			// Await all promises to settle
			const results = await Promise.allSettled(promises);
			const hasErrors = results.some(result => result.status === 'rejected');
			if (hasErrors) {
				results.forEach(result => {
				if (result?.status === 'rejected') {
					if (result.reason?.response && result.reason.response?.status === 404) {
						notify("Item not found, please refresh the page and retry");
						}
					else if (result?.reason?.response && result?.reason?.response?.status === 405) {
					notify("You don't have permission to perform this action.");
					} else {
					notify('An issue occurred during deleting the item');
					}
				}
				});
			}
			else{
				setSelectedItems([])
				handleClearRows()
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			}
		}
	} 

	const deleteSingle = async () => {
		dailyWorkingTypeApi.deleteDailyWorking(onUpdateItem.id)
		.then(res=>{
			setFilterText('')
			setSelectedItems([])
			handleClearRows()
			setPageNumber(1)
			setRefreshTable(!refreshTable)
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				notify("You don't have permission to perform this action.")
			}
			else{
				notify('An issue occured while deleting the item')
			}
		})
	} 

	const updateItem = () => {
		if(onUpdateItem.code !== ""){
			dailyWorkingTypeApi.updateDailyWorking(onUpdateItem.id,onUpdateItem?.code,steel?.value)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				setCode("")
				setDescription("")
				setSteel({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
			.catch(err=>{
				console.log(err)
				if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the item please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to update the item")
		}
	}

	const createItem = () => {
		if(code !== "" && project?.value !== "" &&  meter !== "" && type?.value !== "" 
			&& mold?.value !== "" 
		){
			const data = {
				"code":code,
				"steel_id":steel?.value,
				"project_id":project?.value,
				"status":"used",
				"meter":meter,
				"type":type?.value,
				"mold_id":mold?.value,
			}
			console.log(data,"data")
			dailyWorkingTypeApi.createDailyWorking(data)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				// setFilteredItems(res.data)
				setCode("")
				setDescription("")
				setSteel({label:"Select...",value:""})
				setMold({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			})
			.catch(err=>{
				console.log(err)
				if(err?.response?.data?.message){
					notify(err?.response?.data?.message)
				}
				else if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the item please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to create a item.")
		}
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
		if(paginationPerPage !== newPerPage){
			setPaginationPerPage(newPerPage)
		}
	};

	const onSelectSteel = () => {
		console.log(onUpdateItem.id,onUpdateItem.meter,
			onUpdateItem.project_id,newSteel.value,onUpdateItem.code)
		dailyWorkingTypeApi.updateDailyWorking(onUpdateItem.id,onUpdateItem.meter,
			onUpdateItem.project_id,newSteel.value,onUpdateItem.code
		)
		.then(res=>{
			notify("Steel was added successfully.")
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			notify("Failed to add steel.")
		})
	}

	const shipItem = () => {
		if (selectedItems.length > 0){
			if(driver_name !== "" && truck_plate_number !== "" &&  kren_plate_number !== "" && kren_driver_name !== ""
				&& (truck_cost !== "" && kren_cost >=0) && (kren_cost !== "" && kren_cost >=0)
				&& (other_costs !== "" && other_costs >=0)
			){
				let codes = [] 
				const answersRequest = () => Promise.all(selectedItems?.map(item => {
					codes = [...codes, item?.id]
				}))
				answersRequest().then(()=>{
					const data = {
						"project_id":selectedItems[0]?.project_id,
						"driver_name":driver_name,
						"truck_plate_number":truck_plate_number,
						"kren_plate_number":kren_plate_number,
						"kren_driver_name":kren_driver_name,
						"truck_cost":truck_cost,
						"kren_cost":kren_cost,
						"other_costs":other_costs,
						"codes" : codes
					}
					console.log(data,"data")
					ShippingApi.create(data)
					.then(resUser=>{
						setFilterText('')
						setPageNumber(1)
						setRefreshTable(!refreshTable)
						setDriver_name('')
						setTruck_plate_number('')
						setKren_plate_number('')
						setKren_driver_name('')
						setTruck_cost(0)
						setKren_cost(0)
						setOther_costs(0)
						$("[data-bs-dismiss='modal']").trigger({ type: "click" });
					})
					.catch(err=>{
						console.log(err)
						if(err?.response?.data?.message){
							notify(err?.response?.data?.message)
						}
						else if(err.response.status === 405){
							notify("You don't have permission to perform this action.")
						}
						else{
							notify("Failed to ship the item please refresh the page and retry.")
						}
					})
				})


			}
			else{
				notify("Please fill all required information to ship a item.")
			}
		}
		else{
			notify("Please select an item.")
		}

	}

	const onAssemble = () => {
        if(onUpdateItem?.id){
			dailyWorkingTypeApi.updateStatus(onUpdateItem?.id,"used")
            .then(res=>{
                notify("This item is installed!")
				setRefreshTable(!refreshTable)
                setIsAlertOpen(false)
            })
            .catch(err=>{
                notify("An issue occured with your request")
                setIsAlertOpen(false)
            })
        }
        else{
            notify("Unable to install this item, please refresh the page and retry.")
			setRefreshTable(!refreshTable)
            setIsAlertOpen(false)
        }
    }

	const onOpenAlert = () =>{
        setIsAlertOpen(!isAlertOpen)
        setMessage("This action will install the item !, are you sure you want to procced?")
    }

	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
			<ul className="breadcrumb">
				<li className="breadcrumb-item"><a href="/fixing">Fixing</a></li>
				<li className="breadcrumb-item active">Fixing History</li>
			</ul>
			<AlertModal message={message} onOpen={isAlertOpen} onSubmit={()=>onAssemble()}
				closeAlert={()=>setIsAlertOpen(false)}/>

			<div className="row d-flex align-items-center mb-3">
				<div className='col-12 col-lg-3 col-md-3 col-sm-12 mb-3'>
					<h1 className="page-header mb-0">Fixing History</h1>
				</div>
			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							{/* <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button> */}
							<div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search by Code"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div>
						</div>

						{/* filters */}
						<div className='row mb-4'>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Type</label>
								<Select options={typeList} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)} 
									isClearable={true}/>
							</div>
						</div>
						
						<div>
							<DataTable
								columns={columns}
								data={allItems}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								// selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								// onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								// clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					</div>
				</div>
			</Card>
		</div>
	)
}

export default FixingHistory;