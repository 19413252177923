import React, { useEffect,useRef, useState  } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader } from './../components/card/card.jsx';
import { authApi, projectApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { VscEdit } from "react-icons/vsc";
import { FaListAlt, FaHardHat } from "react-icons/fa";
import { RiDeleteBin6Line, RiToolsLine } from "react-icons/ri";
import validator from 'validator';
import { boldTableStyle, formatNumber, notify, ScreenLoading } from '../utils.js';
// import MoonLoader from "react-spinners/MoonLoader";
import Select from 'react-select';

const $ = require('jquery');

let statusList = [
	{label:"True",value:1},
	{label:"False",value:0}
]

function Projects({permissions,roles}) {
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allItems);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);
    const [engineerList,setEngineerList] = useState([])
    const [engineerFilterText, setEngineerFilterText] = useState('');
	const [engineer,setEngineer] = useState({label:"Select...",value:""})
	const [engineerF,setEngineerF] = useState({label:"Select...",value:""})
	const [status,setStatus] = useState({label:"Select...",value:""})

	//new states
	const [name,setName] = useState("")
    const [grp_price_meter,setGrp_price_meter] = useState(0)
	const [grc_price_meter,setGrc_price_meter] = useState(0)
    const [address,setAddress] = useState("")
    const [description,setDescription] = useState("")

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		projectApi.getAllProject(
			pageNumber,
			filterText,
			paginationPerPage,
			engineerF?.value  !== '' && engineerF?.value !== undefined  ? `&engineer=${engineerF?.value}` : '',
		)
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllItems(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable
		,paginationPerPage,engineerF?.value
	])

	//engineer list filter
	useEffect(()=>{
		let engineersList = []
		if(engineerFilterText === undefined){
			setEngineerFilterText('')
		}
		console.log(engineerFilterText,"engineerFilterText")
		authApi.getEngineerList(10,engineerFilterText,1)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				engineersList = [...engineersList, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setEngineerList(engineersList)
			})
		})
		.catch(err=>{
			notify("failed to fetch engineer")
		})
	},[engineerFilterText])

	const columns = [
		{ name: "ID", width: '90px',selector: row=>row.id,
			cell: (row) => row.id.toString(),sortable: true },
		{ name: "Name" , width: '150px', selector: row=>row.name,
			cell: (row) => row.name, sortable: true },
		{ name: "Description", width: '250px' ,selector: row=>row?.description,
			cell: (row) => row?.description,sortable: true },
		{ name: "Address", selector: row=>row.address, width: '200px',
			cell: (row) => row?.address, sortable: true },
		{ name: "Is Finished", selector: row=>row?.is_finished, width: '150px',
			cell: (row) => (row?.is_finished) ? "True" : "False", sortable: true },
		...((roles[0] !== "casting-engineer" && roles[0] !== "fixing-engineer" && roles[0] !== "engineer")? [
			{ name: "GRP price/meter", width: '150px',selector: row=>row.grp_price_meter,
				cell: (row) => formatNumber(row.grp_price_meter).toString(),sortable: true },
			{ name: "GRC price/meter", width: '150px',selector: row=>row.grc_price_meter,
				cell: (row) => formatNumber(row.grc_price_meter).toString(),sortable: true },
		]: []),
		{ name: "Engineer" , width: '150px', selector: row=>row?.engineer?.name,
			cell: (row) => row?.engineer?.name, sortable: true },
		{ name: "Created By", selector: row=>row?.created_by?.name,width: '150px',
			cell: (row) => row?.created_by?.name, sortable: true },
		{
			name: 'Actions',
			button: true,
			width: '60px',
			cell: row => 
				{return (permissions?.edit) ?
					(
						<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
							onClick={()=>{
								setOnUpdateItem(row)
								setStatus(
									(row?.is_finished)
									? {
										value:1,label:"True"
									}
									: {
										value:0,label:"False"
									}
									)
							}
							}
							href="#modalUpdate" data-bs-toggle="modal"
							target="_blank" rel="noopener noreferrer">
							<VscEdit />
						</a>
						)
						:
						""
				},
		},
		{
			name: '',
			button: true,
			width: '60px',
			cell: row => 
				{return (permissions?.assignEngineer) ?
				(
				<a style={{color:"gold", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)
						setEngineer(
							(row?.engineer_id)
							? {
								value:row?.engineer?.id,label:row?.engineer?.name
							  }
							: {label:"Select...",value:""}
							)
					}
					}
					href="#modalAddEngineer" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<FaHardHat />
				</a>)
				:
				""
			},
		},
		{
			name: '',
			button: true,
			width: '60px',
			cell: row => {return (permissions?.delete) ?
				(
				<a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)}
					}
					href="#modalDelete" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<RiDeleteBin6Line />
				</a>)
				:
				""
			},
		},
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		if (selectedItems.length > 0){
			let promises = selectedItems.map(async (item,index)=>{
				return new Promise((resolve,reject)=>{
				setTimeout(() => 
					projectApi.deleteProject(item.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			// Await all promises to settle
			const results = await Promise.allSettled(promises);
			const hasErrors = results.some(result => result.status === 'rejected');
			if (hasErrors) {
				results.forEach(result => {
				if (result?.status === 'rejected') {
					if (result.reason?.response && result.reason.response?.status === 404) {
						notify("Item not found, please refresh the page and retry");
					}
					else if (result?.reason?.response && result?.reason?.response?.status === 405) {
					notify("You don't have permission to perform this action.");
					} else {
					notify('An issue occurred during deleting the project');
					}
				}
				});
			}
			else{
				setSelectedItems([])
				handleClearRows()
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			}
		}
	} 

	const deleteSingle = async () => {
		projectApi.deleteProject(onUpdateItem.id)
		.then(res=>{
			setFilterText('')
			setPageNumber(1)
			setSelectedItems([])
			handleClearRows()
			setRefreshTable(!refreshTable)
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				notify("You don't have permission to perform this action.")
			}
			else{
				notify('An issue occured while deleting the project')
			}
		})
	} 

	const updateItem = () => {
		if(onUpdateItem.name !== "" &&  onUpdateItem.grc_price_meter !== "" &&  onUpdateItem.grp_price_meter !== ""
			&&  onUpdateItem.address !== "" && status?.value !==""
		){
			projectApi.updateProject(onUpdateItem?.id,onUpdateItem?.name,
				onUpdateItem?.address,onUpdateItem?.description,onUpdateItem?.grp_price_meter,
				onUpdateItem?.grc_price_meter,status?.value)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
			.catch(err=>{
				console.log(err)
				if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the project please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to update the project")
		}
	}

	const createItem = () => {
		if(name !== "" && grc_price_meter !== "" && grp_price_meter !== "" && address !==""){
			let data = {
				name:name,
				grc_price_meter:grc_price_meter,
				grp_price_meter:grp_price_meter,
				address:address,
				description:description
			}
			projectApi.createProject(data)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				// setFilteredItems(res.data)
				setName("")
				setAddress("")
				setDescription("")
				setGrc_price_meter("")
				setGrp_price_meter("")
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			})
			.catch(err=>{
				console.log(err)
				if(err?.response?.data?.message){
					notify(err?.response?.data?.message)
				}
				else if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the project please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to create a project.")
		}
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
	if(paginationPerPage !== newPerPage){
		setPaginationPerPage(newPerPage)
	}
	};

	const updateEngineer = () => {
		if(engineer?.value !== ""){
			projectApi.updateEngineer(onUpdateItem?.id,(engineer?.value) ? engineer?.value : "")
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
			.catch(err=>{
				console.log(err)
				if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to updat engineer, please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please select an engineer.")
		}
	}

	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
			<div className="d-flex align-items-center mb-3">
				<div>
					<ul className="breadcrumb">
						<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
						<li className="breadcrumb-item active">PROJECTS</li>
					</ul>
					<h1 className="page-header mb-0">PROJECTS</h1>
				</div>
				
				{(permissions?.create) && (
					<div className="ms-auto" style={{display:'flex',gap:10}}>
						<a href="#modalAddProject" data-bs-toggle="modal" className="btn btn-theme"><i className="fa fa-plus-circle fa-fw me-1"></i> New Project</a>
					</div>
				)}
			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							{/* <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button>
							<div className="dropdown-menu">
								<button className="dropdown-item" href="#modalDelete" data-bs-toggle="modal" target="_blank"
									rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Delete selected</button>
							</div> */}
							<div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search By Name"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div>
						</div>

						{/* filters */}
						<div className='row mb-4'>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Engineer</label>
								<Select options={engineerList} classNamePrefix="react-select" 
									value={engineerF} onChange={(e)=>setEngineerF(e)} 
									isClearable={true} onInputChange={(e)=>setEngineerFilterText(e)}/>
							</div>
						</div>
						
						<div>
							<DataTable
								columns={columns}
								data={allItems}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

            {/* add project modal */}
            <div className="modal fade" id="modalAddProject">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Add New Project</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Name*</label>
								<input type="text" className="form-control" placeholder="Name" 
									onChange={(e)=>setName(e.target.value)} value={name}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Address*</label>
								<input type="text" className="form-control" placeholder="Address" 
									onChange={(e)=>setAddress(e.target.value)} value={address}/>
							</div>
							<div className="mb-3">
								<label className="form-label">GRP price/meter*</label>
								<input type="number" className="form-control" placeholder="GRP price/meter"
									onChange={(e)=>setGrp_price_meter(e.target.value)} value={grp_price_meter}/>
							</div>
							<div className="mb-3">
								<label className="form-label">GRC price/meter*</label>
								<input type="number" className="form-control" placeholder="GRC price/meter"
									onChange={(e)=>setGrc_price_meter(e.target.value)} value={grc_price_meter}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Description</label>
								<textarea className="form-control" rows="2" maxLength={200}
									onChange={(e)=>setDescription(e.target.value)} value={description}></textarea>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>createItem()}>Create</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of add modal */}

			{/* update modal */}
			<div className="modal fade" id="modalUpdate">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update Project</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Name</label>
								<input type="text" className="form-control" placeholder="Name" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.name = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem.name}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Address</label>
								<input type="text" className="form-control" placeholder="Address" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.address = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem.address}/>
							</div>
							<div className="mb-3">
								<label className="form-label">GRP price/meter</label>
								<input type="number" className="form-control" placeholder="GRP price/meter"
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.grp_price_meter = e.target.value
										setOnUpdateItem(data)
										}}
									value={(onUpdateItem?.grp_price_meter) ? onUpdateItem?.grp_price_meter : ""}/>
							</div>
							<div className="mb-3">
								<label className="form-label">GRC price/meter</label>
								<input type="number" className="form-control" placeholder="GRC price/meter"
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.grc_price_meter = e.target.value
										setOnUpdateItem(data)
										}}
										value={(onUpdateItem?.grc_price_meter) ? onUpdateItem?.grc_price_meter : ""}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Status</label>
								<Select options={statusList} classNamePrefix="react-select" 
									value={status} onChange={(e)=>setStatus(e)} 
									isClearable={true}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Description</label>
								<textarea className="form-control" rows="2" maxLength={200}
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.description = e.target.value
										setOnUpdateItem(data)
									}} value={onUpdateItem?.description}></textarea>
							</div>
							{/* <div className="mb-3">
								<label className="form-label">Password*</label>
								<input type="text" className="form-control" placeholder="Password" 
									onChange={(e)=>setPassword(e.target.value)} value={onUpdateItem.password}/>
							</div> */}
							{/* <div className="mb-3">
								<label className="form-label">Status*</label>
								<select className="form-select" onChange={(e)=>{
										let data = {...onUpdateItem}
										data.status = e.target.value
										setOnUpdateItem(data)
										}}
										value={onUpdateItem.status}>
									<option value="">Select One</option>
									<option value="active">Active</option>
									<option value="suspended">Suspended</option>
								</select>
							</div> */}
							{/* <div className="mb-3">
								<label className="form-label">Tags</label>
								<TagsInput value={tags} name="tags" placeHolder="enter tags" />
							</div> */}
							{/* <div className="form-group">
								<label>Description</label>
								<textarea className="form-control" rows="10"></textarea>
							</div> */}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>updateItem()}>Update</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}

			{/* modalAddEngineer modal */}
			<div className="modal fade" id="modalAddEngineer">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Assign Engineer</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Engineer</label>
								<Select options={engineerList} classNamePrefix="react-select" 
									value={engineer} onChange={(e)=>setEngineer(e)} 
									isClearable={true} onInputChange={(e)=>setEngineerFilterText(e)}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>updateEngineer()}>Update</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}

			{/* delete modal */}
			<div className="modal fade" id="modalDelete">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Delete Project</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							{(onUpdateItem?.id) ?
								(<p>Are you sure you want to delete {onUpdateItem?.name} project?</p>)
								:
								(selectedItems?.length) ?
									(<p>Are you sure you want to delete {selectedItems?.length} projects?</p>)
									:
									(<p>No project was selected for deleting</p>)
							}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							{(onUpdateItem?.id) ?
								(							
								<button type="button" className="btn btn-theme" onClick={()=>deleteSingle()}>Delete</button>
								)
								:
								(selectedItems?.length) ?
									(
										<button type="button" className="btn btn-theme" onClick={()=>batchDelete()}>Batch Delete</button>
									)
									:
									("")
							}
						</div>
					</div>
				</div>
			</div>
            {/* end of delete modal */}

		</div>
	)
}

export default Projects;