import React, { useEffect,useRef, useState  } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardHeader } from './../components/card/card.jsx';
import { materialApi,steelApi, steelMaterialApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { VscEdit } from "react-icons/vsc";
import { FaListAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import validator from 'validator';
import Select from 'react-select';
import { FaMoneyBill } from "react-icons/fa";
import { CiViewList } from "react-icons/ci";
import { boldTableStyle, formatNumber, notify, ScreenLoading } from '../utils.js';

const $ = require('jquery');

let typeList = [
	{label:"GRC",value:"grc"},
	{label:"GRP",value:"grp"}
]

function Steel({permissions,roles}) {
	const navigate = useNavigate();
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allItems);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);

	//new states
	const [code,setCode] = useState("")
    const [type,setType] = useState({label:"Select...",value:""})
	const [fType,setFType] = useState({label:"Select...",value:""})
	const [description,setDescription] = useState("")
	//expense type dropdown filter
	const [materialTypeFilterText, setMaterialTypeFilterText] = useState('');
	const [materialTypeList,setMaterialTypeList] = useState([])
	const [materialsFilterText, setMaterialFilterText] = useState('');
	const [materialList,setMaterialList] = useState([])

	//add used material states
	const [materialType,setMaterialType] = useState({label:"Select...",value:""})
    const [quantity,setQuantity] = useState("")
    const [usedMaterialList,setUsedMaterialList] = useState([])

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		steelApi.getAllSteel(
			pageNumber,
			filterText,
			paginationPerPage,
			fType?.value  !== '' && fType?.value !== undefined  ? `&type=${fType?.value}` : '',
		)
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllItems(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable,
		paginationPerPage,fType?.value
	])

	//material type filter
	useEffect(()=>{
		// setLoading(true)
		let materialTypeList = []
		if(materialTypeFilterText === undefined){
			setMaterialTypeFilterText('')
		}
		materialApi.getAllMaterial(1,materialTypeFilterText,10)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				materialTypeList = [...materialTypeList, {
				value: item.type,
				label: item.type
				}]
			}))
			answersRequest().then(()=>{
				setMaterialTypeList(materialTypeList)
			})
			// setLoading(false)
		})
		.catch(err=>{
			notify("failed to fetch materials")
		})
	},[materialTypeFilterText])

	//material type filter
	useEffect(()=>{
		// setLoading(true)
		let materialsList = []
		if(materialsFilterText === undefined){
			setMaterialTypeFilterText('')
		}
		materialApi.getAllMaterial(1,materialsFilterText,10)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				materialsList = [...materialsList, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setMaterialList(materialsList)
			})
			// setLoading(false)
		})
		.catch(err=>{
			notify("failed to fetch materials")
		})
	},[materialsFilterText])

	const columns = [
		{ name: "ID", width: '80px',selector: row=>row.id,
			cell: (row) => row.id.toString(),sortable: true },
		{ name: "Code", width: '130px',selector: row=>row.code,
			cell: (row) => row.code,sortable: true },
		{ name: "Type", width: '100px',selector: row=>row.type, sortable: true },
		...(roles[0] !== "casting-engineer" ? [
			{ name: "Cost", width: '150px',selector: row=>row.cost,
				cell: (row) => formatNumber(row?.cost)?.toString(),sortable: true },
			{ name: "Material Cost", width: '150px',selector: row=>row.material_cost,
				cell: (row) => formatNumber(row?.material_cost)?.toString(),sortable: true },
		]: []),
		{ name: "Production Date", width: '150px',selector: row=>row?.production_date,
			cell: (row) => row?.production_date,sortable: true },
        { name: "Created At", width: '250px',selector: row=>row.created_at,sortable: true,
            cell: (row) => new Date(`${row.created_at}`).toLocaleDateString(
                'en-gb',
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  timeZone: 'utc',
                  hour: 'numeric',
                  minute: 'numeric',
                  second:'2-digit'
                }) 
        },
		{
			name: 'Actions',
			button: true,
			width:'60px',
			cell: row => 
				{return (permissions?.edit) ?
					(
				<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setType({label:row.type,value:row.type})
						setOnUpdateItem(row)}
					}
					href="#modalUpdate" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<VscEdit />
					{/* Edit */}
				</a>
				)
				:
				""
			},
		},
		{
			name: '',
			button: true,
			width:'60px',
			cell: row => 
				{return (permissions?.delete) ?
					(
				<a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)}
					}
					href="#modalDelete" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<RiDeleteBin6Line />
					{/* Delete */}
				</a>
				)
				:
				""
			},
		},
        {
			name: '',
			button: true,
			width:'60px',
			cell: row => 
				{return (permissions?.materialCost) ?
					(
				<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						navigate('/steel/steel-material',{state:{id:row?.id}})
					}} 
					rel="noopener noreferrer">
					<CiViewList />
					{/* Materials */}
				</a>
				)
				:
				""
			},
		},
        {
			name: '',
			button: true,
			width:'60px',
			cell: row => 
				{return (permissions?.otherCost) ?
					(
				<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						navigate('/steel/steel-other-costs',{state:{id:row?.id}})
					}}
					 rel="noopener noreferrer">
					<FaMoneyBill />
					{/* Other costs */}
				</a>
				)
				:
				""
			},
		},
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
		setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		if (selectedItems.length > 0){
			let promises = selectedItems.map(async (item,index)=>{
				return new Promise((resolve,reject)=>{
				setTimeout(() => 
					steelApi.deleteSteel(item.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			// Await all promises to settle
			const results = await Promise.allSettled(promises);
			const hasErrors = results.some(result => result.status === 'rejected');
			if (hasErrors) {
				results.forEach(result => {
				if (result?.status === 'rejected') {
					if (result.reason?.response && result.reason.response?.status === 404) {
						notify("Item not found, please refresh the page and retry");
						}
					else if (result?.reason?.response && result?.reason?.response?.status === 405) {
					notify("You don't have permission to perform this action.");
					} else {
					notify('An issue occurred during deleting the item');
					}
				}
				});
			}
			else{
				setSelectedItems([])
				handleClearRows()
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			}
		}
	} 

	const deleteSingle = async () => {
		steelApi.deleteSteel(onUpdateItem.id)
		.then(res=>{
			setFilterText('')
			setSelectedItems([])
			handleClearRows()
			setPageNumber(1)
			setRefreshTable(!refreshTable)
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				notify("You don't have permission to perform this action.")
			}
			else{
				notify('An issue occured while deleting the item')
			}
		})
	} 

	const updateItem = () => {
		if(onUpdateItem.code !== "" &&  type?.value !== ""){
			steelApi.updateSteel(onUpdateItem.id,onUpdateItem?.code,type?.value)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				setCode("")
				setDescription("")
				setType({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
			.catch(err=>{
				console.log(err)
				if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the item please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to update the item")
		}
	}

	const addUsedMaterials = () => {
		if(materialType.value !== "" &&  quantity !== "" && quantity > 0){
			let data = {
				steel_id:onUpdateItem.id,
				material_id:materialType.value,
				quantity:quantity
			}
			steelMaterialApi.createSteelMaterial(data)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				setCode("")
				setDescription("")
				setType({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
			.catch(err=>{
				console.log(err)
				if(err?.response?.data?.message){
					notify(err?.response?.data?.message)
				}
				else if(err?.response?.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to add used material, please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information.")
		}
	}

	const createItem = () => {
		if(code !== "" && type?.value !== ""){
			const data = {
				"code":code,
				"type":type?.value,
			}
			steelApi.createSteel(data)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				// setFilteredItems(res.data)
				setCode("")
				setDescription("")
				setType({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			})
			.catch(err=>{
				console.log(err)
				if(err?.response?.data?.message){
					notify(err?.response?.data?.message)
				}
				else if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the item please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to create a item.")
		}
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
		if(paginationPerPage !== newPerPage){
			setPaginationPerPage(newPerPage)
		}
	};


	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
			<div className="d-flex align-items-center mb-3">
				<div>
					<ul className="breadcrumb">
						<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
						<li className="breadcrumb-item active">STEEL</li>
					</ul>
					<h1 className="page-header mb-0">STEEL</h1>
				</div>
				
				{(permissions?.create) && (
					<div className="ms-auto" style={{display:'flex',gap:10}}>
						<a href="#modalAdd" data-bs-toggle="modal" className="btn btn-theme"><i className="fa fa-plus-circle fa-fw me-1"></i>Add</a>
					</div>
				)}

			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							{/* <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button>
							<div className="dropdown-menu">
								<button className="dropdown-item" href="#modalDelete" data-bs-toggle="modal" target="_blank"
									rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Delete selected</button>
							</div> */}
							<div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search by Code"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div>
						</div>

						{/* filters */}
						<div className='row mb-4'>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Type</label>
								<Select options={typeList} classNamePrefix="react-select" 
									value={fType} onChange={(e)=>setFType(e)} 
									isClearable={true}/>
							</div>
						</div>
						
						<div>
							<DataTable
								columns={columns}
								data={allItems}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

            {/* add material modal */}
            <div className="modal fade" id="modalAdd">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Add New Steel</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Code*</label>
								<input type="text" className="form-control" placeholder="code" 
									onChange={(e)=>setCode(e.target.value)} value={code}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Type</label>
								<Select options={typeList} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>createItem()}>Create</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of add modal */}

			{/* update modal */}
			<div className="modal fade" id="modalUpdate">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update Steel</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">code</label>
								<input type="text" className="form-control" placeholder="code" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.code = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem?.code}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Type</label>
								<Select options={typeList} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>updateItem()}>Update</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}

			{/* used materials modal */}
			<div className="modal fade" id="modalUsedMaterials">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Materials</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Material</label>
								<Select options={materialList} classNamePrefix="react-select" 
									value={materialType} onChange={(e)=>setMaterialType(e)} onInputChange={(e)=>setMaterialFilterText(e)}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Quantity</label>
								<input type="text" className="form-control" placeholder="quantity" 
									onChange={(e)=>{setQuantity(e.target.value)}} 
										value={quantity}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>addUsedMaterials()}>Add Material</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of used materials modal */}

			{/* delete modal */}
			<div className="modal fade" id="modalDelete">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Delete Steel</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							{(onUpdateItem?.code) ?
								(<p>Are you sure you want to delete {onUpdateItem?.code} steel?</p>)
								:
								(selectedItems?.length) ?
									(<p>Are you sure you want to delete {selectedItems?.length} steel?</p>)
									:
									(<p>No steel was selected for deleting</p>)
							}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							{(onUpdateItem?.code) ?
								(							
								<button type="button" className="btn btn-theme" onClick={()=>deleteSingle()}>Delete</button>
								)
								:
								(selectedItems?.length) ?
									(
										<button type="button" className="btn btn-theme" onClick={()=>batchDelete()}>Batch Delete</button>
									)
									:
									("")
							}
						</div>
					</div>
				</div>
			</div>
            {/* end of delete modal */}

		</div>
	)
}

export default Steel;