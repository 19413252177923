import http from "../http-common";

export const getAll = (page,per_page)=> {
    return http.get(`/shipping?sort=-id&pagination=true&perPage=${per_page}&page=${page}`);
}
// &include=codes.dailyWorkingType
export const create = (data)=> {
    return http.post(`/shipping`,data);
}

export const deleteShipping = (data)=> {
    return http.delete(`/shipping/${data}`);
}

export const getShippedCodes = (page,per_page,shipping_id)=> {
    return http.get(`/casting/search/shipped?sort=-id&pagination=true&perPage=${per_page}&include=project&page=${page}&shipping=${shipping_id}`);
}

// export const updateShipping = (id,name,type,description)=> {
//     return http.put(`/shipping/${id}?name=${name}&type=${type}&description=${description}`);
// }
