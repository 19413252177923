import http from "../http-common";

export const getAllMaterial = (page,name,per_page)=> {
    return http.get(`/material?sort=-id&include=createdBy&pagination=true&perPage=${per_page}&page=${page}&name=*${name}*`);
}

export const getStocktaking = ()=> {
    return http.get(`/stocktaking`);
}

export const createMaterial = (data)=> {
    return http.post(`/material`,data);
}

export const deleteMaterial = (data)=> {
    return http.delete(`/material/${data}`);
}

export const updateMaterial = (id,name,type,description,unit,perUnit)=> {
    return http.put(`/material/${id}?name=${name}&type=${type}&description=${description}&unit=${unit}&per_unit=${perUnit}`);
}

export const getAllInventory = (page,name,per_page,type)=> {
    return http.get(`/material?sort=-id&pagination=true&perPage=${per_page}&page=${page}&name=*${name}*${type}`);
}