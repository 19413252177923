import React, { useEffect,useRef, useState  } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader } from './../components/card/card.jsx';
import { orderApi, materialApi } from '../api/index.js';
// import DataTable from "react-data-table-component";
// import { useDispatch, useSelector } from 'react-redux';
// import { VscEdit } from "react-icons/vsc";
// import { FaListAlt } from "react-icons/fa";
// import { RiDeleteBin6Line } from "react-icons/ri";
// import validator from 'validator';
import { useForm, useFieldArray,Controller } from "react-hook-form";
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { MdClose } from "react-icons/md";
import { notify } from '../utils.js';
import { useDispatch, useSelector } from 'react-redux';

const $ = require('jquery');

function NewOrder() {
    const navigate = useNavigate();
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [userId,SetUserId]=useState(useSelector((state)=>state.auth.user.id))

	//new states
	const [material,setMaterial] = useState("")
    const [quantity,setQuantity] = useState("")
	const [price,setPrice] = useState("")

	//expense type dropdown filter
	const [materialFilterText, setMaterialFilterText] = useState('');
	const [materialList,setMaterialList] = useState([])

    const { register, control, handleSubmit, reset, trigger, setError } = useForm({
        // you can populate the fields by this attribute 
        defaultValues: {
            orders: [{ material: "", quantity: ""}]
          }
      });
      const { fields, append, remove } = useFieldArray({
        control,
        name: "orders"
      });

      const generateInvoice=()=>{
        //return 13 unique degits
        return `INV-${String(Math.trunc(Date.now() + Math.random()))}`
    }

    //material filter
	useEffect(()=>{
		// setLoading(true)
		let materialsList = []
		if(materialFilterText === undefined){
			setMaterialFilterText('')
		}
		materialApi.getAllMaterial(1,materialFilterText,10)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				materialsList = [...materialsList, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setMaterialList(materialsList)
			})
			// setLoading(false)
		})
		.catch(err=>{
			notify("failed to fetch materials")
		})
	},[materialFilterText])

    const submitOrder = ({orders}) => {
        let items = []
        const answersRequest = () => Promise.all(orders?.map(item => {
            items = [...items, {
            material_id: item.material.value,
            quantity: Number(item.quantity),
            price:0
            }]
        }))
        answersRequest().then(()=>{
            let orders= {
                // "invoice_number":generateInvoice(),
                // "purchased_by":userId,
                // "purchased_at":null,
                "accepted":false,
                "items": items
            }
            console.log(orders)
            orderApi.createOrders(orders)
            .then(res=>{
                notify("Your order has been submited.")
                navigate('../order')
            })
            .catch(err=>{
                notify("An issue occured with your request")
                console.log(err,"err")
            })
        })

    }

	return (
		<div className='new_order'>
			<div className="d-flex align-items-center mb-3">
				<div>
					<ul className="breadcrumb">
						<li className="breadcrumb-item"><Link to="/order">ORDERS</Link></li>
						<li className="breadcrumb-item active">NEW ORDERS</li>
					</ul>
					<h1 className="page-header mb-0">NEW ORDERS</h1>
				</div>
			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div>    
                            <form onSubmit={handleSubmit(data => submitOrder(data))}>
                                {/* <ul> */}
                                    {fields.map((item, index) => (
                                    <div key={item.id} className='order_container row'>
                                        <Controller
                                        render={({ field }) => (
                                            <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                                                <label className="form-label">Material:</label>
                                                <Select options={materialList} classNamePrefix="react-select" 
                                                    onInputChange={(e)=>setMaterialFilterText(e)} {...field}/>
                                            </div>
                                        )}
                                        name={`orders.${index}.material`}
                                        control={control}
                                        rules={{ required: true }}
                                        />
                                        <Controller
                                        render={({ field }) => (
                                            <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                                                <label className="form-label">Quantity:</label>
                                                <input className="form-control"  type="number"{...field} />
                                            </div>
                                        )}
                                        name={`orders.${index}.quantity`}
                                        control={control}
                                        rules={{ required: true }}
                                        />
                                        {/* <Controller
                                        render={({ field }) => (
                                            <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12'>
                                                <label className="form-label">Price:</label>
                                                <input className="form-control"  type="number" {...field} />
                                            </div>
                                        )}
                                        name={`orders.${index}.price`}
                                        control={control}
                                        rules={{ required: true }}
                                        /> */}
                                        {(fields.length > 1) && (
                                            <div className='col-lg-1 col-md-1 col-sm-12 col-xs-12' >
                                            <button type="button" style={{backgroundColor:"red",borderColor:'red',height: '100%'}}
                                            className='btn btn-theme w-100' onClick={() => remove(index)}>
                                                <MdClose color='white' size={18}/>
                                            </button>
                                            </div>
                                        )}

                                    </div>
                                    ))}
                                {/* </ul> */}
                                <div className='form_buttons row'>
                                    <div className='col-lg-2 col-md-3 col-sm-12 col-xs-12 d-flex'>
                                        <button
                                            type="button"
                                            className='btn btn-theme w-100'
                                            onClick={() => append({ material: "", quantity: ""})}
                                        >
                                        Add Another Material
                                        </button>
                                    </div>
                                    <div className='col-lg-2 col-md-3 col-sm-12 col-xs-12 d-flex'>
                                        <input className='btn btn-theme w-100' type="submit" />
                                    </div>
                                </div>
                            </form>
						</div>
					</div>
				</div>
			</Card>
		</div>
	)
}

export default NewOrder;