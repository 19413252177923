import axios from "axios";
// import { useDispatch, useSelector } from 'react-redux';
import { store } from '../store';
import { useNavigate } from 'react-router-dom';
import { notify } from "../utils";
  // let token = useSelector((state)=>state.auth.token)

const api = axios.create({
  // baseURL: "https://pos.sure2shops.com/api",
  // baseURL: "http://109.199.98.2:3001/api",
  baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
  headers: {
    "Content-type": "application/json",
    // "Authorization": `Bearer ${token}`
    },
});

// export const setupInterceptors = (token) => {
//   console.log(token,"token in axios")
  api.interceptors.request.use(
  (config) => {
    // const navigate = useNavigate();
    const state = store.getState();
    const token = state.auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } 
    // else {
    //   // navigate('/login');
    // }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
)

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
      // If the response is successful, just return the response
      return response;
  },
  (error) => {
      // If the response has an error, check if it's 401
      if (error.response && error.response.status === 401 && window.location.pathname !== '/login') {
          // Redirect to login page
          // Using window.location for simplicity, but you could also use react-router
          window.location.href = '/login';
      }
      return Promise.reject(error);
  }
);

export default api