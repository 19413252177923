import http from "../http-common";

export const getAllExpenseType = (page,name,per_page)=> {
    return http.get(
        `/expensesType?sort=-id&pagination=true&perPage=${per_page}&page=${page}&name=*${name}*`
    );
}

export const createExpenseType = (data)=> {
    return http.post(`/expensesType`,data);
}

export const deleteExpenseType = (data)=> {
    return http.delete(`/expensesType/${data}`);
}

export const updateExpenseType = (id,name,description)=> {
    return http.put(
        `/expensesType/${id}?name=${name}&description=${description}`
    );
}
