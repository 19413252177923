import React, { useEffect,useRef, useState  } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader } from './../components/card/card.jsx';
import { workerApi, workerPaidAmountApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { VscEdit } from "react-icons/vsc";
import { FaListAlt,FaMoneyBillWave  } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import validator from 'validator';
import { boldTableStyle, formatNumber, getCurrentDate, notify, ScreenLoading } from '../utils.js';
import Select from 'react-select';
import { Button, ButtonToolbar, ButtonGroup, Modal, Panel, Divider  } from 'rsuite';
import ReactToPrint from 'react-to-print';
import { PiInvoiceBold } from "react-icons/pi";
import { FaPrint } from "react-icons/fa6";
import logo from '../assets/nubin_logo_png.png'

const $ = require('jquery');

function AdvancePayment({permissions}) {
	const componentRef = useRef();
    const [tableData,setTableData] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(tableData);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});
	const [workerFilterText, setWorkerFilterText] = useState('');
	const [workersList,setWorkersList] = useState([])
    const [fWorker,setFWorker] = useState({label:"Select...",value:""})
	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);
	// Modal visibility state
	const [open, setOpen] = useState(false);
	// Open and close modal functions
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	//new states
	const [name,setName] = useState("")
    const [email,setEmail] = useState("")
	const [phone,setPhone] = useState("")
    const [password,setPassword] = useState("")
    const [confirmPass,setConfirmPass] = useState("")

	//loan 
	const [amount,setAmount] = useState(0)
	const [note,setNote] = useState("")

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		workerPaidAmountApi.getPaidAmount(
			pageNumber,
			paginationPerPage,
			fWorker?.value  !== '' && fWorker?.value !== undefined  ? `&worker=${fWorker?.value}` : '',
		)
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setTableData(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable,paginationPerPage,
		fWorker?.value
	])

	//worker filter,
	useEffect(()=>{
		// setLoading(true)
		let workerList = []
		if(workerFilterText === undefined){
			setWorkerFilterText('')
		}
		workerApi.getAllWorkers(1,workerFilterText,10)
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				workerList = [...workerList, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setWorkersList(workerList)
			})
		})
		.catch(err=>{
			notify("failed to fetch workers")
		})
	},[workerFilterText])

	const columns = [
		{ name: "ID", maxWidth: '50px',selector: row=>row.id,
			cell: (row) => row.id.toString(),sortable: true },
		{ name: "Worker", selector: row=>row?.worker?.name,
			cell: (row) => row?.worker?.name,sortable: true },
		{ name: "Amount", selector: row=>row.amount.toString(),
			cell: (row) => formatNumber(row?.amount)?.toString(),sortable: true },
		{ name: "Date", selector: row=>row.paid_date,
			cell: (row) => row.paid_date,sortable: true },
        { name: "Note", selector: row=>row.note, width:'350px',
            cell: (row) => row.note,sortable: true },
		{
			name: 'Actions',
			button: true,
			width: '60px',
			cell: row => 
				{return (permissions?.print) ?
					(
				<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)
						handleOpen()
					}
					}
					// href="#modalInvoice" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<FaPrint />
				</a>
				)
				:
				""
			},
		},
		{
			name: '',
			button: true,
			width:'60px',
			cell: row => 
				{return (permissions?.edit) ?
					(
				<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setErrorMessage("")
						setOnUpdateItem(row)}
					}
					href="#modalUpdate" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<VscEdit />
				</a>
				)
				:
				""
			},
		},
		{
			name: '',
			button: true,
			width:'60px',
			cell: row => 
				{return (permissions?.delete) ?
					(
				<a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setErrorMessage("")
						setOnUpdateItem(row)}
					}
					href="#modalDelete" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<RiDeleteBin6Line />
				</a>
				)
				:
				""
			},
		},
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
	setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		setErrorMessage('')
		if (selectedItems.length > 0){
			let promises = selectedItems.map(async (item,index)=>{
				return new Promise((resolve,reject)=>{
				setTimeout(() => 
					workerPaidAmountApi.deletePaidAmount(item.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			// Await all promises to settle
			const results = await Promise.allSettled(promises);
			const hasErrors = results.some(result => result.status === 'rejected');
			if (hasErrors) {
				results.forEach(result => {
				if (result?.status === 'rejected') {
					if (result.reason?.response && result.reason.response?.status === 404) {
						setErrorMessage("Item not found, please refresh the page and retry");
					  }
					else if (result?.reason?.response && result?.reason?.response?.status === 405) {
					setErrorMessage("You don't have permission to perform this action.");
					} else {
					setErrorMessage('An issue occurred during deleting the item');
					}
				}
				});
			}
			else{
				setSelectedItems([])
				handleClearRows()
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			}
		}
	} 

	const deleteSingle = async () => {
		setErrorMessage('')
		workerPaidAmountApi.deletePaidAmount(onUpdateItem.id)
		.then(res=>{
			setSelectedItems([])
			handleClearRows()
			setFilterText('')
			setPageNumber(1)
			setRefreshTable(!refreshTable)
            notify("Item deleted!")
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				notify("You don't have permission to perform this action.")
			}
			else{
				notify('An issue occured while deleting the item')
			}
		})
	} 

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
		if(paginationPerPage !== newPerPage){
			setPaginationPerPage(newPerPage)
		}
	};

	const onAdvancePayment = () => {
		if(onUpdateItem?.amount > 0){
			let data = {
				paid_date:getCurrentDate(),
				amount:Number(onUpdateItem?.amount),
				note:onUpdateItem?.note,
				worker_id:onUpdateItem?.id
			}
			workerPaidAmountApi.createPaidAmount(data)
			.then(res=>{
                setRefreshTable(!refreshTable)
				notify("advance payment updated!")
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			})
			.catch(err=>{
				if(err?.response?.data?.message){
					notify(err?.response?.data?.message)
				}
				else{
					notify("An issue occurred with your request")
				}
			})
		}
		else{
			notify("Please add amount.")
		}
	}


	return (
		<div className='general_style'>
			{(loading) && (
				<ScreenLoading loading={loading}/>
			)}
			<ul className="breadcrumb">
				<li className="breadcrumb-item"><a href="/workers">WORKERS</a></li>
				<li className="breadcrumb-item active">ADVANCE PAYMENT</li>
			</ul>

			<div className="row d-flex align-items-center mb-3">
				<div className='col-12 col-lg-3 col-md-3 col-sm-12 mb-3'>
					<h1 className="page-header mb-0">ADVANCE PAYMENT</h1>
				</div>
				
				{/* <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12 d-flex ms-auto"
					style={{gap:10,justifyContent: "flex-end",flexWrap:'wrap'}}> */}
					{/* <Link style={{justifyContent: "center",alignItems:'center'}} to={'/advance-payment'} 
						className="col-12 col-xl-3 col-lg-4 col-md-4 col-sm-12 d-flex btn btn-theme text_icon_center">
						<FaListAlt /> Advance Payment
					</Link> */}
                    {/* <div style={{justifyContent: "center",alignItems:'center'}}
						className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex btn btn-theme text_icon_center"> */}
							{/* <a href="#modalAdd" data-bs-toggle="modal"
							className="btn btn-theme col-12 col-xl-3 col-lg-4 col-md-4 col-sm-12 ">
								<i className="fa fa-plus-circle fa-fw me-1"></i> Add Worker</a> */}
					{/* </div> */}
				{/* </div> */}
			</div>
			
			{/* <div className="d-flex align-items-center mb-3">
				<div>
					<ul className="breadcrumb">
						<li className="breadcrumb-item"><a href="#/">PAGES</a></li>
						<li className="breadcrumb-item active">WORKERS</li>
					</ul>
					<h1 className="page-header mb-0">WORKERS</h1>
				</div>
				
				<div className="ms-auto">
					<a href="#modalAdd" data-bs-toggle="modal" className="btn btn-theme"><i className="fa fa-plus-circle fa-fw me-1"></i> Add Worker</a>
				</div>
			</div> */}
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							{/* <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button> */}
							{/* <div className="dropdown-menu">
								<button className="dropdown-item" href="#modalDelete" data-bs-toggle="modal" target="_blank"
									rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Delete selected</button>
							</div> */}
							{/* <div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search Workers"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div> */}
						</div>

							{/* filters */}
							<div className='row mb-4'>
							<div className='col-12 col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-2'>
								<label className="form-label">Worker</label>
								<Select options={workersList} classNamePrefix="react-select" 
									value={fWorker} onChange={(e)=>setFWorker(e)} 
									isClearable={true}/>
							</div>
						</div>
						
						<div>
							<DataTable
								columns={columns}
								data={tableData}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

            
			{/* update modal */}
			<div className="modal fade" id="modalUpdate">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update Payment</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Amount</label>
								<input type="number" className="form-control" placeholder="amount" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.amount = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem?.amount}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Note</label>
                                <textarea className="form-control" rows="3" maxLength={200}
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.note = e.target.value
										setOnUpdateItem(data)
										}}
                                        value={(onUpdateItem?.note) ? onUpdateItem?.note : ""}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>onAdvancePayment()}>Update</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}

            			{/* delete modal */}
			<div className="modal fade" id="modalDelete">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Delete Item</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							{(onUpdateItem?.id) ?
								(<p>Are you sure you want to delete item ID:{onUpdateItem?.id}?</p>)
								:
								(selectedItems?.length) ?
									(<p>Are you sure you want to delete {selectedItems?.length} items?</p>)
									:
									(<p>No item was selected for deleting</p>)
							}
						</div>
						{(errorMessage) && (
							<div className='text-center'>
								<p style={{color:'red'}} >{errorMessage}</p>
							</div>
						)}
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							{(onUpdateItem?.id) ?
								(							
								<button type="button" className="btn btn-theme" onClick={()=>deleteSingle()}>Delete</button>
								)
								:
								(selectedItems?.length) ?
									(
										<button type="button" className="btn btn-theme" onClick={()=>batchDelete()}>Batch Delete</button>
									)
									:
									("")
							}
						</div>
					</div>
				</div>
			</div>
            {/* end of delete modal */}

				{/* Modal for Invoice */}
				<Modal open={open} onClose={handleClose} size="lg" dir="rtl">
				{/* <Modal.Header>
					<Modal.Title>فاکتور #{onUpdateItem?.id}</Modal.Title>
				</Modal.Header> */}
				<Modal.Body>
					{/* Printable Invoice Content */}
					<div ref={componentRef} style={{ padding: '20px', fontFamily: 'k24_bold ,Arial, sans-serif', direction: 'rtl', textAlign: 'right' }}>
						<Panel bordered>
							{/* Company Header */}
							<div style={styles.header}>
								<div>
									<h2 style={{ margin: 0,fontFamily:'k24_bold' }}>کارگەی نووبین</h2>
									<p style={{ margin: 0 }}>شەقامی سەرەکی، هەولێر، دهۆک</p>
									<p style={{ margin: 0 }}>ئیمەیڵ: info@nubingrc.com</p>
									<p style={{ margin: 0 }}> مۆبایل: ٠٧٥٠٥٤٩٨٥٨٠</p>
								</div>

								{/* Logo section */}
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
									<img src={logo} alt="Logo" style={{ width: '150px', height: 'auto', margin: '0 auto' }} />
								</div>


								<div style={{ textAlign: 'left' }}>
									{/* <h3  style={{fontFamily:'k24_bold' }}>فاکتور</h3> */}
									<h2 style={{ margin: 0 }}>Nubin GRC & GRP</h2>
									<p style={{ margin: 0 }}>ڕێکەوت: {new Date().toLocaleDateString()}</p>
									<p style={{ margin: 0 }}>ژمارەی وەصڵ:{onUpdateItem?.id}</p>
								</div>
							</div>

							<Divider />

							{/* Client Information */}
							<div style={styles.section}>
								<h4 style={{fontFamily:'k24_bold' }}>پارەدان بۆ:</h4>
								<p style={{fontSize:18}}><strong>ناو:</strong> {onUpdateItem?.worker?.name}</p>
							</div>

							<Divider />

							{/* Payment Details */}
							<div style={styles.section}>
								<h4 style={{fontFamily:'k24_bold' }}>وردەکاری پارەدان</h4>
								<div style={styles.table}>
									<div style={styles.tableRow}>
										<div style={styles.tableCell}><strong>ڕێکەوتی پارەدان:</strong></div>
										<div style={styles.tableCell}>{onUpdateItem?.paid_date}</div>
									</div>
									<div style={styles.tableRow}>
										<div style={styles.tableCell}><strong>بڕ:</strong></div>
										<div style={styles.tableCell}>${formatNumber(onUpdateItem?.amount)}</div>
									</div>
									<div style={styles.tableRow}>
										<div style={styles.tableCell}><strong>تێبینی:</strong></div>
										<div style={styles.tableCell}>{onUpdateItem?.note}</div>
									</div>
								</div>
							</div>
							<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' ,
								paddingLeft:100,paddingRight:100,paddingBottom:50}}>
								<h4 style={{fontFamily:'k24_bold' }}><strong>واژووی پێدەر</strong></h4>
								<h4 style={{fontFamily:'k24_bold' }}><strong>واژووی وەرگر</strong></h4>
							</div>
						</Panel>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<ReactToPrint
						trigger={() => <Button appearance="primary">چاپکردن</Button>}
						content={() => componentRef.current}
					/>
					<Button onClick={handleClose} appearance="subtle">
						داخستن
					</Button>
				</Modal.Footer>
			</Modal>

		</div>
	)
}

// Custom styles for the invoice
const styles = {
	header: {
	  display: 'flex',
	  justifyContent: 'space-between',
	  marginBottom: '20px',
	},
	section: {
	  marginBottom: '20px',
	},
	table: {
	  display: 'table',
	  width: '100%',
	},
	tableRow: {
	  display: 'table-row',
	},
	tableCell: {
	  display: 'table-cell',
	  padding: '5px 10px',
	  borderBottom: '1px solid #ddd',
	},
	footer: {
	  textAlign: 'center',
	  marginTop: '20px',
	},
	signatureRow:{
		display: 'flex',
		flex:1,
		justifyContent:'justify-between'
	}
  };
  

export default AdvancePayment;