import http from "../http-common";

export const getAll = (page,per_page,worker,project_id,expenses_type_id)=> {
    return http.get(`/finshing?sort=-updated_at&pagination=true&perPage=${per_page}&page=${page}&include=expenseType,worker,project${worker}${project_id}${expenses_type_id}`);
}

export const create = (data)=> {
    return http.post(`/finshing`,data);
}

export const deleteFinishing = (data)=> {
    return http.delete(`/finshing/${data}`);
}

export const updateFinishing = (id,worker_id,project_id,expenses_type_id,amount,note)=> {
    return http.put(`/finshing/${id}?worker_id=${worker_id}&project_id=${project_id}&expenses_type_id=${expenses_type_id}&amount=${amount}&note=${note}`);
}
