import React, { useContext, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { useDispatch, useSelector } from 'react-redux';

function Header() {
	const context = useContext(AppSettings);
	const [redirect, setRedirect] = useState(false);
	const navigate = useNavigate();
	const [user,setUser]=useState(useSelector((state)=>state.auth.user))

	const notificationData = [{
		icon: 'fa fa-receipt fa-lg fa-fw text-success',
		title: 'Your store has a new order for 2 items totaling $1,299.00',
		time: 'just now'
	},{
		icon: 'far fa-user-circle fa-lg fa-fw text-body text-opacity-25',
		title: '3 new customer account is created',
		time: '2 minutes ago'
	},{
		img: '/assets/img/icon/android.svg',
		title: 'Your android application has been approved',
		time: '5 minutes ago'
	},{
		img: '/assets/img/icon/paypal.svg',
		title: 'Paypal payment method has been enabled for your store',
		time: '10 minutes ago'
	}];
	
	const toggleAppSidebarDesktop = () => {
		context.handleToggleAppSidebarMinified();
	}
	
	const toggleAppSidebarMobile = () => {
		context.handleToggleAppSidebarMobile();
	}

	const onLogout = () => {
		localStorage.removeItem("user")
		navigate("/login", { replace: true });
	}


	return (
		<div id="header" className="app-header">
			<div className="mobile-toggler">
				<button type="button" className="menu-toggler" onClick={toggleAppSidebarMobile}>
					<span className="bar"></span>
					<span className="bar"></span>
				</button>
			</div>
			
			<div className="brand">
				<div className="desktop-toggler">
					<button type="button" className="menu-toggler" onClick={toggleAppSidebarDesktop}>
						<span className="bar"></span>
						<span className="bar"></span>
					</button>
				</div>
				
				{/* <Link to="/" className="brand-logo">
					<img src="/assets/img/logo.png" className="invert-dark" alt="" height="20" />
				</Link> */}
			</div>
			
			<div className="menu">
				<form className="menu-search" method="POST" name="header_search_form">
					{/* <div className="menu-search-icon"><i className="fa fa-search"></i></div>
					<div className="menu-search-input">
						<input type="text" className="form-control" placeholder="Search menu..." />
					</div> */}
				</form>
				{/* <div className="menu-item dropdown">
					<a href="#/" data-bs-toggle="dropdown" data-display="static" className="menu-link">
						<div className="menu-icon"><i className="fa fa-bell nav-icon"></i></div>
						<div className="menu-label">{notificationData.length}</div>
					</a>
					<div className="dropdown-menu dropdown-menu-end dropdown-notification">
						<h6 className="dropdown-header text-body-emphasis mb-1">Notifications</h6>
						{notificationData.length > 0 ? (notificationData.map((notification, index) => ((
							<a href="#/" key={index} className="dropdown-notification-item">
								<div className="dropdown-notification-icon">
									{notification.icon ? (
										<i className={notification.icon}></i>
									) : (
										<img src={notification.img} alt="" width="26" />
									)}
								</div>
								<div className="dropdown-notification-info">
									<div className="title">{notification.title}</div>
									<div className="time">{notification.time}</div>
								</div>
								<div className="dropdown-notification-arrow">
									<i className="fa fa-chevron-right"></i>
								</div>
							</a>
						)))) : (
							<div className="dropdown-notification-item">
								No record found
							</div>
						)}
						<div className="p-2 text-center mb-n1">
							<a href="#/" className="text-body-emphasis text-opacity-50 text-decoration-none">See all</a>
						</div>
					</div>
				</div> */}
				<div className="menu-item dropdown">
					<a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
						<div className="menu-img online">
							<img src="/assets/img/user/default_profile_image.webp" alt="" className="ms-100 mh-100 rounded-circle" />
						</div>
						<div className="menu-text">{user?.name}</div>
					</a>
					<div className="dropdown-menu dropdown-menu-end me-lg-3">
						{/* <Link to="/profile" className="dropdown-item d-flex align-items-center" href="profile.html">Edit Profile <i className="fa fa-user-circle fa-fw ms-auto text-body text-opacity-50"></i></Link>
						<Link to="/email/inbox" className="dropdown-item d-flex align-items-center" href="email_inbox.html">Inbox <i className="fa fa-envelope fa-fw ms-auto text-body text-opacity-50"></i></Link> */}
						{/* <Link to="/calendar" className="dropdown-item d-flex align-items-center" href="calendar.html">Calendar <i className="fa fa-calendar-alt fa-fw ms-auto text-body text-opacity-50"></i></Link> */}
						{/* <Link to="/settings" className="dropdown-item d-flex align-items-center" href="settings.html">Setting <i className="fa fa-wrench fa-fw ms-auto text-body text-opacity-50"></i></Link>
						<div className="dropdown-divider"></div> */}
						<button onClick={()=>onLogout()} className="dropdown-item d-flex align-items-center">Log Out <i className="fa fa-toggle-off fa-fw ms-auto text-body text-opacity-50"></i></button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Header;
