import React, { useEffect,useRef, useState  } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Card, CardHeader } from './../components/card/card.jsx';
import { materialApi, moldCostApi,steelMaterialApi, workerApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { VscEdit } from "react-icons/vsc";
import { FaListAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import validator from 'validator';
import Select from 'react-select';
import { FaMoneyBill } from "react-icons/fa";
import { CiViewList } from "react-icons/ci";
import { boldTableStyle, formatNumber, getCurrentDate, notify } from '../utils.js';

const $ = require('jquery');

const costTypeList = [
    {label:"Salary",value:"salary"},
    {label:"Other",value:"other"},
]

function MoldCost({permissions}) {
	const navigate = useNavigate();
    const location = useLocation();
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allItems);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);

	//new states
	const [code,setCode] = useState("")
    const [type,setType] = useState({label:"Select...",value:""})
	const [description,setDescription] = useState("")
	const [worker,setWorker] = useState({label:"Select...",value:""})
	const [amount,setAmount] = useState(0)

	//expense type dropdown filter
	const [workerFilterText, setWorkerFilterText] = useState('');
	const [workersList,setWorkersList] = useState([])
	const [materialsFilterText, setMaterialFilterText] = useState('');
	const [materialList,setMaterialList] = useState([])

	//add used material states
	const [materialType,setMaterialType] = useState({label:"Select...",value:""})
    const [quantity,setQuantity] = useState("")

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
        if(location?.state?.id){
            moldCostApi.getSingleMoldCosts(pageNumber,paginationPerPage,location?.state?.id)
            .then(res=>{
                setPaginationMeta(res?.data?.meta?.pagination)
                setAllItems(res?.data?.data)
                // setFilteredItems(res?.data?.data)
                setLoading(false)
            })
            .catch(err=>{
                setLoading(false)
                navigate('/mold')
            })
        }
        else{
            navigate('/mold')
        }
    },[filterText,resetPaginationToggle,pageNumber,refreshTable,paginationPerPage])

	//material type filter
	useEffect(()=>{
		// setLoading(true)
		let workerList = []
		if(workerFilterText === undefined){
			setWorkerFilterText('')
		}
		workerApi.getWorkers(1,workerFilterText,10,"mold,all")
		.then(res=>{
			const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
				workerList = [...workerList, {
				value: item.id,
				label: item.name
				}]
			}))
			answersRequest().then(()=>{
				setWorkersList(workerList)
			})
		})
		.catch(err=>{
			notify("failed to fetch materials")
		})
	},[workerFilterText])

		//material type filter
		useEffect(()=>{
			// setLoading(true)
			let materialsList = []
			if(materialsFilterText === undefined){
				setWorkerFilterText('')
			}
			materialApi.getAllMaterial(1,materialsFilterText,10)
			.then(res=>{
				const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
					materialsList = [...materialsList, {
					value: item.id,
					label: item.name
					}]
				}))
				answersRequest().then(()=>{
					setMaterialList(materialsList)
				})
				// setLoading(false)
			})
			.catch(err=>{
				notify("failed to fetch materials")
			})
		},[materialsFilterText])

	const columns = [
		{ name: "Steel", maxWidth: '50px',selector: row=>row.mold.code, sortable: true },
		{ name: "Worker", selector: row=>row?.worker?.name, sortable: true },
		{ name: "Cost", maxWidth: '50px',selector: row=>row.cost,
			cell: (row) => formatNumber(row?.cost)?.toString(),sortable: true },
		{ name: "Date", maxWidth: '150px',selector: row=>row.expense_date,
			cell: (row) => row.expense_date,sortable: true },
		{ name: "note", width: '170px',selector: row=>row.note,sortable: true },
		{
			name: '',
			button: true,
			cell: row => 
				{return (permissions?.delete) ?
					(
				<a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)}
					}
					href="#modalDelete" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<RiDeleteBin6Line />
				</a>
				)
				:
				""
			},
		},
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
		setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		if (selectedItems.length > 0){
			let promises = selectedItems.map(async (item,index)=>{
				return new Promise((resolve,reject)=>{
				setTimeout(() => 
					moldCostApi.deleteMoldCosts(item.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			// Await all promises to settle
			const results = await Promise.allSettled(promises);
			const hasErrors = results.some(result => result.status === 'rejected');
			if (hasErrors) {
				results.forEach(result => {
				if (result?.status === 'rejected') {
					if (result.reason?.response && result.reason.response?.status === 404) {
						notify("Item not found, please refresh the page and retry");
						}
					else if (result?.reason?.response && result?.reason?.response?.status === 405) {
					notify("You don't have permission to perform this action.");
					} else {
					notify('An issue occurred during deleting the item');
					}
				}
				});
			}
			else{
				setSelectedItems([])
				handleClearRows()
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			}
		}
	} 

	const deleteSingle = async () => {
		moldCostApi.deleteMoldCosts(onUpdateItem.id)
		.then(res=>{
			setFilterText('')
			setSelectedItems([])
			handleClearRows()
			setPageNumber(1)
			setRefreshTable(!refreshTable)
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				notify("You don't have permission to perform this action.")
			}
			else{
				notify('An issue occured while deleting the item')
			}
		})
	} 

	const updateItem = () => {
		if(onUpdateItem.code !== "" &&  type?.value !== ""){
			moldCostApi.updateMoldCosts(onUpdateItem.id,onUpdateItem?.code,type?.value)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				setCode("")
				setDescription("")
				setType({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
			.catch(err=>{
				console.log(err)
				if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the item please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to update the item")
		}
	}

	const createItem = () => {
		if((type?.value !== "" || (type?.value === "salary" && worker?.value !== "")) && amount !== ""
            && amount >= 0){
			const data = {
				"mold_id":location?.state?.id,
				"worker_id":worker?.value,
                "cost":amount,
                "note":description,
				"expense_date":getCurrentDate()
			}
			moldCostApi.createMoldCosts(data)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				// setFilteredItems(res.data)
				setAmount("")
				setDescription("")
				setType({label:"Select...",value:""})
                setWorker({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			})
			.catch(err=>{
				console.log(err)
				if(err?.response?.data?.message){
					notify(err?.response?.data?.message)
				}
				else if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the item please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to create a item.")
		}
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
		if(paginationPerPage !== newPerPage){
			setPaginationPerPage(newPerPage)
		}
	};

	return (
		<div className='general_style'>
			<div className="d-flex align-items-center mb-3">
				<div>
					<ul className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/mold">MOLD</Link></li>
                        <li className="breadcrumb-item active">MOLD OTHER COSTS</li>
					</ul>
					<h1 className="page-header mb-0">MOLD OTHER COSTS</h1>
				</div>

				{(permissions?.create) && (
				<div className="ms-auto" style={{display:'flex',gap:10}}>
					<a href="#modalAdd" data-bs-toggle="modal" className="btn btn-theme"><i className="fa fa-plus-circle fa-fw me-1"></i>Add</a>
				</div>
				)}

			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							{/* <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button> */}
							{/* <div className="dropdown-menu">
								<button className="dropdown-item" href="#modalDelete" data-bs-toggle="modal" target="_blank"
									rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Delete selected</button>
							</div> */}
							{/* <div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search Material"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div> */}
						</div>
						
						<div>
							<DataTable
								columns={columns}
								data={allItems}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

            {/* add material modal */}
            <div className="modal fade" id="modalAdd">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Add New Cost</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Cost Type</label>
								    <Select options={costTypeList} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)}/>
							</div>
                            {(type?.value === "salary") && (
                                <div className="mb-3">
                                    <label className="form-label">Worker</label>
                                    <Select options={workersList} classNamePrefix="react-select" 
                                        value={worker} onChange={(e)=>setWorker(e)} onInputChange={(e)=>setWorkerFilterText(e)}/>
                                </div>
                            )}
                            <div className="mb-3">
								<label className="form-label">Amount</label>
								<input type="number" className="form-control" placeholder="amount" 
	                                onChange={(e)=>setAmount(e.target.value)} value={amount}/>
							</div>
                            <div className="mb-3">
                            <label className="form-label">Description</label>
								<textarea className="form-control" rows="2" maxLength={200}
									onChange={(e)=>{setDescription(e.target.value)}} value={description}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>createItem()}>Create</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of add modal */}

			{/* update modal */}
			<div className="modal fade" id="modalUpdate">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update cost</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">code</label>
								<input type="text" className="form-control" placeholder="code" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.code = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem?.code}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Type</label>
								<Select options={workersList} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)} onInputChange={(e)=>setWorkerFilterText(e)}/>

							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>updateItem()}>Update</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}

			{/* delete modal */}
			<div className="modal fade" id="modalDelete">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Delete Item</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							{(onUpdateItem?.id) ?
								(<p>Are you sure you want to delete cost ID:{onUpdateItem?.id} ?</p>)
								:
								(selectedItems?.length) ?
									(<p>Are you sure you want to delete {selectedItems?.length} costs?</p>)
									:
									(<p>No mold was selected for deleting</p>)
							}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							{(onUpdateItem?.id) ?
								(							
								<button type="button" className="btn btn-theme" onClick={()=>deleteSingle()}>Delete</button>
								)
								:
								(selectedItems?.length) ?
									(
										<button type="button" className="btn btn-theme" onClick={()=>batchDelete()}>Batch Delete</button>
									)
									:
									("")
							}
						</div>
					</div>
				</div>
			</div>
            {/* end of delete modal */}

		</div>
	)
}

export default MoldCost;