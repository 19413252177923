import http from "../http-common";

export const getAllTransactions = (page,per_page,project,type,date)=> {
    return http.get(
        `/transaction?sort=-id&pagination=true&perPage=${per_page}&page=${page}&include=project,worker${project}${type}${date}`
    );
}

export const getAmounts = ()=> {
    return http.get(
        `/transaction/amounts`
    );
}

export const createTransaction = (data)=> {
    return http.post(`/transaction`,data);
}

export const deleteTransaction = (data)=> {
    return http.delete(`/transaction/${data}`);
}

export const updateTransaction = (id,data)=> {
    return http.put(`/transaction/${id}`,data);
}
