import { getCurrentDate } from "../../utils";
import http from "../http-common";

export const getAll = (page,per_page,status)=> {
    return http.get(`/dailyExpense?sort=-updated_at&pagination=true&perPage=${per_page}&page=${page}&include=worker&purpose=${status}`);
}

export const getAllCasting = (page,per_page)=> {
    return http.get(`/dailyExpense?sort=-updated_at&pagination=true&perPage=${per_page}&page=${page}&include=worker&purpose=ready&date=${getCurrentDate()}`);
}

export const create = (data)=> {
    return http.post(`/dailyExpense`,data);
}

export const deleteExpense = (data)=> {
    return http.delete(`/dailyExpense/${data}`);
}

// export const update = (id,code,type)=> {
//     return http.put(`/dailyExpense/${id}?code=${code}&type=${type}`);
// }
