import React, { useEffect,useRef, useState  } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Card, CardHeader } from './../components/card/card.jsx';
import { materialApi, MaterialExpense, steelCostsApi, steelMaterialApi, workerApi } from '../api/index.js';
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from 'react-redux';
import { VscEdit } from "react-icons/vsc";
import { FaListAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import validator from 'validator';
import Select from 'react-select';
import { FaMoneyBill } from "react-icons/fa";
import { CiViewList } from "react-icons/ci";
import { boldTableStyle, formatNumber, getCurrentDate, notify, typeList } from '../utils.js';

const $ = require('jquery');

const costTypeList = [
    {label:"Salary",value:"salary"},
    {label:"Other",value:"other"},
]

const statusSteelList = [
	{label:"Ready",value:"ready"},
    {label:"Fixing",value:"fixing"},
]

function AssemblyStoreExpense({permissions}) {
	const navigate = useNavigate();
    const location = useLocation();
    const [allItems,setAllItems] = useState([])
	const [loading,setLoading] = useState(true)
	const [selectedItems, setSelectedItems] = useState([]);
	const [filterText, setFilterText] = useState('');
	const [filteredItems, setFilteredItems] = useState(allItems);
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
	const [toggledClearRows, setToggleClearRows] = useState(false);
	const [onUpdateItem, setOnUpdateItem] = useState({});

	//table state
	const [paginationMeta, setPaginationMeta] = useState({});
	const [paginationPerPage, setPaginationPerPage] = useState(15);
	const [pageNumber, setPageNumber] = useState(1);
	const [refreshTable, setRefreshTable] = useState(false);

	//new states
	const [code,setCode] = useState("")
    const [type,setType] = useState({label:"Select...",value:""})
	const [description,setDescription] = useState("")
	const [worker,setWorker] = useState({label:"Select...",value:""})
	const [amount,setAmount] = useState(0)
	const [status,setStatus] = useState({label:"Select...",value:""})

	//expense type dropdown filter
	const [workerFilterText, setWorkerFilterText] = useState('');
	const [workersList,setWorkersList] = useState([])
	const [materialsFilterText, setMaterialFilterText] = useState('');
	const [materialList,setMaterialList] = useState([])

	//add used material states
	const [materialType,setMaterialType] = useState({label:"Select...",value:""})
    const [quantity,setQuantity] = useState("")

	useEffect(()=>{
		setLoading(true)
		if(filterText === undefined){
			setFilterText('')
		}
		MaterialExpense.getAll(pageNumber,paginationPerPage,'used')
		.then(res=>{
			setPaginationMeta(res?.data?.meta?.pagination)
			setAllItems(res?.data?.data)
			// setFilteredItems(res?.data?.data)
			setLoading(false)
		})
		.catch(err=>{
			setLoading(false)
		})
    },[filterText,resetPaginationToggle,pageNumber,refreshTable,paginationPerPage])

		//material type filter
		useEffect(()=>{
			// setLoading(true)
			let materialsList = []
			if(materialsFilterText === undefined){
				setWorkerFilterText('')
			}
			materialApi.getAllMaterial(1,materialsFilterText,10)
			.then(res=>{
				const answersRequest = () => Promise.all(res?.data?.data?.map(item => {
					materialsList = [...materialsList, {
					value: item.id,
					label: item.name
					}]
				}))
				answersRequest().then(()=>{
					setMaterialList(materialsList)
				})
				// setLoading(false)
			})
			.catch(err=>{
				notify("failed to fetch materials")
			})
		},[materialsFilterText])

	const columns = [
		{ name: "Material",selector: row=>row?.material?.name, sortable: true },
        { name: "Quantity",selector: row=>row.quantity,
			cell: (row) => formatNumber(row?.quantity)?.toString(),sortable: true },
		{ name: "Type", selector: row=>row.type,
			cell: (row) => row?.type,sortable: true },
		{ name: "note", width: '300px',selector: row=>row.note,
			cell: (row) => row?.note, sortable: true },
		{ name: "Expense Date", selector: row=>row.expense_date,
			cell: (row) => row?.expense_date,sortable: true },
		// {
		// 	name: '',
		// 	button: true,
		// 	cell: row => (
		// 		<a style={{color:"black", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
		// 			onClick={()=>{
		// 				setWorker({label:row?.worker?.name,value:row.worker_id})
		// 				setOnUpdateItem(row)}
		// 			}
		// 			href="#modalUpdate" data-bs-toggle="modal"
		// 			target="_blank" rel="noopener noreferrer">
		// 			<VscEdit />
		// 			Edit
		// 		</a>
		// 	),
		// },
		{
			name: '',
			button: true,
			cell: row => 
				{return (permissions?.delete) ?
					(
				<a style={{color:"red", textDecoration: "none",cursor:"pointer",display:'flex',gap:5,alignItems:'center'}}
					onClick={()=>{
						setOnUpdateItem(row)}
					}
					href="#modalDelete" data-bs-toggle="modal"
					target="_blank" rel="noopener noreferrer">
					<RiDeleteBin6Line />
				</a>
				)
				:
				""
			},
		},
	  ];

	const handleChange = ({ selectedRows }) => {
	// You can set state or dispatch with something like Redux so we can use the retrieved data
	setSelectedItems(selectedRows)
	// console.log('Selected Rows: ', selectedRows);
	};

	// Toggle the state so React Data Table changes to clearSelectedRows are triggered
	const handleClearRows = () => {
		setToggleClearRows(!toggledClearRows);
	}

	const batchDelete = async () => {
		if (selectedItems.length > 0){
			let promises = selectedItems.map(async (item,index)=>{
				return new Promise((resolve,reject)=>{
				setTimeout(() => 
					MaterialExpense.deleteExpense(item.id)
					.then(res=>{
						resolve("done")
					})
					.catch(err=>{
						reject(err)
					})
				,300 * index);
			})})

			// Await all promises to settle
			const results = await Promise.allSettled(promises);
			const hasErrors = results.some(result => result.status === 'rejected');
			if (hasErrors) {
				results.forEach(result => {
				if (result?.status === 'rejected') {
					if (result.reason?.response && result.reason.response?.status === 404) {
						notify("Item not found, please refresh the page and retry");
						}
					else if (result?.reason?.response && result?.reason?.response?.status === 405) {
					notify("You don't have permission to perform this action.");
					} else {
					notify('An issue occurred during deleting the item');
					}
				}
				});
			}
			else{
				setSelectedItems([])
				handleClearRows()
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			}
		}
	} 

	const deleteSingle = async () => {
		MaterialExpense.deleteExpense(onUpdateItem.id)
		.then(res=>{
			setFilterText('')
			setSelectedItems([])
			handleClearRows()
			setPageNumber(1)
			setRefreshTable(!refreshTable)
			$("[data-bs-dismiss='modal']").trigger({ type: "click" });
		})
		.catch(err=>{
			if(err.response.status === 405){
				notify("You don't have permission to perform this action.")
			}
			else{
				notify('An issue occured while deleting the item')
			}
		})
	} 

	const updateItem = () => {
		if(onUpdateItem.code !== "" &&  type?.value !== ""){
			MaterialExpense.create(onUpdateItem.id,onUpdateItem?.code,type?.value)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				setCode("")
				setDescription("")
				setType({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
				})
			.catch(err=>{
				console.log(err)
				if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the item please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to update the item")
		}
	}

	const createItem = () => {
		if(type?.value !== "" && quantity !== "" && quantity >= 0 && materialType?.value !== ""){
			const data = {
				"material_id":materialType?.value,
                "quantity":quantity,
                // "amount":0,
                "note":description,
				"purpose":"used",
				"type":type?.value,
				"expense_date":getCurrentDate()
			}
			console.log(data,"data")
			MaterialExpense.create(data)
			.then(resUser=>{
				setFilterText('')
				setPageNumber(1)
				setRefreshTable(!refreshTable)
				// setFilteredItems(res.data)
				setQuantity("")
				setDescription("")
				setWorker("")
				setStatus({label:"Select...",value:""})
                setType({label:"Select...",value:""})
				setMaterialType({label:"Select...",value:""})
				$("[data-bs-dismiss='modal']").trigger({ type: "click" });
			})
			.catch(err=>{
				console.log(err)
				if(err?.response?.data?.message){
					notify(err?.response?.data?.message)
				}
				else if(err.response.status === 405){
					notify("You don't have permission to perform this action.")
				}
				else{
					notify("Failed to create the item please refresh the page and retry.")
				}
			})
		}
		else{
			notify("Please fill all required information to create a item.")
		}
	}

	const handlePageChange = page => {
		if(paginationMeta?.total_pages >= page){
			setPageNumber(page)
		}
	  };

	const handlePerRowsChange = async (newPerPage, page) => {
		if(paginationPerPage !== newPerPage){
			setPaginationPerPage(newPerPage)
		}
	};

	return (
		<div className='general_style'>
			<div className="d-flex align-items-center mb-3">
				<div>
					<ul className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/installation">INSTALLATION</Link></li>
                        <li className="breadcrumb-item active">INSTALLATION STORE EXPENSE</li>
					</ul>
					<h1 className="page-header mb-0">INSTALLATION STORE EXPENSE</h1>
				</div>
				
				{(permissions?.create) && (
					<div className="ms-auto" style={{display:'flex',gap:10}}>
						<a href="#modalAdd" data-bs-toggle="modal" className="btn btn-theme"><i className="fa fa-plus-circle fa-fw me-1"></i>Add</a>
					</div>
				)}
			</div>
			<Card>
				<div className="tab-content p-4">
					<div className="tab-pane fade show active" id="allTab">
						<div className="input-group mb-4">
							{/* <button className="btn btn-default dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions &nbsp;</button>
							<div className="dropdown-menu">
								<button className="dropdown-item" href="#modalDelete" data-bs-toggle="modal" target="_blank"
									rel="noopener noreferrer" onClick={()=>setOnUpdateItem({})}>Delete selected</button>
							</div> */}
							{/* <div className="flex-fill position-relative z-1">
								<div className="input-group">
									<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0" style={{ zIndex: '1020' }}>
										<i className="fa fa-search opacity-5"></i>
									</div>
									<input type="text" className="form-control ps-35px" placeholder="Search Material"
										onChange={(e)=>setFilterText(e.target.value)} value={filterText}/>
								</div>
							</div> */}
						</div>
						
						<div>
							<DataTable
								columns={columns}
								data={allItems}
								pagination
								theme="default"
								progressPending={loading}
								paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
								selectableRows
								persistTableHead
								paginationServer
								paginationDefaultPage={paginationMeta?.current_page}
								onSelectedRowsChange={handleChange}
								onChangeRowsPerPage={handlePerRowsChange} //handles rows per page state
								clearSelectedRows={toggledClearRows}
								paginationPerPage={paginationPerPage}
								onChangePage={handlePageChange}
								paginationTotalRows={paginationMeta?.total}
								customStyles={boldTableStyle} // Apply custom styles
							/>
						</div>
					
					</div>
				</div>
			</Card>

            {/* add material modal */}
            <div className="modal fade" id="modalAdd">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Add New Material</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Material</label>
								<Select options={materialList} classNamePrefix="react-select" 
									value={materialType} onChange={(e)=>setMaterialType(e)} onInputChange={(e)=>setMaterialFilterText(e)}/>
							</div>
                            <div className="mb-3">
								<label className="form-label">Quantity</label>
								<input type="number" className="form-control" placeholder="quantity" 
	                                onChange={(e)=>setQuantity(e.target.value)} value={quantity}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Type</label>
								    <Select options={typeList} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)}/>
							</div>
                            <div className="mb-3">
                            <label className="form-label">Description</label>
								<textarea className="form-control" rows="2" maxLength={200}
									onChange={(e)=>{setDescription(e.target.value)}} value={description}/>
							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="submit" className="btn btn-theme" onClick={()=>createItem()}>Create</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of add modal */}

			{/* update modal */}
			<div className="modal fade" id="modalUpdate">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Update cost</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">code</label>
								<input type="text" className="form-control" placeholder="code" 
									onChange={(e)=>{
										let data = {...onUpdateItem}
										data.code = e.target.value
										setOnUpdateItem(data)
										}} 
										value={onUpdateItem?.code}/>
							</div>
							<div className="mb-3">
								<label className="form-label">Type</label>
								<Select options={workersList} classNamePrefix="react-select" 
									value={type} onChange={(e)=>setType(e)} onInputChange={(e)=>setWorkerFilterText(e)}/>

							</div>
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							<button type="button" className="btn btn-theme" onClick={()=>updateItem()}>Update</button>
						</div>
					</div>
				</div>
			</div>
            {/* end of update modal */}

			{/* delete modal */}
			<div className="modal fade" id="modalDelete">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Delete item</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal"></button>
						</div>
						<div className="modal-body">
							{(onUpdateItem?.id) ?
								(<p>Are you sure you want to delete cost ID:{onUpdateItem?.id} ?</p>)
								:
								(selectedItems?.length) ?
									(<p>Are you sure you want to delete {selectedItems?.length} costs?</p>)
									:
									(<p>No item was selected for deleting</p>)
							}
						</div>
						<div className="modal-footer">
							<a href="#/" className="btn btn-default" data-bs-dismiss="modal">Cancel</a>
							{(onUpdateItem?.id) ?
								(							
								<button type="button" className="btn btn-theme" onClick={()=>deleteSingle()}>Delete</button>
								)
								:
								(selectedItems?.length) ?
									(
										<button type="button" className="btn btn-theme" onClick={()=>batchDelete()}>Batch Delete</button>
									)
									:
									("")
							}
						</div>
					</div>
				</div>
			</div>
            {/* end of delete modal */}

		</div>
	)
}

export default AssemblyStoreExpense;